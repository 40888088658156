body *{
  font: '"Exo 2", sans-serif' !important;
}
/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  background: #bd362f !important;
  color: white !important;
    padding: 12px 8px;
    border-radius: 2px;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    font-family: "Exo 2";
    max-width: 290px;
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
}
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
background-color: #51a351 !important;
color: white !important;
padding: 12px 8px;
border-radius: 2px;
box-shadow: 0 0 12px #999;
cursor: pointer;
font-size: 1em;
line-height: 1.2em;
font-family: "Exo 2";
max-width: 290px;
width: fit-content;
margin-left: auto;
margin-top: 10px;
margin-right: 10px;
width: 100%;
}
.Toastify__toast svg {
  fill: white !important;
  display: none;
}
.Toastify__toast h4 {
  font-size: 1em;
  text-align: left;
  margin: 0;
  font-weight: 700;
}
.Toastify__progress-bar{
  opacity: 0 !important;
}

.Toastify__toast  i{
  font-size: 20px;
}
/* .Toastify__toast  button.Toastify__close-button{
  display: none;
} */
.Toastify__toast-container {
  z-index: 99999999999999;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
