p.add-images {
    background: #f3f3f3;
    padding: 10px;
    border-radius: 5px;
    font-size: 13px;
    margin-top: 5px;
    min-height: 100px;
    border: 1px solid #c1c1c1;
}
.signature-btn {
    display: flex;
    justify-content: space-between;
}

.signature-btn button {
    width: 48%;
}

.signature-btn .delete-btn {
    border: 1px solid red;
    color: red;
    border-radius: 8px;
}

.signature-btn .save-template label {
    color: green !important;
    /* border: 1px solid green; */
    font-size: 16px;
    font-weight: 600;
    min-width: 116px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signature-btn .save-template {
    border: 1px solid green;
    border-radius: 8px;
}

.signature-btn .form-group {
    margin-bottom: 0px !important;
}
.mob-accordion .card-header .btn.btn-link{
    text-transform: capitalize !important;
}
.signature-btn button:first-child {
    background-color: transparent !important;
    border: 1px solid #939191 !important;
    color: #939191 !important;
}