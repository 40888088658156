@media (min-width: 991px) {

    /* Reports Page */
    .automated-payment-reminders .main-titles-mob h3 {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: #000000;
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        margin: auto;
    }

    .automated-payment-reminders .main-titles-mob .content-dropdown {
        display: none;
    }

    /* Sending Reminders */
    .sending-reminders .target-heading.client-profile {
        padding: 20px;
        margin-bottom: 0;
    }

    .sending-reminders .target-heading.client-profile h3 {
        max-width: 100%;
    }

    .sending-reminders .target-heading.client-profile .content-dropdown img {
        margin-top: -4px !important;
    }

    .sending-reminders .sending-reminders-page .appointment-summary {
        padding: 20px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-direction: column;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) {
        flex-direction: row;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group {
        margin: 0;
        width: 50%;
        max-width: 680px;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #3C424B;
        margin-bottom: 20px;
        padding: 0;
        height: fit-content;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group .number-field {
        height: fit-content;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group .number-field .number-box #mobile {
        width: fit-content;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group .number-field .number-box .flag-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group .number-field .number-box button span:last-child {
        margin-right: 0;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group .number-field .number-box #mobile button {
        padding: 5px 0 5px 10px;
        height: 34px;
        width: 125px;
        margin-right: 0;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .form-group input {
        height: 34px !important;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .number.form-group .number-box {
        width: fit-content;
    }

    .sending-reminders .sending-reminders-page .appointment-summary:nth-child(1) .number.form-group input {
        width: 100%;
        max-width: calc(100% - 125px);
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
    }

    .sending-reminders .sending-reminders-page .appointment-summary h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0;
        color: #333333;
    }

    .sending-reminders .sending-reminders-page .pending-water-test {
        margin: 0;
    }

    .sending-reminders .sending-reminders-page .pending-water-test h3 {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
    }

    /* Email & SMS Sending */
    .email-sms-sending .target-heading.client-profile {
        padding: 20px;
        margin-bottom: 0;
    }

    .email-sms-sending .target-heading.client-profile h3 {
        max-width: 100%;
    }

    .email-sms-sending .sending-days-section {
        padding: 20px;
    }

    .email-sms-sending .sending-days-section .top-heading h6 {
        margin: 0;
    }

    .email-sms-sending .days-description {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
    }

    .email-sms-sending .days-description-list {
        margin: 0;
    }

    .email-sms-sending .days-description-list h6 {
        margin: 0;
    }


    .sms-invocie-template .sending-days-section,
    .email-invoice-template .sending-days-section {
        padding: 0 20px 20px;
    }

    /* Payment Reminder Sending Conditions */
    .payment-reminder-sending .payment-reminder-page .table-data .service-mbl {
        display: block;
    }
    .payment-reminder-sending .payment-reminder-page .service-container .service-title {
        display: flex;
    }
    .payment-reminder-sending .payment-reminder-page .service-container .service-title .btn {
        min-width: auto;
        padding: 0;
        color: #000;
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
    }
    .payment-reminder-sending .payment-reminder-page .service-container .services-header {
        display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    }
    .payment-reminder-sending .payment-reminder-page .mob-table .mob-accordion {
        border-bottom: 1px dashed #ececec;
    }
    .payment-reminder-sending .payment-reminder-page .mob-table .mob-accordion {
        padding: 0;
    }
    .payment-reminder-sending .payment-reminder-page .mob-table .mob-accordion .card-header {
        padding: 0;
    }
    .payment-reminder-sending .payment-reminder-page .mob-table .mob-accordion .sub-category.card-header .btn {
        padding-left: 0;
        padding-right: 0;
    }
    .payment-reminder-sending .payment-reminder-page .mob-table .mob-accordion .card-header h5 {
        font-size: 18px;
    }
    .payment-reminder-sending .payment-reminder-page .mob-table .mob-accordion .card-header h5 i {
        font-size: 18px;
        margin-right: 5px;
    }



    /* SMS Invoice Template , Email Invoice Template */
    .sms-invocie-template .sending-days-section .days-description .message-subject h6,
    .email-invoice-template .sending-days-section .days-description .message-subject h6 {
        margin: 0;
    }

    /* Invoice Send Approval */
    .invoice-send-approval .target-heading.client-profile,
    .invoice-send-approval .invoice-max-section .form-group {
        margin: 0;
    }

    .invoice-send-approval .target-heading.client-profile p {
        margin: 0;
        font-size: 16px;
        line-height: 21px;
        color: #3C424B;
    }

    .invoice-send-approval .invoice-max-section {
        padding: 20px;
    }

    .invoice-send-approval .invoice-max-section .form-group label {
        margin-bottom: 20px;
        height: fit-content;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #3C424B;
        padding: 0;
    }










}

@media (max-width: 991px) {}