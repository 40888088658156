.water-chemistry-banner {
  background-image: url("../../assets/home/image1.jpg");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 100px 0;
}
.block-heading h3 span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #f8d400;
  top: 100%;
}
.block-heading {
  margin-bottom: 40px;
}
.block-heading h3 {
  font-size: 45px;
}
.block-heading h3 span {
  position: relative;
  padding-bottom: 10px;
  font-size: 45px !important;
  font-weight: 700 !important;
  font-style: normal !important;
}
.water-chemistry-container .block-box {
  margin-bottom: 60px;
  padding: 30px 0;
}
.water-chemistry-container .block-box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.water-chemistry-container .block-img:before {
  content: none;
}
.water-chemistry-container .block-content span {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
}
.water-chemistry-container .block-content {
  padding: 0 !important;
  padding-right: 50px !important;
}
.water-chemistry-container .reverse .block-content {
  padding: 0 !important;
  padding-left: 50px !important;
}
