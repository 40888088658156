.head-table.table thead th.bg-green,
.head-table.table tbody td.bg-green {
  background-color: #afffaf;
}
.head-table select.form-control {
  border: none;
  text-align: center;
  height: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  padding: 8px 10px;
  font-size: 16px;
  color: #00bdf7;
  font-weight: 600;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  z-index: 9;
  background-color: transparent;
  cursor: pointer;
}
table.head-table tbody td.dropdown > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  right: 0;
  width: 35px;
  z-index: 9;
  border: 1px solid rgb(0, 0, 0, 0.1);
  background: rgb(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 25px;
  line-height: 30px;
  color: #000;
}
.chemical-pricing-container table.head-table tbody td.dropdown > i {
  align-items: center;
  line-height: 31px;
}
table.head-table tbody td {
  text-align: left;
}
table.head-table tbody td.dropdown {
  padding: 0;
}
.chemical-pricing-container .head-table.table {
  margin-bottom: 20px;
}
.chemical-pricing-container .head-table.table-bordered td,
.chemical-pricing-container .head-table.table-bordered th {
  vertical-align: middle;
}
