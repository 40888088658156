.image-upload-popup .modal-content {
  height: auto !important;
  min-height: auto;
  margin: 15px;
  border-radius: 5px;
}
.image-upload-popup {
  padding-right: 0px !important;
}
.preview-popup nav.nav.nav-tabs a {
  width: 33%;
}
/* .field-location-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

.field-location-section .field-location input {
  width: 45%;
  height: 25px;
  margin: 5px 0px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}

.change-image-section {
  padding: 20px 10px;
}
.field-location-section .field-location span {
  padding-left: 5px;
  position: absolute;
  right: 35%;
  top: 0px;
}
/* .change-image-section .preview-image img {
    height: 80px;
    width: 100%;
    object-fit: contain;
    /* border: 1px solid #dcd9d9; */
/* border-radius: 5px;
    padding: 10px; */
/* } */
.change-image-section .preview-image {
  margin-top: 20px;
}
.change-image-section .change-section-btn button {
  font-size: 10px;
  padding: 8px 0px !important;
  min-width: auto;
  width: 24%;
  background-color: #94959a;
  color: white;
}

.change-image-section .change-section-btn {
  margin-top: 24px;
}
.preview-text .form-group label {
  color: black;
}
.text-section-btn {
  justify-content: flex-end;
  display: flex;
}

.template-btn .delete-btn,
.template-btn .delete-btn:focus,
.template-btn .delete-btn:hover,
.template-btn .btn-primary:not(:disabled):not(.disabled):active:focus {
  border: 1px solid red !important;
  color: red !important;
  background: transparent;
}

.template-btn .save-template,
.template-btn .save-template:hover,
.template-btn .save-template:focus,
.template-btn .save-template:not(:disabled):not(.disabled):active:focus {
  border: 1px solid #15a01c !important;
  color: #15a01c !important;
  background-color: transparent;
}

.template-btn .cancel-btn,
.template-btn .cancel-btn:hover,
.template-btn .cancel-btn:focus,
.template-btn .cancel-btn:not(:disabled):not(.disabled):active:focus  {
  border: 1px solid #9c9898 !important;
  color: #9c9898 !important;
  background-color: transparent !important;
}

.template-btn .save-btn,
.template-btn .save-btn:hover,
.template-btn .save-btn:focus,
.template-btn .save-btn:not(:disabled):not(.disabled):active:focus {
  background: #15a01c;
  color: white;
  border: 1px solid #15a01c;
}
.template-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 25px !important;
  flex-wrap: wrap;
  margin: 20px;
  width: 95%;
  margin: 40px auto;
}
.template-btn button {
  width: 24%;
}
.upload-logo input {
  visibility: hidden;
  width: 0px;
  display: none;
}

.upload-logo .form-group label {
  padding: 0px !important;
  margin: 0px !important;
  text-align: center;
  height: fit-content;
  font-size: 10px;
  color: white !important;
  justify-content: center;
}

.upload-logo .form-group {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}
.preview-popup .form-group {
  margin-bottom: 0px;
  position: relative;
}

.preview-popup .form-group label {
  padding: 0px !important;
  line-height: initial;
  height: auto;
  font-weight: 500;
  color: black;
}

.field-location {
  width: 50%;
}
html.edit-template-page .admin-content {
  padding-top: 0px;
}
/* .enable-zoom .react-transform-wrapper .react-transform-component.transform-component-module_content__2jYgh{
    transform: inherit !important;
}
.enable-zoom .react-transform-wrapper {
    overflow-y: auto !important;
    user-select: all !important;
    -webkit-user-select: all !important;
    touch-action: pan-y;
} */
/* .zoom-in-template {
    padding: 5px 5px;
} */
.change-text-popup .field-location {
  width: 48% !important;
}
.change-text-popup .field-location-section {
  display: flex;
}
.change-text-popup .form-group {
  width: 100% !important;
}
.change-text-popup .field-location-section .field-location input {
  width: 100% !important;
  margin: 0px;
}
.change-text-popup label.form-label {
  margin-bottom: 5px;
}
.change-text-popup .preview-popup .form-group {
  margin-bottom: 10px;
}
.image-upload-popup .field-location {
  width: 100%;
}
/* .image-upload-popup  .field-location-section {
    display: grid;
    justify-content: space-between;
    align-items: center;
} */
.image-upload-popup .image-size {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.image-upload-popup .field-location-section .field-location span {
  padding-left: 5px;
  position: absolute;
  right: 35%;
  top: 18px;
}
.image-upload-popup .form-group {
  width: 48%;
}
.image-upload-popup .field-location-section .field-location input {
  width: 100%;
  height: 32px;
}
.image-upload-popup .field-location-section .field-location span {
  right: 18%;
}
.image-upload-popup .change-section-btn .form-group {
  width: 100%;
}
.change-text-popup .field-location-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.preview-popup td {
  border: none !important;
}
.pool-set-up .template-btn .dropdown button {
  width: 100% !important;
}
.save-template-popup .time-keeper-popup.duration-popup.event-popup {
  padding: 22px !important;
}
.save-template-popup h5 {
  color: #0048ba;
  font-size: 16px;
}
.pool-set-up.invoices-list.invoice-detail.search-invoice .form-group label {
  color: #000000 !important;
  font-weight: 600 !important;
}
.save-template-popup .form-group input::placeholder {
  color: red;
  font-style: italic;
}
.save-template-popup .form-group {
  margin: 20px 0px 30px 0px !important;
}
.save-template-popup .template-btn {
  width: 100% !important;
  margin: 0px !important;
}
.template-page .target-heading .form-group {
  width: 100%;
}
.new-search-popup1 .modal-content {
  min-height: auto !important;
  margin: 10px auto !important;
  background-color: #f6f7fb !important;
  padding: 0px !important;
  border-radius: 10px !important;
  width: 322px;
}
.close-btn button {
  background-color: transparent !important;
  border: 1px solid #939191 !important;
  color: #939191 !important;
  min-width: 118px;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 10px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 38px !important;
}
.close-btn {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.template-btn button .form-group {
  width: 100%;
}

.image-upload-popup .template-btn button .form-group label {
  color: #15a01c !important;
  font-weight: 600 !important;
}
.change-image-section {
  padding: 0px 10px;
  background-color: #f6f7fb;
}
.change-image-section .template-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 15px !important;
  flex-wrap: wrap;
  margin: 20px;
  width: 100%;
  margin: 15px auto;
}
.image-selection .form-group {
  width: 100%;
  margin-bottom: 10px;
}
.change-text-popup .template-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 0px !important;
  flex-wrap: wrap;
  margin: 20px;
  width: 100%;
  margin: 15px auto;
  padding: 0px 10px;
}
.new-search-popup1 .template-btn {
  margin-top: 25px !important;
}
.add-field-popup .template-btn {
  margin-top: 0px !important;
}
.text-popup-spacing {
  margin: 20px 0px;
}
.preview-invoice .image-selection .select-box select.form-control {
  background-color: white;
  color: #000000;
  font-size: 12px;
  font-style: initial;
}
.preview-popup .modal-content {
  width: 95%;
}
.change-text-popup .change-image-section {
  padding: 5px 10px !important;
}
/* .edit-template tr td{
    line-height: 12px !important;
} */
.template-page table tr td {
  border: none !important;
  word-break: break-word !important;
}
.edit-template-page {
  position: relative;
}
.template-btn button {
  width: 48% !important;
  min-width: auto;
  font-weight: 600 !important;
  border-radius: 10px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 38px !important;
}
.image-upload-popup .form-control:focus,
.form-control[focus] {
  border-color: #e0e0e0 !important;
}
.image-upload-popup .select-box select.form-control {
  background-color: white;
}
.preview-pdf .modal-content {
  background-color: #ffffff !important;
}
.image-upload-popup .field-location-section .field-location input {
  border: 1px solid #e0e0e0;
}
.edit-template-page {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.new-image-popup .field-location-section  .template-btn {
    margin-top: 35px !important;
}
.appointment-container.preview-popup .preview-text {
  margin-bottom: 30px;
}
.new-image-popup .field-location {
  margin-top: 35px;
}
.new-image-popup .field-location-section  .temp-btn {
  margin: 5px 0px !important;
  margin-top: 10px !important;
}
.save-template-popup .time-keeper-popup.duration-popup.event-popup {
    max-width: 100%;
    width: calc(100% - 96px);
    padding: 22px 15px !important;
}
.save-template-popup .time-picker-modal button.btn.btn-primary{
    font-size: 13px !important;
}
.template-btn.view-btn.new-btn-section {
  display: flex;
  justify-content: flex-end;
}
.appointment-container iframe {
  width: 100%;
  height: 700px;
}
/* .search-modal .react-pdf__Page canvas {
 border: none; 
} */
.search-modal.new-search-popup1.image-upload-popup.change-text-popup.modal.show .modal-content {
  margin-bottom: 100px !important;
}

.quotes-edit-temp tr td span{
  display: block;
  /* width: 100%; */
}
.search-modal.new-search-popup1.preview-popup.preview-pdf .modal-dialog {
  width: 100%;
  margin: 0px auto;
  max-width: 100%;
}
p.error-msg {
  font-size: 12px;
  line-height: initial;
  color: red;
}
/* @media screen and (max-width: 991px) {
  .edit-template.quotes-edit-temp.add-edit-quotes-template {
    transform: scale(0.8) translate(-7%, -50%) !important;
}
}
@media screen and (max-width: 480px) {
  .edit-template.quotes-edit-temp.add-edit-quotes-template {
    transform: scale(0.5) translate(50%, -50%) !important;
}
} */
.preview-popup.preview-pdf .back-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: absolute;
  margin: 0;
  right: 7px;
  top: 7px;
  z-index: 99999999;
}
/* iframe{
  pointer-events: none;
} */
.right-btns{
  justify-content: flex-end !important;
}
.change-image-section .preview-image span h6 {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.quotes-template-page.default-view-templates .edit-template{
  pointer-events: none;
}
.change-text-popup .field-location .form-group span {
  position: absolute !important;
  top: 24px !important;
  right: 5px !important;
  font-size: 12px;
  font-weight: 500;
}
.upload-logo .form-group label{
  font-size: 16px;
  cursor: pointer;
}
.pool-set-up.invoices-list .loader-section {
  margin: 0;
}

@media screen and (min-width: 767px) {
  .new-search-popup1.image-upload-popup.new-image-popup .modal-content, .new-search-popup1 .modal-content {
    max-width: 400px;
    width: 100%;
}
.new-search-popup1.image-upload-popup.new-image-popup button{
  width: 48% !important;
}
.add-field-popup .template-btn button {
  width: 48% !important;
}
}
