.appointment-view.xero-box img {
    height: 90px;
}

.xero-box {
    text-align: center;
}

.xero-box h2 {
    font-size: 35px;
    padding: 30px 0px 5px;
}

.xero-box .view-btn button {
    margin-bottom: 20px !important;
}

.xero-box .view-btn {
    display: flex;
    justify-content: center;
}
.xer-success-box {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appointment-view.xero-box.xer-success-box h2 {
    color: #0048ba;
    font-size: 30px;
    padding: 10px;
}

/* Work On 04/03/22 - Haya  */
.main-titles-mob.xero-page-connect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
    color: black;
    padding: 14px 12px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-top: 0;
}
.xero-page-connect h3 {
    font-size: 15px !important;
    color: #0070c0;
}
.link-sales-page h3 {
    font-size: 22px !important;
    max-width: 90%;
}
.status-box-xero {
    padding:40px 20px 10px;
}

.status-box-xero img {
    height:80px !important;
}
/* .status-box-xero .view-btn Button {
    width: fit-content;
    max-width: fit-content;
} */

.sales-default {
    background: #fff;
    border-radius: 5px;
    padding: 12px 12px;
}
.sales-default h5 {
    margin-bottom:0;
    font-size: 18px;
}

.sales-default label.form-label {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
}

.sales-default .form-group select {
    border: 1px solid #e0e0e0 !important;
}
.xero-checkbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.xero-checkbox p{
    width: fit-content;
    margin: 0;
}
.xero-checkbox .client-notes{
    width: fit-content !important;
}
.select-box select.xero-select.form-control option:first-child {
    color: #333 !important;
    font-weight: 700;
}

/* Import Contacts Form */
/* .import-contacts-form .select-box:after {
content: none;
}
.import-contacts-form .select-box select:after {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 0;
    color: #000;
} */
.import-contacts-form label {
    color: #0070c0 !important;
    font-size: 15px !important;
    font-weight: 600;
    padding-left: 6px !important;
}
.import-contacts-form .select-section {
    background: white;
    padding: 20px 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.import-contacts-form .select-section select {
    position: relative;
}
.import-contacts-form .select-section .select-box {
width: calc(100% - 0px);
margin-bottom: 20px;
}
form.import-contacts-form select.form-control {
    background: white;
}
form.import-contacts-form .view-btn {
    justify-content: flex-end;
    margin: 0;
}
.appointment-view.xero-box.status-box-xero button {
    width: fit-content;
}
@media screen and (min-width: 991px) {
    .import-contacts-form .select-section{
        flex-direction: row;
    }
    form.import-contacts-form .view-btn button, .xero-box .view-btn button{
        max-width: fit-content;
    }
    .import-contacts-form .select-section .select-box{
        margin: 0;
    }
}