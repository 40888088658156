.inventory-list .card {
  padding: 0px !important;
}
.inventory-page .service-mbl .card {
  background-color: transparent;
}
.inventory-page .service-mbl .outer-accordion.accordion .card-body {
  padding-bottom: 0px !important;
}
.inventory-page .mob-accordion .card {
  margin: 0px 0px !important;
}
.inventory-page .service-mbl .mob-accordion {
  padding: 5px 0px !important;
}
.inventory-page .service-edit-icon {
  line-height: 10px;
  font-weight: 600;
}
.inventory-page .timehour {
  color: #5094ff;
  font-weight: 600;
}
.inventory-page .yellow-text {
  color: #ffc310 !important;
}
.inventory-page .staff-list .staff-box,
.inventory-page ul.grid-list .grid-box {
  padding: 8px 0;
}

/********** inventory product list *********/
.predicative-stock {
  background: #f1f4f9;
  padding: 5px;
  border-radius: 5px;
  margin-top: 20px;
}

.predicative-stock h6 {
  color: #28afd9;
  margin-bottom: 0px;
}

.predicative-stock h4 {
  color: #28afd9;
  margin-bottom: 0px;
}

.predicative-stock p {
  color: #28afd9;
  margin-bottom: 0px;
  font-style: italic;
  line-height: initial;
  margin-bottom: 3px;
}
.stock-listing {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.ordring-section {
  margin-top: 10px;
}
.inventory-list .grid-box.blue-text * {
  color: #28afd9 !important;
}
.ordring-section .stock-listing p:last-child {
  color: red !important;
  font-weight: 500;
}
.reports-page.staff-container.inventory-page
  .client-option.service-edit-icon
  .action
  img {
  width: 20px;
}
.inventory-page .stock-listing p:last-child {
  width: 50%;
  max-width: 25%;
}
.search-inventory .pool-chemistry-box.appointment-service-modal {
  margin-top: 0px;
  padding: 12px 12px;
}

.search-inventory .pool-chemistry-box.appointment-service-modal h5 {
  padding-bottom: 8px;
}
.search-inventory .select-box select.form-control {
  background: white;
}
.search-inventory .search-box.form-group input {
  color: #a5a5a5;
}

.search-inventory .search-box.form-group input::placeholder {
  color: red !important;
}

/********* search result *********/
.search-result-box {
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px 5px;
  margin-top: 25px;
}

.search-result-box .search-result-head {
  display: flex;
  justify-content: space-between;
}

.search-result-box .search-result-content {
  display: flex;
  justify-content: space-between;
}

.search-result-box .search-result-head h6 {
  width: 20%;
  font-size: 12px !important;
}

.search-result-box .search-result-head h6:first-child {
  width: 40% !important;
}

.search-result-box .search-result-content p {
  width: 20%;
  font-size: 12px;
  color: #a7a7a7;
  margin-bottom: 0px;
}

.search-result-box .search-result-content p:first-child {
  width: 40% !important;
  font-weight: 600;
  color: black;
}
.search-result-box .search-result-head h6:last-child {
  /* text-align: center; */
  width: 15% !important;
}
.search-result-box .search-result-content p:last-child {
  /* text-align: center; */
  width: 15% !important;
  color: #0048ba !important;
}
.search-result-box .supplier-text {
  font-style: italic;
  font-size: 12px;
  color: #28afd9;
}
.main-search-result {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search-result-content {
  width: 100%;
}

.search-result-body {
  position: relative;
}
.search-result-box .no-data-found {
  text-align: center;
}
.inventory-result-icon img {
  height: 20px;
  transform: rotate(-90deg);
  opacity: 0.5;
}
.inventory-result-icon {
  position: absolute;
  right: 0px;
}

/******** inventory searhc result *********/
.inventory-search-details .grid-box .action-btn {
  padding: 0px !important;
}
.inventory-search-details .search-result-box {
  padding: 20px 20px 20px 60px;
}

/****** predictive ordering detail **********/
.predictive-ordering-detail .ordering-detail-header {
  display: flex;
  justify-content: space-between;
  background: #a5a5a5;
  padding: 0px;
}

.predictive-ordering-detail .ordering-detail-header h6 {
  font-size: 12px;
  width: 18%;
  color: white;
  text-align: center;
  border-right: 1px solid #d6d6d6;
  padding: 10px 0px;
  margin-bottom: 0px;
  text-align: center;
}

.predictive-ordering-detail .ordering-detail-header h6:first-child {
  width: 35%;
}
.predictive-ordering-detail .ordering-detail-header h6:nth-child(2) {
  width: 12%;
}
.predictive-ordering-detail .ordering-detail-list {
  display: flex;
  border: 1px solid #d6d6d6;
  justify-content: space-between;
  border-top: none;
}
.predictive-ordering-detail .ordering-detail-list p:last-child {
  border-right: none;
}
.predictive-ordering-detail .ordering-detail-list p {
  margin-bottom: 0px;
  border-right: 1px solid #d6d6d6;
}
.predictive-ordering-detail .ordering-detail-list p:first-child {
  width: 35%;
}
.predictive-ordering-detail .ordering-detail-list :nth-child(2) {
  width: 12%;
}
.predictive-ordering-detail .ordering-detail-list p {
  font-size: 12px;
  width: 18%;
  text-align: center;
}
/****** inventory adjustment *******/
.inventory-adjustment .remove-text {
  top: 47px;
  height: auto;
}
.inventory-adjustment .search-box.form-group > img {
  top: 47px;
}
.inventory-adjustment .view-btn {
  margin-top: 20px !important;
  justify-content: space-between !important;
  width: 100%;
  display: flex;
}

/* Work On 04/03/22 - Haya  */
.product-inventory-grid .grid-box {
  padding: 5px 0 !important;
}

.current-stock .grid-box,
.current-stock .grid-content {
  color: #28afd9 !important;
}
.current-stock .grid-content {
  font-weight: 600 !important;
}
.product-inventory-grid .grid-list-item .predicative-stock h4 {
  font-weight: bold !important;
}
.product-inventory-grid .grid-list-item .predicative-stock h6 {
  font-size: 14px !important;
}

.product-inventory-grid
  .grid-list-item
  .predicative-stock
  .ordring-section
  .stock-listing
  p:last-child {
  font-weight: bold !important;
}

.product-inventory-grid .actions {
  padding-top: 10px !important;
}
.inventory-list .sub-category .time-category {
  width: 20%;
}
.inventory-list .service-mbl .sub-category .time-category {
  justify-content: flex-start;
}
.inventory-location-tag {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.inventory-location-tag p,
.inventory-location-tag p:last-child {
  margin-bottom: 8px !important;
  margin-right: 5px;
  background: #efefef;
  padding: 5px 15px;
  border-radius: 19px;
  font-size: 12px;
  min-width: 50px;
}

.inventory-location-tag p span {
  margin-left: 10px;
  cursor: pointer;
}

.inventory-adjustment .italic-grey-inventory {
  color: grey;
  font-style: italic;
  font-weight: 600;
}
.target-heading {
  display: flex;
  justify-content: space-between;
}
.target-heading .order-image {
  height: 20px;
  width: 20px;
}
.inventory-adjustment .auto-complete {
  position: absolute;
  width: 100%;
  max-height: 200px;
  height: auto;
  top: 69px;
  background: #fff;
  left: 0;
  border-radius: 5px;
  z-index: 9999;
  border: 1px solid #eaebec;
  overflow: auto;
  padding: 5px 0px;
}
.inventory-page .client-option.service-edit-icon span {
  font-weight: 400 !important;
}
.search-invoces-box .outstandings-invoices p {
  margin: 0;
  font-size: 13px;
  color: #a5a5a5;
}
.search-invoces-box .outstandings-invoices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  border: 1px solid #e0e0e0;
  background: white;
  height: 35px;
  border-radius: 5px;
}
.inventory-page .invoice-detail.search-invoice .form-group label{
  color: #333 !important;
}
.inventory-page .search-invoces-box h6{
  color: #333 !important;
  margin-bottom: 18px;
}
.inventory-adjustment .appointment-summary {
  margin-top: 25px;
}
.blue-heading-predective-details{
  color: #28afd9 !important;
}
.inventory-location-tag input {
  background: #efefef;
  padding: 5px 15px;
  border-radius: 19px;
  font-size: 12px;
  min-width: 50px;
  border: 1px solid #e0e0e0;
  width: 100%;
  text-align: left;
  height: 30px;
  background: transparent;
}

.inventory-location-tag .form-group {
  position: relative;
  margin-bottom: 8px !important;
  margin-right: 10px;
}

.inventory-location-tag .number-span{
  position: relative;
}
.inventory-location-tag .number-span {
  position: relative;
  max-width: 80px;
}
.inventory-location-tag .form-group span {
  position: absolute;
  right: 16px;
  top: 4px;
  color: #8e8e8e;
  font-size: 15px;
  cursor: pointer;
}