.calendar-container .rbc-row-bg {
  background: #d4f2ff;
}

.calendar-container .rbc-day-bg.rbc-today {
  background: #7bd7ff;
}

.calendar-container .rbc-date-cell.rbc-now.rbc-current > a {
  color: #000;
}

.calendar-container .rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}

.calendar-container .rbc-header {
  padding: 5px 10px !important;
}

.calendar-container .rbc-header span {
  font-size: 16px;
}

.calendar-container .rbc-time-view .rbc-row {
  min-height: 40px !important;
}

.calendar-container .calendar-associate {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  background: #d0d0d0;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* .calendar-associate .js-schedule-employee {
  padding-right: 10px;
} */
.calendar-container .calendar-associate .js-schedule-employee .btn-primary {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  min-width: auto;
  padding: 5px;
}

.calendar-container
  .calendar-associate
  .js-schedule-employee
  .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  color: #555;
  background: #fff;
  padding: 2px 5px;
  height: auto;
  border-radius: 0;
}

.calendar-container
  .calendar-associate
  .js-schedule-employee.home-location
  .dropdown-toggle::after {
  content: "\f015";
}

.calendar-container
  .calendar-associate
  .js-schedule-employee.staff-location
  .dropdown-toggle::after {
  content: "\f0c0";
}

.calendar-container .rbc-toolbar .rbc-toolbar-label {
  font-weight: 800;
}

.calendar-container .calender-icon {
  margin-left: auto;
}

.calendar-container .calender-icon span {
  display: inline-block;
  background: #fff;
  padding: 5px 5px;
  font-size: 20px;
  color: #555;
  cursor: pointer;
}

.grid-box {
  text-align: center;
  padding: 2px;
}

.grid-box h5 {
  font-size: 16px;
  margin: 0px;
  font-weight: 600;
  color: #000;
}

.grid-box span {
  font-size: 12px;
  color: #000;
}

table.fc-col-header * {
  border: none !important;
}

/* .calendar-container .rbc-date-cell {
  padding: 5px 10px !important;
} */
/* 
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.mbsc-schedule-all-day-wrapper.mbsc-ios {
  display: none !important;
}

html,
body,
body > div {
  /* the react root *
  margin: 0;
  padding: 0;
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 16px;
} */
/* 
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
} */
/* 
b {
  /* used for event dates/times *
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root *
  max-width: 1100px;
  margin: 0 auto;
}

html,
body,
body > div {
  /* the react root *
  margin: 0;
  padding: 0;
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 16px;
}
/* 
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { */
/* used for event dates/times *
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root *
  max-width: 1100px;
  margin: 0 auto;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

/************************************** Calendar Css Start Here  **********************************************/
@import "https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap";

/* html.calendar {
  overflow-y: hidden;
} */
.demo-app.calendar-inner {
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 15px 0;
  font-family: "Mulish", sans-serif;
}

.calendar-main-page .admin-container {
  background: #fff;
  /* -webkit-opacity: 1; */
}

.calendar-inner
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-button-primary.fc-button-active {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #0048ba;
}

.calendar-inner .fc .fc-toolbar.fc-header-toolbar .fc-button-primary {
  background-color: #fff !important;
  border-color: #fff !important;
  margin: 0 5px;
  color: #7f7171;
  flex: inherit;
}

.calendar-inner .fc .fc-toolbar-title {
  font-size: 18px;
  /* font-family: "Mulish", sans-serif; */
}

/* .calendar .admin-wrapper {
  font-family: "Mulish", sans-serif;
} */

.calendar-inner .fc .fc-col-header-cell-cushion {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 15px 4px;
  width: 100%;
}

.calendar-inner
  .fc-col-header
  th.fc-col-header-cell.fc-day.fc-day-sun:first-child {
  text-align: center !important;
}

.calendar-inner .main-calendar .fc-timegrid-event .fc-event-main {
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  height: 100%;
  position: relative;
  font-weight: 600;
}

.main-calendar .fc .fc-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.main-calendar .fc-toolbar-chunk {
  width: 100%;
  text-align: center;
}

.main-calendar button.fc-today-button.fc-button.fc-button-primary {
  display: none;
}

.main-calendar .fc .fc-button-group {
  width: 100%;
}

.main-calendar .fc-toolbar-chunk {
  width: 100%;
  text-align: center;
  margin: 8px 0;
}

.main-calendar .fc .fc-button-primary:not(:disabled):active:focus,
.main-calendar .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.main-calendar .fc .fc-button-primary:focus,
.main-calendar .fc .fc-button:focus {
  box-shadow: none;
}

.main-calendar .fc-timegrid-slot-label-cushion {
  font-size: 11px;
  font-weight: 700;
  color: #101928;
}


.main-calendar .fc-event-main > p {
  font-style: normal;
  max-width: 100%;
  overflow: hidden;
  font-size: 11.5px;
  line-height: 1.4;
  word-break: break-all;
  font-family: 'Exo 2';
  white-space: nowrap;
  margin-bottom: 0px;
  font-weight: 500;
}

.main-calendar .fc-event-main > b {
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 1.4;
  max-width: 100%;
  overflow: hidden;
  font-family: 'Exo 2';
  white-space: nowrap;
}

.main-calendar .fc-event-main > i {
  font-style: normal;
  line-height:1.4;
  word-break: break-all;
  font-family: 'Exo 2';
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  font-size: 11.5px;
  white-space: nowrap;
}

.main-calendar .fc-event-main i:last-child {
  white-space: nowrap;
  padding-top: 2px;
}

.main-calendar
  .fc-direction-ltr
  .fc-button-group
  > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.main-calendar
  .fc-direction-ltr
  .fc-button-group
  > .fc-button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-calendar
  td.fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor,
.main-calendar td.fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor,
.main-calendar td.fc-timegrid-slot.fc-timegrid-slot-lane,
.main-calendar .fc .fc-timegrid-slot-label {
  padding: 0px 8px;
  line-height: 18px !important;
}

.main-calendar .fc-button-group .fc-prev-button.fc-button.fc-button-primary,
.main-calendar .fc-button-group .fc-next-button.fc-button.fc-button-primary {
  border-radius: 0;
  min-width: auto;
  width: auto;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 20px;
}

.main-calendar
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr
  .fc-toolbar-chunk:first-child {
  width: auto;
  display: none;
}
.main-calendar .fc .fc-timegrid-slot {
  height: 1.63em !important;
}
.main-calendar .calendar-filters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: -40px; */
}

.main-calendar .calendar-filters span.elipse-icon {
  font-size: 25px;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.main-calendar .calendar-filters span.filter-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-calendar .calendar-filters span.filter-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.main-calendar .fc .fc-button .fc-icon {
  vertical-align: middle;
  font-size: 20px;
  color: #666;
}

.main-calendar .fc-v-event {
  display: block;
  /* border: 1px solid #3788d8; */
  border: #a5dff8;
  /* background-color: #3788d8; */
  background-color: #a5dff8;
}

.main-calendar .fc-v-event .fc-event-main {
  color:#404040 !important;
}

.main-calendar .fc .fc-timegrid-axis-cushion,
.main-calendar .fc .fc-timegrid-slot-label-cushion {
  padding: 0;
}

.main-calendar .fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center;
  width: auto;
}

.main-calendar .fc-theme-standard .fc-scrollgrid {
  border: none;
}

.main-calendar td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
  border: none;
  background: #fff;
  border-top: 1px solid #e2e2e2;
}

/* td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink:first-child {
  border-bottom: none;
  border: none;
} */
.main-calendar
  td.fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor:first-child {
  border-top: none;
  background: #fff;
}

/* .main-calendar .fc-col-header-cell.fc-day {
  border-bottom: 1px solid #f7f7f7 !important;
} */

.main-calendar .grid-box {
  text-align: center;
  padding: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  display: -webkit-flex;
}

.main-calendar .grid-box h5 {
  font-family: "Exo 2", sans-serif;
  color: #171717;
  font-weight: 500 !important;
  font-size: 20px !important;
  width: 36px;
  height: 36px;
  line-height: 36px;
}


.main-calendar .grid-box.selected h5 {
  border-radius: 50%;
  background-color: hsl(240deg 100% 50%) !important;
  color: #fff;
  font-size: 18px !important;
}

.main-calendar .grid-box.prev-date h5,
.main-calendar .grid-box.prev-date span {
  color: #6b7489;
}

.main-calendar .grid-box.next-date h5,
.main-calendar .grid-box.next-date span {
  color: #333;
}
.main-calendar .grid-box.selected span {
  color: hsl(240deg 100% 50%) !important;
  /* font-weight: 800; */
}
.main-calendar .grid-box span {
  font-family: "Exo 2", sans-serif;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 600;
  color: #8a8888;
  margin-bottom: 4px;
}


.main-calendar .fc .fc-scrollgrid-section-liquid > td:last-child {
  border-bottom: none;
}

.main-calendar th.fc-col-header-cell.fc-day {
  width: auto;
  vertical-align: bottom;
}

.main-calendar .calendar-filter-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.main-calendar .calendar-filter-icons span {
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .calendar-filter-prev {
  position: absolute;
  top: 5px;
  left: 100px;
}
.calendar-filter-next {
  position: absolute;
  top: 5px;
  right: 100px;
} */
.main-calendar .fc .fc-button-group {
  display: inline-block;
}

.main-calendar button.fc-timeGridWeek-button.fc-button.fc-button-primary,
.main-calendar button.fc-timeGridDay-button.fc-button.fc-button-primary {
  position: relative;
  min-width: auto;
  border-radius: 0;
  /* overflow: hidden; */
  padding: 0;
  width: 20px;
}
/* 
.main-calendar
  button.fc-timeGridWeek-button.fc-button.fc-button-primary:before {
  content: "\f784";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 100%;
  background: inherit;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.main-calendar button.fc-timeGridDay-button.fc-button.fc-button-primary:before {
  content: "\f783";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 100%;
  background: inherit;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
} */

.main-calendar .fc .fc-button-group {
  width: 100%;
  position: absolute;
  top: -40px;
  right: 15px;
  text-align: right;
}

.main-calendar .testclass {
  background-color: #fff !important;
}


.main-calendar .calendar-date .date {
  margin: 0 10px;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
  color: #000000;
  font-size: 14px !important;
  font-family: "Exo 2", sans-serif;
  text-align: center;
  line-height: 15px;
}

/* .main-calendar .fc .fc-toolbar {
  display: none;
} */
.calendar-inner .fc .fc-toolbar-title {
  display: none;
}

.main-calendar .fc-toolbar-chunk {
  margin: 0;
}



/* .fc
  .fc-scrollgrid-section
  > .fc-scrollgrid-section.fc-scrollgrid-section-header {
  border-right-width: 0;
} */
.main-calendar .fc .fc-scrollgrid-section-header > *,
.main-calendar .fc .fc-scrollgrid-section-footer > * {
  border-bottom-width: 0;
  border-right-width: 0;
}

.main-calendar .fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 5px;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #f7f7f7;
}

/* .fc-timegrid-slots tr {
  touch-action: pan-y;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 8px 8px;
  background-image: linear-gradient(
    45deg,
    transparent 46%,
    rgba(16, 25, 40, 0.2) 49%,
    rgba(16, 25, 40, 0.2) 51%,
    transparent 55%
  );
  background-color: #eef0f2a6;
} */
.main-calendar .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr .fc-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 15px;
  right: 0;
  display: none !important;
}
.fc-timegrid-slots tr:nth-child(4n) td.fc-timegrid-slot.fc-timegrid-slot-lane {
  /* border-bottom: 1px solid #dee3e7; */
  border-bottom: 1px solid #e2e2e2;
}


/* ayushi */
.main-calendar .fc .fc-timegrid-slot-minor {
  border-top-style: solid;
}

.main-calendar .fc .fc-scrollgrid table {
  /* border-bottom: 1px solid #dee3e7 !important; */
  border-bottom:  1px solid #e2e2e2 !important;

}

.main-calendar .calendar-date .date span {
  color: #8a8888 !important;
  font-size: 11px !important;
}

.calendar .header {
  background-color: transparent;
  height: auto !important;
}

.calendar .header .navbar-dark .navbar-brand{
  display: none;
}
.calendar .navbar-dark .navbar-toggler {
  margin-left: auto;
  line-height: inherit;
  z-index: 9999;
  padding: 0.25rem 0;
  margin-top: 20px;
}
.calendar .admin-content {
  padding: 0px;
}
.calendar .admin-content-view {
  padding-top: 0px;
}

.calendar .header .col-lg-12 {
  padding-right: 7px;
}
.calendar .demo-app.calendar-inner {
  width: calc(100% + 30px);
  margin-left: -15px;
  font-family: "Mulish", sans-serif;
}
.calender-date-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  border-bottom: 1px dashed #e7e7e7;
  margin-bottom: 0px;
  padding: 0px 5px;
  padding-bottom: 0px;
}
.demo-app.calendar-inner {
  padding: 22px 0;
}
.fc-theme-standard td,  .fc-theme-standard th {
  border: 1px solid #eaeaea;
  border-right: none;
}
.calendar-date img {
  height: 28px;
  width: 28px;
}
.main-calendar .fc-timegrid-event-harness {
  margin: -1px -4px !important;
}
/* .main-calendar .fc-timegrid-event .fc-event-main {
  background-color: #dfddb6;

} */
.main-calendar .fc-media-screen .fc-timegrid-event {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
}
/* .main-calendar .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-size: 11px !important;
  font-weight: 700;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  line-height: 10px;
  -webkit-align-items: baseline;
  align-items: baseline;
} */
.main-calendar .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-size: 11px !important;
  font-weight: 700;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 32px;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  line-height: 10px;
  -webkit-align-items: baseline;
  align-items: baseline;
  padding-left: 4px;
}

.main-calendar .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion span {
  font-size: 9px;
  color: #999999;
}
.main-calendar td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink{
  padding: 0px 2px !important;
  padding-left: 0px !important;
}
/* .main-calendar .fc-scroller {
  overflow: hidden;
} */


.main-calendar .calendar-date {
  position: relative;
  margin: 0px 0;
  width: 88%;
}
.calender-option {
  width: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.calendar .navbar-toggler-icon {
  background-image: url(../../assets/home/hamburger-iconblack.png) !important;
  transition:none;
  background: no-repeat center center;
  background-size: 100% 100%;
  z-index: 9999;
  width: 18px;
  height: 18px;
  background-size: 25px;
  right: 14px;
}
.calendar .navbar-dark .navbar-toggler{
  display: none;
}

.calendar .navbar-close-icon {
  position: absolute;
  background-image: url(../../assets/home/cancel.svg) !important;
  transition: none;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  z-index: 9999;
  width: 18px;
  height: 18px;
  background-size: 25px;
  right: 14px;
}
/* .main-calendar .fc-scroller{
  overflow: auto !important;
} */
.calender-date-section {
  margin-top: 0px;
}

/* 
team page css */
.calendar-team-member {
  width: 300px;
  background-color: #ffffff;
  /* height: 500px; */
  position: fixed;
  top: 0px;
  min-height: 100vh;
  height: 100% !important;
  z-index: 999;
  transition: all ease .5s;
  right: 0px;
  bottom: 0px;
}
.calendar-option {
  height: 280px;
  background-color: #0096ed !important;
  padding: 25px;
}
.calendar-close img {
  /* height: 20px; */
  width: 20px;
  cursor: pointer;
}

.calendar-close {
  position: absolute;
  right: 18px;
  top: 15px;
}
.team-members {
  padding: 25px;
  height: 100%;
  background-color: white;
}
.day-option img {
  width: 45px;
}

.total-option {
  display: flex;
  justify-content:center;
  align-items: center;
  width: auto !important;
  cursor: pointer;
}
.cal-options .react-multi-carousel-dot button {
  border-color: #d0cdcd;
  background-color: #d0cdcd;
  border: 1px solid #d0cdcd;
  height: 8px;
  width: 8px;
}
.cal-options .react-multi-carousel-list {
  padding-bottom: 30px;
}
.calendar-options h6 {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin-bottom: 30px;
}
.cal-options .react-multi-carousel-dot--active button {
  background: #ffffff;
  border: 1px solid white;
}
.calendar-options {
  margin-top: 15px;
}
.day-option {
  border: 1px solid white;
  padding: 3px;
  border-radius: 12px;
  margin-bottom: 5px;
  height: 53px;
  width: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-time.active .day-option {
  border: 1px solid white;
}
.calendar-time {
  text-align: center;
  opacity: 0.5;
}
.calendar-time.active {
  text-align: center;
  opacity: 1;
}
.calendar-time p {
  color: white;
  font-size: 15px;
  font-weight: 500;

}
.member-name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.team-area {
  width: 50%;
  text-align: center;
  margin-top: 30px;
}

.first-last {
  max-width: 62px;
  height: 62px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 50%;
  font-size: 20px;
  color: #c2c2c2;
  margin: 0px auto;
  margin-bottom: 10px;
  border: 1px solid #e8e4e4;
}
.team-members h6 {
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin-top: 15px;
}
.team-area {
  font-size: 13px;
  font-weight: 600;
  color: #242424;
}
.open-client .calendar-team-member {
  margin-left: -300px;
  transition: all ease .5s;
}
.cal-options {
  text-align: center;
}

.gold-color {
  height: 32px;
  width: 35px;
  background-color: #e2ca8e;
  border-radius: 5px;
  margin: 0px 6px;
}
.green-color {
  height: 32px;
  width: 35px;
  background-color:#7ddf7d;
  border-radius: 5px;
  margin: 0px 6px;
}
.blue-color {
  height: 32px;
  width: 35px;
  background-color: #97baff;
  border-radius: 5px;
  margin: 0px 6px;
}
.display-section, .apt-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.display-view h5, .apntment-colors-view h5 {
  text-align: left;
  margin-bottom: 25px;
  color: white;
  font-weight: 500;
  font-size: 15px;
}
.calendar-main-page .admin-content {
  padding-top: 0px;
}
.calender-option img {
  width: 15px;
}

.team-member {
  text-align: center;
}

.team-member p {
  font-size: 12px;
  margin-bottom: 0px;
  color: #80d1f5;
  font-style: italic;
}
.calendar-date-client {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.member-location img {
  height: 16px;
  transform: rotate(
-90deg);

cursor: pointer;
}

.member-location {
  background-color: #0070c0;
  color: white;
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.member-location h6 {
  font-size: 18px;
}
.calendar-main-page .admin-content-view {
  padding-bottom: 0px;
  overflow: inherit !important;
}

.calendar-main-page .admin-content {
  padding-bottom: 0px;
}
.popup-section {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000054;
  z-index: 999;
}
/* .calendar-fixed {
  overflow: hidden;
} */
.calendar-container .team-members {
  padding: 25px;
  height: calc(100vh - 368px);
  background-color: white;
  overflow-y: auto;
  padding-bottom: 120px;
}
.calendar-container .team-members::-webkit-scrollbar {
  width: 0px;
}
.calendar-container .team-members::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.calendar-container .team-members::-webkit-scrollbar-thumb {
  background: #888;
}
.calendar-container.close-client-popup .calendar-team-member {
  right: -300px;
}
.calendar-modal .modal-body button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button::after {
  background: none;
}
.calendar-container .fc .fc-scrollgrid, .fc .fc-scrollgrid table{
  width: 100% !important;
}
.calendar-container .fc .fc-scroller-liquid-absolute{
  position: initial !important;
}
/* .calendar-view-page {
  z-index: 99;
  position: relative !important;
  table-layout: auto !important;
  overflow-y: auto !important;
  user-select:initial !important;
  -webkit-user-select: initial !important;
  -webkit-touch-callout: initial !important;
  -webkit-overflow-scrolling: touch !important;
} 

html.calendar-main-page body {
  user-select:initial !important;
  -webkit-user-select: initial !important;
  -webkit-touch-callout: initial !important;
  -webkit-overflow-scrolling: touch !important;
  overflow-y: auto !important;
}

html.calendar-main-page body {
  user-select: all;
  user-select:initial !important;
  -webkit-user-select: initial !important;
  -webkit-touch-callout: initial !important;
  -webkit-overflow-scrolling: touch !important;
}
.fc-scrollgrid  tbody{
  overflow-y: scroll !important;
  height: 100vh;
  user-select:initial !important;
  -webkit-user-select: initial !important;
  -webkit-touch-callout: initial !important;
  overflow-x: hidden; 
  -webkit-overflow-scrolling:touch !important;
} 
 table.fc-col-header tbody{
  height: auto;
  overflow-x: hidden;
}
  .fc-unselectable{
  user-select:initial !important;
  -webkit-user-select: initial !important;
  -webkit-touch-callout: initial !important;
  -webkit-overflow-scrolling: touch !important;
  z-index: 99;
} 
 */

/* .calendar-container .calendar-inner * {
  -webkit-overflow-scrolling:touch !important;
}  */
.calendar-container .calendar-inner tbody {
  /* -webkit-overflow-scrolling: touch !important;
  overflow: auto!important; */
  /* overflow-y: auto; */
  /* touch-action: pan-y !important; */
} 
.calendar-container .calendar-inner tbody tr td {
  /* -webkit-overflow-scrolling: touch !important; */
  touch-action: pan-y !important;
} 


.calendar-container .fc .fc-scroller-liquid-absolute {
  /* position: initial !important;
  touch-action: inherit!important;
  user-select: inherit!important;
  -webkit-user-drag: inherit!important;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important; */
  overflow-y: auto;
  touch-action: pan-y !important;
}

/* .calendar-view-page {
  height: 100vh!important;
  overflow-y: auto!important;
} */


/*********Event Popup **************/
.time-keeper-popup.duration-popup.event-popup {
  width: 100%;
  max-width: 325px;
  padding: 40px 12px 20px;
}
.event-popup .pending-water-test {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
}

.event-popup .pending-water-test .open-date.client-notes {
  width: auto;
}
.event-popup-btn.footer-btn.view-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}
.event-popup-btn.footer-btn.view-btn  button:first-child{
background-color: transparent !important;
border: 1px solid #747070 !important;
}
.event-popup .pending-water-test h3 {
  font-size: 16px;
  word-break: break-word;
}
/* 
table.fc-scrollgrid.fc-scrollgrid-liquid thead {
  top: 145px;
  background: white;
  z-index: 99;
  min-height: 90px;
  position: fixed;
  border-top: 1px dashed #d0caca;
}
html.calendar-main-page .admin-wrapper{
  overflow: hidden;
    height: 100vh;
    position: fixed;
    width: 100%;
    
}
.calendar-view-page {
  overflow-y: scroll;
  padding-top: 90px;
  height: calc(100vh - 210px);
  margin-bottom: 100px;
  position: relative;
}
.calendar-view-page .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard{
  height: 100% !important;
} */


.calendar-container .calendar-inner tbody .fc-scroller-harness.fc-scroller-harness-liquid {
  height: calc(100vh - 300px);
   overflow-y: auto;
}

.calendar-container .calendar-inner thead tbody {
  height: auto;
}

/* .calendar-main-page {
  position: fixed;
  width: 100%;
  overflow: hidden;
}  */
.main-calendar .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion span.test-span2{
  margin-left: 0.5px;
}
/* @supports (-webkit-touch-callout: none) {
  .calendar-container .calendar-inner .fc-timegrid-slots {
    margin-bottom: 110px;
  }
}

@supports not (-webkit-touch-callout: none) {
  .calendar-container .calendar-inner .fc-timegrid-slots {
    margin-bottom: 52px;
  }
} */
.swc .swiper-container + div > a {
  display: none;
}







/**************** Calendar *************/

/* .calendar-view-page {
  margin-left: -1px;
  margin-right: 1px;
} */

.calendar-container .fc-timegrid-slot-lane {
  border: 0.7px solid #eef0f1 !important;
}

.calendar-container .main-calendar td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
  border-top: 0.7px solid #dee3e7 !important;
}

/* .calendar-container .fc-theme-standard td, .calendar-container .fc-theme-standard th{
  border: 1px solid #dee3e7;
} */

.calendar-container .fc-timegrid-slots tr:nth-child(4n) td.fc-timegrid-slot.fc-timegrid-slot-lane{
  border-bottom: 0.7px solid #dee3e7 !important;
}

.calendar-container .main-calendar .fc .fc-scrollgrid table{
  border-bottom: 0.9px solid #dee3e7!important;
}

.calendar-container .fc-theme-standard th, .calendar-container .fc-theme-standard td {
  border-left: 0.7px solid #eef0f1;
  border-right: none;
  border-collapse: collapse !important;
  position: initial !important;
}
.main-calendar .fc .fc-timegrid-slot {
  height: 1.50em !important;
}
.calendar-container .fc-theme-standard th:first-child, .calendar-container .fc-theme-standard td:first-child{
  border-left: none !important;
}
.demo-app.calendar-inner {
  padding-bottom: 0px !important;
}

/* .calendar-container .fc .fc-scrollgrid, .fc .fc-scrollgrid table thead{
  overflow: hidden;
    position: sticky;
    background: red;
    z-index: 9999;
    width: 100%;
    top: 0;
} */

.swc .swiper-container {
  height: 100vh;
}
html.calendar-main-page body.fixed-body-section {
  position: initial;
}
.calendar-main-page body{
  width: 100%;
  position: relative;
}

.new-cal .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 148px !important;
}
.new-cal .calender-date-section {
  margin-top: 0px;
  padding-top: 22px;
  position: sticky;
  z-index: 99;
  background-color: white;
  top: 70px;
  transition: all 0.2s ease-in-out 0s;
  min-height: 78px;
}
.new-cal .demo-app.calendar-inner {
  padding: 0px 0 !important;
}
.new-cal .fc .fc-scrollgrid-section-sticky > * {
  position: sticky;
  background-color: white;
  top: 150px;
  transition: all 0.2s ease-in-out 0s;
}

.new-cal .fc .fc-scrollgrid-section-sticky .fc-scroller-harness {
  background-color: white;
  min-height: 93px !important;
}
.calendar-container.shift-bottom .demo-app-main.main-calendar {
  margin-top: 92px;
}
.calendar-container.shift-up .calender-date-section {
  top: 0px;
    left: 0px;
    right: 0px;
}
.calendar-container.shift-up .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 78px !important;
  transition: all 0.2s ease-in-out 0s;
}
.new-cal .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  /* margin-top: 170px; */
  transition: all 0.2s ease-in-out 0s;
}
html.calendar-main-page, html.calendar-main-page body {
  overscroll-behavior-y: none;
}
span.appt-icons img {
  width: 15px;
  height: 15px;
  object-fit: cover;
}
span.appt-icons i {
color: #12bd12;
}

.total-option.appnt-colors .calendar-time .day-option {
  border: 1px solid #ffffff63;

}
.total-option.appnt-colors .calendar-time{
  opacity: 1 !important;
}
.appnt-colors .calendar-time.active .day-option{
  border: 1px solid white !important;
}
span.appt-icons img {
  width: 12px;
  height: 12px;
  object-fit: cover;
}
span.appt-icons {
  margin-left: 2px;
  display: flex;
  align-items: center;
  position: absolute;
  right: -2px;
  padding: 0px 2px;
}
.main-calendar .fc-event-main  p:first-child {
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
span.appt-iconsimg{
  margin-left: 3px;
}
span.appt-icons:first-child img{
  margin-left: 0px;
}
p.week-day-select{
  justify-content:flex-start !important;
  flex-wrap: wrap;
  position: relative;
}
p.day3-view {
  white-space: inherit !important;
  word-break: break-word!important;
}
@supports (-webkit-touch-callout: none) {
  .calendar-view-page{
    padding-bottom: 68.11px;
  }
}
.calendar-container .fc-theme-standard tr td {
  border-bottom: 0!important;
}

.calendar-container .main-calendar .fc .fc-timegrid-cols table {
  border-bottom: none!important;
}
p.repeated-week-day-select {
  display: flex;
  justify-content: flex-start !important;
  position: relative;
}
.custom-time-popup .search-popup.shift-container {
  margin: 10px 0px;
  width: 100%;
  margin: 0px auto;
  border-radius: 10px !important;
  padding: 18px !important;
}
.apppt-time-popup  .shift-container {
  /* width: 320px;
  margin: 10px auto; */
  border-radius: 10px !important;
  padding: 18px !important;
}

p.blocktype-section.week-day-select span.appt-icons img {
  filter: initial;
  position: absolute;
  right: 1px;
  background-color: darkgrey;
}
.calendar-container .fc .fc-timegrid-now-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: #1fd409;
  border-color: #1fd409;
  border-width: 2px 0 0;
}
.calendar-container .fc .fc-timegrid-now-indicator-arrow {
  border-color: #1fd409;
  border-top-color: transparent !important;
    border-bottom-color: transparent !important;
}

.main-calendar .fc-event-main .appt-block>p {
  font-style: normal;
  font-size: 11.5px;
  word-break: break-all;
  margin-bottom: 0;
  font-weight: 500;
}
.main-calendar .fc-event-main .appt-block>b, .main-calendar .fc-event-main .appt-block>p {
  max-width: 100%;
  overflow: hidden;
  line-height: 1.4;
  font-family: "Exo 2";
  white-space: nowrap;
}
.main-calendar .fc-event-main  .appt-block p:first-child {
  text-align: right;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

.main-calendar .fc-event-main .appt-block p:first-child, span.appt-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.calendar-inner .main-calendar .fc-timegrid-event .fc-event-main .appt-block{
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-flow: column;
  flex-flow: column;
  height: 100%;
  position: relative;
  font-weight: 600;
}
.main-calendar .fc-event-main .appt-block>i {
  font-style: normal;
  line-height: 1.4;
  word-break: break-all;
  font-family: "Exo 2";
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  font-size: 11.5px;
  white-space: nowrap;
}
.main-calendar .fc-event-main .appt-block>b {
  font-weight: 700!important;
  font-size: 12px;
}
.appt-block {
  color: black  !important;
}

.appt-block.completed-block {
  color: #404040  !important;
}

.main-calendar .fc-v-event .fc-event-main .block-type{
  color: #404040!important;
}
.main-calendar .fc-v-event .fc-event-main .personaltype-type{
  color: #fff!important;
}
.block-type.appt-block.blocktype-event * {
  color: black !important;
}
.upload-img-calc label.form-label {
  width: fit-content !important;
}

.main-calendar .fc .fc-non-business{
  background: #F7F7F5 url(../../assets/desktop-view/calenderBg.svg);
  opacity: 0.5;
}
