.chm-container {
  margin-bottom: 30px;
}
.chm-container h4 {
  color: #00bdf7;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  font-family: sans-serif;
}

.chm-form {
  background: #fff;
  border: 1px solid #00bdf7;
  padding: 40px 90px;
}
.chm-form .form-group {
  margin-bottom: 25px;
}
.chm-form .form-group:last-child {
  margin-bottom: 0;
}
.chm-form .form-control {
  border-radius: 0;
  padding: 8px 12px;
}
.chm-form .form-control::placeholder {
  color: rgb(0, 0, 0, 0.3);
}
.chm-btn {
  width: 100%;
  text-align: right;
  padding-top: 20px;
}
.chm-btn .btn.btn-add-chm {
  background: #00bdf7;
  color: #fff;
  border-color: #00bdf7;
  padding: 6px 10px;
}
.chlorinator-model .heading-table .table tbody td .delete-btn:last-child {
  color: #ff0000;
  text-decoration: underline;
}
.chlorinator-model .heading-table h5 {
  background: #0070c0 !important;
}
.chlorinator-model .heading-table tbody td {
  background-color: #eff9ff !important;
}
.chlorinator-model .heading-table {
  border: 1px solid #0070c0 !important;
}
.chlorinator-model .heading-table thead th {
  border-bottom: 1px solid #0070c0 !important;
}
.chlorinator-model .heading-table thead th:first-child,
.chlorinator-model .heading-table tbody td:last-child {
  text-align: center !important;
}
.chlorinator-model .heading-table .table-bordered tbody td:nth-child(2n) {
  text-align: left;
}
