.heading h3:before {
  content: "";
  position: absolute;
  width: 200px;
  height: 4px;
  background: #f8d400;
  top: 100%;
}
.heading {
  margin-bottom: 40px;
}
.heading h3 {
  font-size: 45px;
  position: relative;
  padding-bottom: 10px;
}
.block-box {
  width: 100%;
  display: flex;
}
.block-box.reverse {
  flex-flow: row-reverse;
}
.block-left,
.block-right {
  width: 50%;
}
.block-left {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* padding: 60px 40px 60px 0; */
}
.block-left {
  /* padding: 60px 40px 60px 40px; */
}
.block-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-img:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.block-img {
  width: 100%;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.block-content {
  width: 100%;
  padding: 30px 60px 30px 0;
  font-size: 18px;
  line-height: 30px;
  font-family: "Merriweather", Georgia, "Times New Roman", serif;
}
.reverse .block-content {
  padding: 30px 0 30px 60px;
}
.block-content h4 {
  font-size: 35px;
  margin-bottom: 30px;
}
.block-container.product-feature .block-btn {
  margin-top: 60px;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #555;
  background: #dcdcdc;
  font-family: "Merriweather", Georgia, "Times New Roman", serif;
}
