.service-title {
  background: #0048ba;
  text-align: center;
  padding: 10px 10px;
  margin-bottom: 0px;
  position: relative;
  border-radius: 5px;
}
.service-mbl {
  margin-bottom: 25px;
}
.service-title > span {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}
.service-title h4 {
  color: #fff;
  margin-bottom: 0;
  font-size: 25px;
  /* font-family: sans-serif; */

}
.product-service-container .heading-table .table-bordered thead th:first-child {
  text-align: center !important;
}
/* form modal css */
.modal-backdrop {
  z-index: 99999;
}
.modal-backdrop.show {
  opacity: 0.7;
}
.modal {
  z-index: 99999;
}
.modal-title {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  background: #f2f2f2;
  padding: 5px 10px;
  border: 1px solid #bfbfbf;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}
.modal-content {
  padding: 20px 30px;
  padding-top: 0px;
}
.modal-header .close {
  padding: 0;
  margin: 0;
}
.close {
  float: right;
  font-size: 30px;
  text-shadow: none;
  top: 0;
  position: absolute;
  right: 10px;
}
.modal-header {
  border-bottom: none;
}
.modal-form label.form-label {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #333;
  /* padding-left: 18px; */
}
.modal-form input.form-control {
  border-radius: 0;
  font-weight: 400;
}
.modal-form textarea.form-control {
  border-radius: 0;
  font-weight: 400;
  resize: none;
}
.modal-form input.form-control::placeholder,
.modal-form textarea.form-control::placeholder {
  color: rgb(0, 0, 0, 0.5);
}
.modal-footer {
  border-top: none;
}
.modal-footer .view-btn {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.view-btn .back-btn {
  color: #000;
  background: transparent;
  border: none;
  min-width: auto;
  font-size: 16px;
  float: left;
  padding: 0;
  width: 100%;
  margin-right: auto;
  text-align: left;
}
.view-btn .back-btn:hover,
.view-btn .back-btn:focus {
  background: transparent;
  border-color: transparent;
  color: #0048ba;
  box-shadow: none;
}

.modal-form select.form-control {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #333;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
}
.select-box {
  position: relative;
}
.modal-form .select-box > i {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  background: rgb(0, 0, 0, 0.1);
  border: 1px solid rgb(206 212 218);
  height: 100%;
  width: 30px;
  font-size: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  display: none;
}
.percent:after {
  content: "%";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 18px;
  color: #a5a5a5;
  font-weight: 700;
}
.percent {
  position: relative;
}
.table-icons .btn {
  min-width: auto;
  padding: 5px 10px;
  color: #fff;
  background: transparent;
  border: none;
  font-size: 18px;
}

.table-icons {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-icons > i {
  color: #fff;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
}

.table-data .mob-accordion {
  display: none;
}

.heading-accordion h5 {
  margin-bottom: 0;
  font-family: "Exo 2";
  font-size: 18px;
}

.heading-accordion {
  position: relative;
  /* background: #00b0f0; */
  /* background: #9f9f9f; */
  background: white;
  padding: 8px 10px;
  color: black;
}
.table-data .mob-accordion .card-header {
  border-bottom: none;
}
/* .table-data .card .accordion {
  border-bottom: 1px solid #ddd;
} */
.table-data .card .accordion:last-child {
  border-bottom: none;
}
.table-data ul.grid-list .grid-box h3 {
  padding-left: 3px;
}
.service-title span.sort-icon {
  width: 15px;
  height: 100%;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.service-title span.sort-icon img {
  width: 100%;
  height: 100%;
}
.service-modal .close:after,
.subcategory-modal .close:after {
  top: -10px;
  right: -35px;
}

.product-service-list i{
font-size: 18px;
padding-right: 8px;
color: #373636;
}
.product-service-list{
  display: flex;
  align-items: baseline;
}
.subcategory-modal  .view-btn {
  margin-bottom: 0;
  margin-top: 20px;
}
.subcategory-modal .modal-title {
  font-size: 20px;
}
.service-modal .view-btn {
  margin-bottom: 0;
  margin-top: 20px;
}
.service-mbl .service-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;
}
.services-header button {
  padding: 10px;
  width: fit-content;
  min-width: fit-content;
}
.service-mbl .card-header {
  padding: 0px;
  border: none;
}
.service-mbl .service-title h4 {
  color: #373636;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}
.service-mbl .card {
  border: none;
}
.service-container .service-title {
  padding: 10px 15px;
}
.service-mbl .collapse {
  /* background-color: white; */

  border-radius: 5px;
}
.service-mbl .table-data .card .accordion {
  border-bottom: none !important;
}
/* .service-mbl .mob-table {
  padding: 0px 12px;
} */
.services-header span, .services-header button {
  color: #0048ba;
}
.service-mbl .table-data .mob-accordion {
  display: block;
  padding: 10px 0px;
}
.service-mbl .outer-accordion.accordion .heading-accordion {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

.service-edit-icon i {
  color: #656565;
  font-size: 14px;
}
.sub-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-category .time-category{
  color: red;
}
.sub-category .time-category {
  color: #7d7d7d;
  font-size: 14px;
}
/* .service-mbl {
  display: none !important;
} */
.service-mbl .heading-accordion h5 > i {
  padding-right: 5px;
}
.service-mbl .mob-accordion {
  display: block;
  padding: 15px 0px;
}
.service-mbl.products {
  margin-top: 5px;
}
/* .service-mbl .heading-accordion h5 > i {
  font-size: 16px;
  font-size: 18px;
  color: #cccdcf;
}
.services-header button i{
  font-size: 16px;
  font-size: 18px;
  color: #cccdcf;
} */
.sorting img {
  cursor: pointer;
  margin: 1px;
  width: 32px;
}
.up-down img {
  width: 26px;
  cursor: pointer;
  background-color: transparent;
  margin: 1px 5px;
  border-radius: 50%;
  padding: 5px;
}
/* .service-mbl .sub-category .time-category .sorting {
  margin-right: 12px;

} */
.sorting.up-down:last-child{
  padding-right: 0px !important;
}
.sorting.up-down {
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  padding: 0px 10px;
  margin-top: 4px;
}

.product-service-container .view-btn {
  margin-top: 0px;
}

.active-sort img{
  opacity: 1 !important;
}


.action {
  color: #949496;
  font-size: 18px;
}

.action i {
  margin-right: 10px;
  color: #b4b5b8 !important; 
}
.sorting img {
  margin-top: 4px !important;
}
.service-edit-icon.add-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timehour {
  color: #a29d9d;
  font-size: 12px;
}
.services-header.active-sort .timehour {
  display: none !important;
}
.service-mbl .outer-accordion.accordion .card-body {
  padding-left: 26px;
  padding-right: 0px !important;
}
.service-mbl .mob-accordion .inner-accordion .card-header {
  padding: 10px 0px;
}
.service-mbl .service-container .service-title.title-service .btn i {
  color: #808183;
}

.service-mbl .heading-accordion h5 > i {
  color: #808183;
}
.product-service-list i {
  font-size: 20px;
  padding-right: 10px;
  color: #cccdce;
}

.service-edit-icon button {
  min-width: fit-content !important;
  width: auto;
  padding: 0px;
  background-color: transparent !important;
  border: none !important;
}
.service-edit-icon .action {
  padding: 8px 12px;
}
.service-edit-icon .dropdown-toggle::after {
  display: none;
}
.service-mbl .mob-table {
  padding: 0px 20px;
}
.up-down img:last-child{
  margin-right: 0px !important;
}
.service-mbl ul .grid-list-item {
padding-left: 28px;
padding: 0px;
}

.service-mbl .table-data .mob-accordion {
  border-bottom: 1px dashed #dcdcdc;
}
.product-service-container .grid-box .action-btn button img {
  width: 18px;
  /* line-height: 49px; */
  margin-top: -4px;
}
.action img {
  width: 22px;
  margin-right: 5px;
}
.service-edit-icon .dropdown-menu.show {
  box-shadow: 0px 0px 3px 0px #e8dfdf;
  border: none;
  z-index: 9;
}
.react-confirm-alert-body .react-confirm-alert-button-group button {
  padding: 8px 15px !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  width: 25%;
}
.service-mbl .sub-category .time-category {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}
.product-service-container .table-data .outer-accordion.accordion .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70% !important;
}
.service-mbl .table-data .mob-accordion .card-header {
  display: flex;
  align-items: baseline !important;
}
.product-service-container .main-titles h3 {
  text-transform: capitalize;
}
.service-mbl .mob-table {
  margin-top: 25px;
}
.service-mbl .mob-accordion:last-child {
  border-bottom: none !important;
}
.service-mbl .mob-table {
  background-color: white;
  border-radius: 5px;
}
.service-mbl .card{
  background-color: transparent;
}
.service-mbl .card-header{
  border-radius: 5px !important;
}
.service-mbl .sub-category .time-category img {
  opacity: 0.5;
}
.service-mbl .mob-accordion .inner-accordion .sub-category.card-header {
    align-items: baseline;
}
.subcategory-modal input {
  border: 1px solid #ced4da;
  height: 35px;
  border-radius: 5px;
  width: 100%;
  padding: 6px 10px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  color: #a5a5a5;
  font-weight: 400 !important;
}
.subcategory-label input{
  color: #a5a5a5;
  font-weight: 400 !important;
}
.subcategory-modal textarea{
  color: #a5a5a5;
  font-weight: 400;
}
.subcategory-modal input::placeholder{
  color: #a5a5a5;
}
.subcategory-modal .select-box select.form-control{
  height: 35px;
}
.subcategory-modal .modal-form textarea.form-control {
  font-size: 18px;
  padding: 6px 10px;
}
.subcategory-modal .modal-header {
  padding-bottom: 0px !important;
  padding: 8px;
}
.service-mbl .accordion>.card {
  overflow: inherit !important;
}
.service-modal input {
  color: #a5a5a5 !important;
}
.service-modal .form-group {
  position: relative;
}
/* .subcategory-modal .subcategory-label {
  display: flex;
  padding: 0px 15px;
} */
.subcategory-modal .cate-label.form-group {
  width: 25%;
}
.full-cate {
  width: 70%;
  margin-right: 10px;
}
.subcategory-modal .modal-form .subcategory-label  label.form-label{
  padding-left: 0px !important;
}
span.input-text-right {
  top: 4px;
  position: absolute;
  right: 12px;
  font-size: 12px;
  color: red;
  font-style: italic;
    text-transform: lowercase;
}

.italic-text input::placeholder {
  color: red !important;
  font-style: italic;
}

.italic-text textarea::placeholder {
  color: red !important;
  font-style: italic;
}

span.multilines {
  font-size: 12px;
  color: red;
  position: absolute;
  top: 18px;
  left: 25px;
}
.italic-text.multilines-text{
  position: relative;
}
span.price-symbol {
  position: absolute;
  left: 21px;
  top: 6px;
  font-size: 11px;
  color: #a5a5a5;
}



.italic-text.price-tag.form-group.row input {
  padding-left: 15px;
}
span.span-measure {
  font-style: italic;
  text-transform: lowercase;
  color: #9b9b9b !important;
  padding-left: 10px;
}
.italic-text span.input-text-right {
  color: #a5a5a5;
}
.subcategory-modal .select-box-duration button, .subcategory-modal .select-box button:hover, .subcategory-modal .select-box button:focus  {
  border: 1px solid #ced4da;
  height: 35px;
  border-radius: 5px;
  width: 100%;
  padding: 6px 10px;
  -webkit-appearance: none !important;
  appearance: none !important;
  color: #a5a5a5;
  font-weight: 400 !important;
  text-align: left;
  background-color: transparent;
  font-size: 13px;
}
.subcategory-modal .select-box-duration .btn-primary:not(:disabled):not(.disabled):active{
  color: #a5a5a5 !important;
  border: 1px solid #ced4da !important;
  border-color: #ced4da !important;
}
.product-service-container .mob-table .heading-accordion h5 {
  display: flex;
  align-items: baseline;
}
span.span-measure.inner-measure {
  font-size: 12px;
  font-weight: 400;
  padding-left: 5px;
}
.service-container .service-title {
  display: none;
}


.bottom-section {
  margin-top: 50px;
}

.bottom-section .checklist-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bottom-section .checklist-heading img {
  height: 20px;
  margin: 0px 6px 0px 0px;
  cursor: pointer;
}

.bottom-section .checklist-heading i {
  font-size: 20px;
  color: #4b7ece;
  cursor: pointer;
}

.bottom-section .checklist-heading .checklist-icon {
  display: flex;
  align-items: center;
}
.checklist-text {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dddddd;
  padding: 7px 10px;
  border-radius: 5px; */
  font-size: 16px;
  color: #999595;
  margin-bottom:10px;
}

.checklist-text img {
  width: 10px;
  opacity: 0.5;
  margin-left: 15px;
  cursor: pointer;
}
.checklist-text p {
  margin-bottom: 0px;
  display: flex;
  align-items: baseline;
  line-height: initial;
}
.checklist-text p span {
  margin-right: 5px;
}
.bottom-section .view-btn{
  margin: 30px 0px 10px 0px;
}
.checklist-heading h6 {
  font-size: 20px;
  margin-bottom: 0px;
}

.checklist-text span {
  position: absolute;
  right: 12px;
  top: 6px;
}
.checklist-text.disable-input input {
  pointer-events: none;
}
span.sorting-img {
  right: 35px;
}

span.sorting-img img {
  margin-left: 10px !important;
  cursor: pointer;
}
.checklist-content.checklist-content-sorting .checklist-text input {
  padding-right: 65px;
}
p.custom-text-checklist {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}
span.number-text {
  position: absolute;
  left: 12px !important;
  width: fit-content;
}

.checklist-text input {
  padding-left: 24px !important;
}
.service-mbl .yellow-text{
  color: #ffc310 !important;
}
.relative-section {
  position: relative;
}