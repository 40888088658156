.default-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f7f7f7;
}
.default-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06274509803921569);
  font-size: 16px;
}

.default-content img {
  height: 300px;
  margin-bottom: 20px;
}
.default-content h3 {
  font-family: "Exo 2", sans-serif;
}
.default-btn > a {
  max-width: 200px;
  display: block;
  background: #000;
  text-align: center;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 30px auto 0;
  transition: all 0.5s;
}
.default-btn > a:hover {
  color: #fff;
  text-decoration: none;
}
.default-content > p {
  max-width: 550px;
  margin: 0 auto;
}
