.company-form label.form-label,
.location-form label.form-label {
  color: #333;
  text-transform: uppercase;
  padding: 0 !important;
}
.company-form input.form-control,
.location-form input.form-control {
  font-weight: 400;
  border-radius: 0;
}
.company-form input.form-control::placeholder {
  color: rgb(0, 0, 0, 0.5);
}
.company-form select.form-control {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #333;
  background: transparent;
  z-index: 9;
  position: relative;
}
.select-box {
  position: relative;
}
.company-form .select-box > i {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  background: rgb(0, 0, 0, 0.1);
  border: 1px solid rgb(206 212 218);
  height: 100%;
  width: 30px;
  font-size: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.setting-container .heading-table span {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #ffff;
}
.setting-container .heading-table .table-bordered tbody td:first-child {
  text-align: left;
}
.setting-container .heading-table {
  margin-bottom: 30px;
}
.setting-container .heading-table tbody td {
  background-color: #eff9ff !important;
}
.company-form .view-btn,
.location-form .view-btn {
  padding-top: 20px;
}
