.staff-upper-form {
  padding: 10px 0 35px;
}
.staff-upper-form select.form-control,
.staff-form select.form-control {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #333;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
}
.select-box {
  position: relative;
}

.datetime-table .dropdown .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 8px;
  border-radius: 0;
  height: 100%;
  background: transparent;
  color: #000;
  border: 1px solid #bfbfbf;
  box-shadow: none;
  appearance: none;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}
.datetime-table .dropdown .btn:focus {
  border-color: #bfbfbf;
}
.table-dropdown.datetime-table {
  margin: 20px 0 0;
}
.datetime-table .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  content: "\f0d7";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  border: none;
  height: 100%;
  width: 30px;
  font-size: 20px;
  color: #000;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.datetime-table .dropdown-menu.show {
  display: block;
  width: 100% !important;
  background: #a6daff;
  border-radius: 5px;
}
.table-dropdown.datetime-table span {
  margin-bottom: 5px;
  display: inline-block;
}

.datetime-table .table-dropdown.time .dropdown-menu.show {
  height: 130px;
  overflow: auto;
}
.datetime-table .head-table.table tbody td.table-dropdown {
  padding: 0;
}
.staff-container .view-btn {
  padding-top: 20px;
}
/* .head-table.table tbody td {
  padding: 0;
} */

.staff-table .head-table.table tbody td > a {
  color: #0056b3;
  text-decoration: underline;
  /* transition: 0.2s all ease-in; */
  /* font-weight: 400; */
}
.staff-table .head-table.table tbody td > a.mobile,
.staff-table .head-table.table tbody td > a.email {
  text-decoration: none;
}
.staff-table .head-table.table tbody td > a.mobile:hover,
.staff-table .head-table.table tbody td > a.email:hover {
  text-decoration: underline;
}
.staff-table .head-table.table thead th,
.staff-table .head-table.table tbody td {
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
}
.staff-table .head-table.table tbody td img {
  width: 20px;
}
.staff-form .view-btn {
  margin-bottom: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.staff-table .view-btn {
  margin-bottom: 0;
}
/* .staff-table .head-table.table {
  border-right: 0;
  border-bottom: 0;
}

.staff-table .head-table.table tbody tr:last-child td {
  border-bottom: 2px solid #0070c0;
}
.staff-table .head-table.table tbody tr:last-child td:last-child {
  border-bottom: 0;
}
.staff-table .head-table.table tbody td:last-child {
  border: none;
  border-left: 2px solid #0070c0;
} */

.staff-modal.modal .modal-content {
  padding: 20px 10px;
  background: #f6f7fb;
  padding-top: 0px;
}
.repeat-modal .modal-body,
.repeat-modal .modal-footer {
  padding: 20px 0;
}
.repeat-shift-container {
  /* padding: 20px 0; */
  background: #fff;
  border-radius: 5px;
  padding: 20px 20px 35px;
}
/* .action-btn {
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.check-btn.btn.btn-primary:hover {
  color: #168ee4;
  text-decoration: underline;
}

.check-btn.btn.btn-primary {
  background: transparent;
  color: #0070c0;
  border-color: transparent;
  padding: 4px 10px;
  min-width: auto;
  transition: 0.3s all ease-in;
  white-space: nowrap;
  /* text-align: left; */
  font-size: 12px;
  width: 100%;
}
/* .action-btn .btn {
  background: #0070c0;
  color: #fff;
  width: auto;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.action-btn .btn-primary.edit-btn {
  background: #0048ba;
  border-color: #0048ba;
  color: #fff;
}
/* .action-btn .btn-primary.delete-btn {
  background: #ca0808;
  border-color: #ca0808;
  color: #fff;
} */

.action-btn .btn-primary.delete-btn {
  background: #ccc8c8;
  border-color: #adabab;
}
.for-disable.form-group {
  position: relative;
}

.for-disable.form-group .form-control {
  position: relative;
  background: transparent;
}

.for-disable.form-group span.angle-right {
  position: absolute;
  right: 15px;
  top: 45px;
}
/* .staff-table .head-table.table tbody td:last-child {
  position: relative;
}
.staff-table .head-table.table tbody td:last-child:after {
  content: "";
  position: absolute;
  background-image: url(../../assets/home/uncheck.png);
  background-size: 30px;
  height: 30px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
} */
.staff-table .head-table.table tbody td:nth-child(2n) a {
  width: 125px;
  display: inline-block;
  word-break: break-all;
}
.staff-table .head-table.table tbody td.table-dropdown {
  padding: 0;
}
.datetime-table.btn-success:not(:disabled):not(.disabled).active,
.datetime-table .btn-success:not(:disabled):not(.disabled):active,
.datetime-table .show > .btn-success.dropdown-toggle {
  /* color: #00bdf7;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none; */
  color: #000;
  background-color: transparent;
  border-color: #e5e5e5;
  box-shadow: none;
}
.datetime-table .head-table.table tbody td {
  vertical-align: middle;
}
.staff-upper-form .select-box > i,
.staff-form .select-box > i {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  background: rgb(0, 0, 0, 0.1);
  border: 1px solid rgb(206 212 218);
  height: 100%;
  width: 30px;
  font-size: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.staff-upper-form .form-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.staff-upper-form .form-group label.form-label {
  width: auto;
  color: #333;
  font-size: 16px;
}
.modal-open .modal {
  padding-left: 0 !important;
}

.staff-form label.form-label {
  color: #333;
  text-transform: capitalize;
  padding: 0 !important;
  font-size: 14px;
  font-weight: 700;
}
.staff-form input.form-control {
  font-weight: 400;
  border-radius: 0;
  border-radius: 5px;
}
.staff-form input.form-control::placeholder {
  color: rgb(0, 0, 0, 0.5);
}
.staff-upper-form .select-box {
  position: relative;
  width: 50%;
}

.form-radio.form-group {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.form-radio.form-group .form-check + .form-check {
  margin-left: 10px;
  cursor: pointer;
}
.staff-form textarea.form-control {
  height: 120px;
  resize: none;
  border-radius: 5px;
}

span.check-img {
  display: inline-block;
}
/* modal css */

.datetime-table .head-table.table {
  margin-bottom: 15px;
}
.datetime-table .view-btn {
  margin-bottom: 0;
}
.datetime-table .head-table.table tbody td,
.datetime-table .head-table.table thead th {
  border: 1px solid #00b0f0;
  padding: 4px 10px;
  font-weight: 500 !important;
  font-size: 14px;
}
.datetime-table .head-table.table tbody td {
  padding: 0;
}
/* .react-datepicker-popper {
  top: 120px !important;
  left: -300px !important;
} */
.datetime-table .react-datepicker-popper {
  transform: inherit !important;
  top: inherit !important;
  bottom: -100%;
  left: inherit !important;
  right: 0;
}

.datetime-table .react-datepicker__triangle {
  display: none;
}
.datetime-table .head-table.table tbody td.table-dropdown span {
  display: none;
  padding: 4px 10px;
  font-weight: 500 !important;
  font-size: 14px;
}
.datetime-table .head-table.table tbody td:first-child {
  white-space: nowrap;
}
.dropdown {
  display: block;
}
.datetime-table.hideEdit .head-table.table tbody td.table-dropdown span {
  display: block;
}
.datetime-table.hideEdit .head-table.table tbody td.table-dropdown .dropdown {
  display: none;
}

.datetime-table.time .dropdown-menu.show {
  height: 130px;
  overflow: auto;
}

.react-calendar__navigation button {
  background: #0048ba;
  color: #fff;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #0048ba;
}
/* .card-header .btn {
  min-width: auto;
  font-size: 20px;
  color: #000;
  padding: 0;
  box-shadow: none;
  transition: 0.3s all ease-in;
} */
/* 
.card-header .btn .angle-right {
  transition: 0.3s all ease-in;
}
.card-header .btn:hover .angle-right,
.card-header .btn:focus .angle-right {
  display: inline-block;
  transform: rotateZ(90deg);
  transition: 0.3s all ease-in;
} */
/* .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  height: 40px;
  background: #fff;
  border-color: #ced4da;
} */

.table-dropdown .react-datepicker__input-container:after {
  display: none;
}
.table-dropdown input,
.table-dropdown input.react-datepicker-ignore-onclickoutside {
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  padding: 5px 8px;
}
.table-dropdown input:focus,
.table-dropdown input.react-datepicker-ignore-onclickoutside:focus {
  border: none;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 8px;
  color: #000;
}
.table-dropdown input::placeholder {
  color: #000;
}
.react-datepicker-wrapper:hover {
  background: #fff;
}
.startdate-modal .modal-body .react-calendar {
  width: 100%;
}
/* .startdate-modal .react-calendar__tile--now {
  background: #0048ba !important;
  color: #fff !important;
  border-radius: 5px;
} */
.staff-modal .modal-title {
  margin-bottom: 0;
}

.date-range {
  text-align: left;
  margin-bottom: 10px;
}

.date-range h3 {
  font-size: 20px;
  margin-bottom: 0;
}
.schedule-date-toolbar {
  width: auto;
}
.schedule-filters {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 40px;
}

.btn-group.js-date-toolbar {
  border: 1px solid #c2c2c2;
  padding: 1px;
  display: flex;
  height: 100%;
}
.schedule-filters .btn.btn-default.navigate {
  /* background-color: #ececec; */
  border: 1px solid #c2c2c2;
  color: #2c2c2c;
  border-radius: 0 !important;
  margin: -2px;
  cursor: default;
  padding: 0;
}

.schedule-filters .arrow-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.arrow-icons span {
  margin: 0;
  font-size: 10px;
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #c2c2c2;
  padding: 0 6px;
}
.arrow-icons span:last-child {
  border: none;
}
.select-date .form-control {
  position: absolute;
  top: 0;
  border: transparent;
  background: transparent;
  color: transparent;
  left: 0;
  width: 100%;
  cursor: default;
}

.select-date {
  position: relative;
  cursor: default;
}

.select-date .form-control:focus {
  outline: none;
  box-shadow: none;
  color: transparent;
  background: transparent;
}

.select-date input[type="date"] {
  cursor: pointer;
  opacity: 0;
}

.select-date input[type="date"]:after {
  content: "\25BC";
  color: transparent;
  padding: 0 5px;
  cursor: pointer;
}

.select-date input[type="date"]:hover:after {
  color: transparent;
}

.select-date input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  outline: none;
  height: auto;
  color: transparent;
  background: transparent;
}
span.display-date {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  z-index: -1;
  position: relative;
}

.btn.btn-default.select-date {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.select-date input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

.select-date input[type="date"]::-webkit-clear-button {
  z-index: 1;
}
.select-date input [type="date"].form-control,
.select-date input[type="datetime-local"].form-control,
.select-date input[type="month"].form-control,
.select-date input[type="time"].form-control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
.react-calendar__month-view__days {
  padding: 10px;
}
.react-calendar__month-view__weekdays {
  padding: 10px 10px 0;
}
/* .table-striped tbody tr:nth-of-type(odd) {
  background-color: #00bdf71f;
} */
.react-confirm-alert-body > h1 {
  font-size: 25px;
  font-family: "Exo 2" !important;
}
.react-confirm-alert-body {
  text-align: center !important;
  font-family: "Exo 2" !important;
}
.react-confirm-alert-body .react-confirm-alert-button-group {
  justify-content: center !important;
}
.placehoder-text {
  font-size: 20px;
  color: #000;
  padding: 20px 0;
}
.select-wrapper select.form-control {
  height: 50px;
  width: 150px;
  border-radius: 0;
  color: #000;
  font-weight: 600;
}

.select-wrapper .form-group {
  margin-bottom: 0;
}
.startdate-modal .modal-content,
.startdate-modal .modal-header,
.startdate-modal .modal-body {
  padding: 0;
  border: none !important;
}

/* .number.form-group .form-control {
  padding-left: 60px;
} */

.table-input-box.datetime-table {
  padding: 50px 0 0;
}
.table-input-box.datetime-table .form-control:disabled,
.table-input-box.datetime-table .form-control[readonly] {
  background-color: #fff;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  border-color: #bfbfbf;
  height: 42px;
}
/* .number.form-group .form-label {
  margin-bottom: 10px;
} */
.number-field {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.number-box .dropdown .dropdown-toggle.btn.btn-success {
  background: #fff;
  border-radius: 0;
  border-color: #ced4da;
  color: #000;
  min-width: auto;
  border-right: 0;
  display: flex;
  align-items: center;
  padding: 1px 8px;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.number.form-group .number-field .form-control {
  /* padding-left: 10px; */
  /* padding: 6px 5px; */
  border-left: 0;
  /* height: 100%; */
  /* box-shadow: none; */
  border-color: #ced4da;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.flag-icon img {
  width: 100%;
  border-radius: 100%;
  height: 100%;
  vertical-align: top;
  /* object-fit: cover; */
}
.number.form-group .flag-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.country-code {
  font-weight: 400;
  padding-left: 5px;
}
.number-box {
  position: relative;
  width: 70px;
}
.number-box .dropdown {
  width: 70px;
}
.number-field .special-label.form-label {
  background: #fff;
  padding: 5px !important;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 0;
  font-family: "Exo 2" !important;
  height: auto;
  width: auto;
  min-width: auto;
  display: inline-block;
  text-transform: capitalize;
  position: absolute;
  top: -10px;
  left: 25px;
  z-index: 99;
}
.number-field a.dropdown-item {
  display: flex !important;
  align-items: center;
  padding: 13px 10px !important;
}
.number-field .dropdown-item.active,
.number-field .dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  outline: none;
}
/* 
.number-field .react-tel-input {
  width: 150px;
}
.number-field .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.number-field .react-tel-input .form-control {
  width: 100%;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
  padding-left: 50px;
}
.number-field .form-control.mobile-num {
  border-left: none;
  padding: 18px 10px;
  box-shadow: none;
}
.number-field .react-tel-input .selected-flag {
  border-left: 1px solid #ced4da;
} */
.close {
  font-size: 40px !important;
  top: -7px !important;
  z-index: 99999;
}

/********************************** Only for Mobile Accordion table *********************************/
.datetime-table .mob-table {
  display: none;
}
.staff-container .mob-accordion .card {
  margin-bottom: 15px;
  border-radius: 5px !important;
  border: none;
  /* box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%); */
  padding: 0px 12px;
}
.mob-accordion .card-header {
  padding: 8px 10px;
  background: #fff;
  color: #333;
}
.mob-accordion .card-header .btn.btn-link {
  width: 100%;
  text-align: left;
  text-decoration: none;
  font-size: 18px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Exo 2" !important;
  font-weight: 600;
}
.mob-accordion .card-header .btn.btn-link > i {
  font-size: 25px;
  padding-right: 10px;
  color: #808183;
}
.mob-accordion.accordion > .card > .card-header {
  border-bottom: none;
}
.mob-accordion.accordion .card-body {
  padding: 0 10px 20px;
}
ul.staff-list {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-left: 33px;
}
.staff-container .staff-list-item {
  width: 100%;
}

ul.staff-list .staff-box,
ul.grid-list .grid-box {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 16px;
  padding: 10px 0;
  font-family: "Exo 2" !important;
}
ul.staff-list .staff-box {
  align-items: center;
}
ul.staff-list .staff-box h3,
ul.grid-list .grid-box h3 {
  width: 50%;
  text-align: left;
  font-size: 17px;
  padding-right: 10px;
  font-family: "Exo 2" !important;
  font-weight: 600;
  padding-left: 0px;
}
ul.staff-list .staff-box h5,
ul.grid-list .grid-box .grid-content {
  width: 50%;
  font-size: 17px;
  line-height: 17px;
  /* font-family: "Exo 2", sans-serif; */
  font-weight: 400;
  text-align: left;
  padding: 0 10px;
  font-family: "Exo 2" !important;
  color: #9b9b9b;
}
ul.staff-list .staff-box h3 {
  width: 40% !important;
}
ul.staff-list .staff-box h5 {
  width: 60% !important;
}
.action-btn .btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  min-width: 40px;
}

.action-btn .eye-btn.btn-primary,
.action-btn .eyehide-btn.btn-primary {
  background-color: #1aa01d;
  border-color: #1aa01d;
  color: #fff;
}
.staff-box .action-btn .btn.delete-btn,
.grid-box .action-btn .btn.delete-btn {
  background: #f43c3c;
  border-color: #f43c3c;
  color: #fff;
}
.staff-box .action-btn,
.grid-box .action-btn {
  padding: 0 10px;
}
.staff-box .action-btn .btn,
.grid-box .action-btn .btn {
  height: 40px;
  width: 40px;
  line-height: 40px;
  padding: 0;
  min-width: 40px;
  border-radius: 50px;
}
ul.staff-list li.staff-list-item:last-child .staff-box,
ul.grid-list li.grid-list-item:last-child .grid-box {
  align-items: center;
}
ul.staff-list .staff-box h3,
ul.staff-list .staff-box h5,
ul.grid-list .grid-box h3,
ul.grid-list .grid-box .grid-content {
  margin-bottom: 0;
}

ul.staff-list .staff-box h5 td {
  width: 100%;
  display: inline-block;
}
.dropdown-modal .modal-body,
.dropdown-modal .modal-footer {
  padding: 20px 0;
  flex: 0;
}
/* .main-titles-mob {
  display: none;
} */
.view-btn .add-btn.btn-primary > i {
  padding-right: 5px;
}
.view-btn .blue-btn.btn-primary {
  display: none;
}
.view-btn.working-hour .blue-btn.btn-primary {
  display: block;
  margin-left: auto;
}

.staff-modal .close:after {
  background-size: 20px;
  width: 15px;
  height: 15px;
  top: 0;
}
.dropdown-modal .close:after {
  top: 0;
  right: -30px;
}
.startdate-modal .close:after,
.enddate-modal .close:after {
  content: none;
}
.staff-modal .number-field .number-box .dropdown-toggle::after {
  content: none;
}
.staff-modal .staff-form {
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}
.form-check-label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  line-height: 12px;
}
/********************************** ONly for different table *********************************/
/* .main-table.head-table.table {
  border-left: 0;
}
.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  max-height: 350px;
  height: auto;
}
.table-scroll table {
  width: 100%;
  min-width: 800px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  /* border: 1px solid #00b0f0; *
  background: #fff;
  vertical-align: top;
}
.table-scroll thead th {
  /* background: #333; */
/* color: #fff; *
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.main-table.head-table.table th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  border-left: 2px solid #0070c0;
  /* background: #ccc; *
}
thead th:first-child {
  z-index: 5;
} */
.staff-box .action-btn .fa-edit:before {
  content: "\f044";
  font-weight: 100;
}
.staff-box .eye-btn .fa-eye:before {
  content: "\f06e";
  font-weight: 100;
}

.staff-box .delete-btn .fa-trash-alt:before {
  content: "\f2ed";
  font-weight: 100;
}

.staff-close {
  position: relative;
  /* right: 20px; */
  top: 0px;
  /* background-color: #f6f7fb; */
  padding: 30px 0px;
  width: 100%;
  z-index: 999999;
  /* margin-bottom: 20px; */
}

.staff-close i {
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 9999999;
  color: #949191;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;
  content: "";
  background-image: url(../../assets/admin/close.png);
  background-size: 25px;
  width: 25px;
  position: absolute;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.staff-close i:before {
  display: none;
}
/* .staff-modal .modal-header {
  margin-top: 15px;
} */

.staff-modal .staff-form input.form-control {
  height: 35px !important;
  border-radius: 5px;
}
.staff-modal .number-box .dropdown .dropdown-toggle.btn.btn-success {
  height: 35px !important;
}
.staff-container .select-box select.form-control {
  height: 35px !important;
}
.staff-modal .select-box select.form-control {
  height: 35px !important;
}
.edit-working-popup .datetime-table .dropdown .btn {
  font-size: 14px;
  height: 35px;
  min-height: 35px;
  border: 1px solid #ced4da !important;
  opacity: 0.5;
}

.staff-modallabel.form-label {
  color: #333;
  text-transform: capitalize;
  padding: 0 !important;
  font-weight: 600;
}

.edit-working-popup .react-calendar__month-view__weekdays__weekday {
  font-family: "Exo 2" !important;
}
.edit-working-popup .react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: "Exo 2" !important;
}

.edit-working-popup .modal-title {
  font-size: 20px;
}

.edit-working-popup .modal-header {
  padding-bottom: 0;
  padding: 10px 8px;
}

.staff-container .staff-box .action-btn button img {
  width: 18px;
  margin-top: -5px;
}
.staff-container ul.staff-list .staff-box h5 {
  font-size: 20px;
  color: #7c7c7c;
}
.staff-modal .staff-list .staff-list-item {
  width: 100%;
}
.staff-modal .mob-accordion .card-header .btn.btn-link > i {
  color: #808183;
  font-size: 18px !important;
  line-height: 20px;
}
.staff-modal ul.staff-list .staff-box h5 .check-btn {
  padding: 0px;
}
.staff-modal .mob-accordion {
  margin-top: 15px;
}
.staff-modal .card {
  border: none;
  border-radius: 5px;
}
.staff-modal .mob-accordion .card-header .btn.btn-link {
  padding: 12px 12px !important;
  font-size: 15px;
}
.staff-container .staff-main-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.staff-container .accordion > .card {
  overflow: initial;
}
.staff-container .client-option.service-edit-icon .dropdown-menu {
  min-width: 9rem;
  z-index: 99;
}
.staff-container .client-option.service-edit-icon .dropdown-menu .action {
  color: #b4b5b8;
  display: flex;
}
.startdate-modal
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button::after {
  background: url("../../assets/home/right2.png") !important;
}
.startdate-modal .search-popup.shift-container {
  margin: 10px 0px;
}
.staff-modal ul.staff-list .staff-box h5 h6 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}
.staff-modal .schedule-date-toolbar .date {
  line-height: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.staff-modal .schedule-date-toolbar .date {
  line-height: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.staff-modal .calendar-filter-icons span {
  background-color: #ededed;
  height: 32px;
  width: 32px;
  margin: 0px 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.staff-modal .calendar-filter-icons {
  display: flex;
  justify-content: space-between;
}
.staff-modal .calendar-date-client {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 4px 0px;
}
.edit-working-popup .table-dropdown.datetime-table .dropdown-menu.show {
  background-color: white;
}
.staff-container .service-edit-icon button img {
  margin-top: -4px;
  width: 12px;
}
.staff-form .form-group .form-check input {
  padding: 0px !important;
  cursor: pointer;
}
.profile-page .number .dropdown-toggle::after{
  display: none;
}
.profile-page .view-btn.staff-form-btn{
  justify-content: space-between !important;
}
.staff-container  .target-heading {
  color: black;
  padding: 14px 12px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 25px;
}
@media screen and (min-width: 991px) {
  .number-box .dropdown .dropdown-toggle.btn.btn-success {
    height: 40px;
    width: 100%;
  }
  .number-box .dropdown {
    width: fit-content;
  }
  .number-box {
    width: fit-content;
  }
  .staff-container .mob-accordion .card-header .btn.btn-link, .client-container .mob-accordion .card-header .btn.btn-link {
    padding: 15px 0px;
}
}
