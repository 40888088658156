.pb-heading {
  background: #0048ba;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 10px;
}
.pb-heading h5,
.pb-heading h4 {
  margin-bottom: 0;
  color: #fff;
  font-size: 18px;
  font-family: "Exo 2", sans-serif;
}
.pb-heading h4 {
  width: 50%;
  text-align: center;
}
.pb-heading h5 {
  width: 50%;
}
.pb-table.table.pb-table thead th {
  vertical-align: middle;
  border-bottom: 1px solid #a6a6a6;
  background: #d9d9d9;
  color: #000;
}
.pb-table.table-bordered td,
.pb-table.table-bordered th {
  border: 1px solid #a6a6a6;
  padding: 8px 10px;
}
table.pb-table {
  text-align: center;
  border: 1px solid #a6a6a6 !important;
}
.pb-table.table.pb-table tbody td {
  text-align: center;
}

.pb-table.table.pb-table tbody td:first-child,
.pb-table.table.pb-table thead th:first-child {
  text-align: left;
}

.access-level .custom-switch .custom-control-label::after {
  width: calc(1rem - 0px);
  height: calc(1rem - 0px);
  background-color: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.access-level .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 40px;
  border-radius: 50px;
}
.access-level .custom-control-label::before {
  height: 20px;
  border-radius: 50px;
  background-color: #ccc;
  border: #ccc solid 1px;
  cursor: pointer;
}
.access-level
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
/* .permission-box.permission-box-mob .mob-accordion.accordion {
  display: none;
} */
.permission-container
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #4cd864;
  background-color: transparent;
}
.permission-container .permission-box-mob .staff-box {
  display: flex !important;
  justify-content: space-between !important;
}
.permission-container .permission-box-mob h3 {
  width: 50%;
}
.permission-container .permission-box-mob h5 {
  width: 50%;
  text-align: right !important;
}
.permission-container .permission-box-mob .custom-control-label::after {
  position: absolute;
  top: 6px !important;
  left: -21px;
  border-radius: 50% !important;
  display: block;
  width: 18px !important;
  height: 18px !important;
  content: "";
  border: 1px solid #adb5bd;
  transition: all ease 0.5s;
  background-color: transparent;
  box-shadow: 0px 0px 5px #e7e7e7;
  display: none;
}
.permission-container .permission-box-mob .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 22px !important;
  height: 22px;
  pointer-events: none;
  content: "";
  border-radius: 25px !important;
  transition: all ease 0.5s;
}
.permission-container .permission-box-mob li.staff-list-item:last-child h5 {
  padding-right: 0px !important;
}

.permission-access {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.permission-access .basic {
  /* text-align: center; */
  /* display: grid; */
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.permission-access .basic h6 {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 700;
  color: #333;
}
/* .permission-access .custom-switch {
   padding-left: 2.25rem; 
  padding-left: 0px;
} */
.permission-access .basic span.check-img img {
  height: 23px;
  width: 23px;
  background-color: #5bd125;
  border-radius: 50%;
}
.permission-access .basic input {
  height: 21px;
  width: 21px;
  border-radius: 50% !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: 1px solid #ebb7b7;
  position: relative !important;
}
.permission-access .basic input:focus {
  outline: none !important;
  box-shadow: none i !important;
}
.permission-access .basic input:checked::after {
  content: "";
  position: absolute;
  /* height: 23px; */
  /* width: 8px; */
  top: -1px;
  left: -1px;
  padding: 12px;
  background: url(../../assets/home/check1.png);
  background-size: cover;
  background-color: #5bd125;
  border-radius: 50%;
  border: none !important;
}

.permission-level h6 {
  margin-bottom: 0px;
}

.permission-level {
  margin-bottom: 25px;
}
.permission-box.permission-box-mob .card {
  background: #fff;
  border-radius: 5px;
  padding: 10px 0px;
  border: none;
}

.card-body .permission-level h6 {
  text-transform: capitalize;
}

/******* new permission layout css **********/
.permission-new-layout ul.staff-list {
  padding-left: 20px;
}
.permission-new-layout ul.staff-list .staff-box h3 {
  width: calc(50% - 0px) !important;
  padding: 0px !important;
}
.permission-new-layout ul.staff-list .staff-box h5 {
  width: 50% !important;
  padding: 0px !important;
}
.permission-new-layout .permission-access {
  display: flex;
  justify-content: space-between;
}
.permission-new-layout .mob-accordion .card-header .btn.btn-link {
  width: calc(50% + 15px) !important;
}
.permission-new-layout .mob-accordion .card-header .permission-new-section {
  width: calc(50% - 15px) !important;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 20px;
}
.permission-new-layout .mob-accordion .card-header .permission-new-section h6 {
  margin-bottom: 0px !important;
  width: 33% !important;
  text-align: center;
}
.permission-new-layout .permission-access .basic {
  width: 33% !important;
}
.permission-new-layout .staff-main-heading {
  justify-content: space-between !important;
}
.permission-new-layout .permission-access .basic .form-check {
  padding-left: 0px !important;
  display: flex;
  justify-content: center
}
.permission-new-layout .mob-accordion.accordion .card-body {
  padding-right: 0px !important;
}
.permission-new-layout .form-check-input {
  margin-left: 0px !important;
}
li.staff-list-item.permission-type .staff-box {
  display: flex;
  justify-content: flex-end;
}

li.staff-list-item.permission-type .staff-box .permission-new-section {
  display: flex;
  justify-content: space-between;
}

li.staff-list-item.permission-type .staff-box .permission-new-section h6 {
  font-size: 13px;
  font-weight: 600;
  color: #333;
  width: 33%;
  text-align: center;
}
li.staff-list-item.permission-type .staff-box {
  padding-bottom: 0px !important;
}
ul.staff-list.permission-child li h3 {font-weight: 700 !important;}

ul.staff-list.permission-sub-child li h3 {font-weight: 600 !important;}
.permission-new-layout ul.staff-list.permission-sub-child h3 {width: calc(50% - 0px) !important;}

.permission-new-layout ul.staff-list.permission-sub-child .staff-box h5 {width: calc(55% + 10px) !important;}
.permission-new-layout .staff-main-heading .check-permission {
  width: calc(50% - 15px);
}
.permission-new-layout ul.staff-list .staff-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}