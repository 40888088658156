.chatbot-content img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin: 0px auto 0;
}
.chat-img {
  text-align: center;
  margin-bottom: 0;
  margin-top: 14px;
}
.chat-img h2 span {
  color: #0a23f2;
  font-weight: bold;
  letter-spacing: -1.4px;
}

.chat-img h2 {
  color: #a6a6a6;
  margin: 0.5rem 0 1rem;
  font-size: 36px;
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 26px;
  margin-top: 15px;
}
.cpbot-para h6 {
    font-size: 14px;
    font-family: "Exo 2", sans-serif;
    color: black;
    word-spacing: -0.5px;
    /* line-height: 0.1em; */
    font-weight: 400;
    margin: 0;
    display: flex;
}
.cpbot-para h6 p {
    white-space: pre-line;
    word-break: break-word;
}

.cpbot-para h6 span {
  font-weight: 700;
  margin-right: 5px;
  white-space: nowrap;
}
.chat-bot-page .input-section {
  /* margin-top: 40px; */
}
.chat-bot-page .input-section input::placeholder {
  font-style: italic;
  font-size: 12px;
  color: red !important;
  font-weight: 500;
}
.chat-bot-page .view-btn.staff-form-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  /* padding: 0 1rem; */
  margin-bottom: 0px;
}
/* .chatbot-content {
    background: #fff;
    border-radius: 5px;
    padding: 12px 12px;
    margin-bottom: 10px;
} */
.cpbot-para {
  margin-bottom: 0.9rem;
  /* padding-bottom: 15px; */
  /* margin-top: 10px !important; */
}
/* .chat-bot-messages {
    max-height: 145px;
    overflow: auto;
} */
.chat-bot-page .loader-section {
  background: transparent;
  height: -moz-fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
}
.chat-bot-page .view-btn .btn-edit {
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 10px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 38px !important;
  background-color: transparent !important;
  border-color: #747070 !important;
  color: #747070 !important;
  border: 1px solid #747070 !important;
}
.chat-bot-page .time-keeper-popup.duration-popup {
  padding: 0px 20px 25px 20px !important;
  max-width: 320px;
  width: 100%;
  /* max-height: 571px; */
  /* max-height: 553.47px; */
  max-height: 80vh;
  min-height: 560px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
}
.chat-bot-messages {
  position: relative;
  margin-bottom: 29.9px;
}
/* .chat-bot-messages {
    height: calc(100% - 400px);
    overflow: auto;
} */
.chat-bot-page .time-keeper-popup.duration-popup::-webkit-scrollbar,
.chat-bot-page .time-keeper-popup.duration-popup::-webkit-scrollbar-thumb:hover {
  width: 0px !important;height: 0px !important;
  opacity: 0 !important;
  visibility: hidden !important;display: none;
}
.duration-picker.time-picker-modal.chat-bot-page
  .time-keeper-popup.duration-popup {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 99999999999999 !important;
}
.duration-picker.time-picker-modal.chat-bot-page:before {
  z-index: 9999999999 !important;
}

/* Track */
.chat-bot-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat-bot-messages::-webkit-scrollbar-thumb {
  background: #888;
}
/* .chatbot-section {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    padding: 0px 20px 10px;
} */
.back-popup {
  display: block;
  background: linear-gradient(180deg, white 50%, transparent);
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 54px;
  z-index: 99;
  padding: 0;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* .chatbot-content{
    margin-top: -40px;
} */
.back-popup i {
  font-size: 24px;
  cursor: pointer;
  color: #909090;
}
.chat-bot-page .back-option.calc-option {
  margin-top: 0;
  margin-bottom: 0px;
  position: relative;
  left: -20px;
  margin-top: -45px;
  width: calc(100% + 40px);
  overflow: hidden;
  height: 70px;
  align-items: flex-start;
  border-radius: 15px;
  margin-bottom: -25px;
}
.chat-bot-page .back-option.calc-option:before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 0;
  background: linear-gradient(180deg, white 50%, transparent);
}
.chat-bot-page .back-option.calc-option i {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 25px;
  /* background-image: url(../../../src/assets/home/icon/close-cross-gray.png); */
  background-size: contain;
  opacity: 1;
  margin-top: 20px !important;
}
/* .chatbot-content{
    margin-top: -70px;
} */
.main-para h6 {
  font-size: 14px;
  font-family: "Exo 2", sans-serif;
  color: black;
  word-spacing: -0.5px;
  line-height: 1.2em;
  text-align: justify;
  text-align-last: justify;
}
.chatbot-section .input-section .form-group {
  margin-bottom: 0;
  position: relative;
}
.chatbot-section .form-group.form-group i {
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 16px;
  color: #9f9f9f;
  transform: translateY(-50%);
}

@supports (-webkit-touch-callout: none) {
  /* .chatbot-section .input-section .form-control::-webkit-input-placeholder {
    color: red !important;
    line-height: 2!important;
    line-height: 1.2em;
    padding: 5px;
    box-shadow: none;
    padding-top: calc((40px - 0.2em) /2);
    min-height: calc(1.5em + .25rem + -30px);
  } */
}

.chatbot-section .input-section .form-control::placeholder {
  color: #ea0000;
  font-weight: 300;
  font-style: italic;
  line-height: normal;
pointer-events: all;
}
.chatbot-section .input-section .form-control,
.chatbot-section .input-section .form-control:focus {
    box-shadow: none;
    padding: 8px 5px;
    padding-right: 40px;
    border-radius: 5px !important;
    font-weight: 400;
    font-size: 13px;
    color: #212529;
    border: 1px solid #e0e0e0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    background: transparent;
    height: 35px;
    font-weight: 400;
    line-height: normal !important;
    vertical-align: middle;
    overflow: hidden;
}
/* .chatbot-section textarea::placeholder{
  height: 100%;
} */
/* .chatbot-section .input-section .form-control::placeholder{
    line-height: initial !important;
} */
.chat-bot-page.time-picker-modal button.btn.btn-primary {
  font-family: system-ui;
}
.inner-content {
  position: relative;
}
p.type-msg {
  font-size: 13px;
  font-style: italic;
  color: red;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  pointer-events: none;
}
/* .grow-wrap {
  display: grid;
  word-break: break-word;
}
.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  border: 1px solid black;
  padding: 0;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
} */
.clear-height{
    min-height: 35px !important;
}
/* .chat-bot-page textarea::placeholder {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(0%, -50%);
} */
.chat-bot-page textarea {
  resize: none;
}

.chat-bot-page textarea {
  -webkit-user-select: text !important;
  user-select: text !important;
  -webkit-touch-callout: none !important;
  -webkit-user-drag: none !important;
}
.error-chattext {
  color: red;
  white-space: pre-wrap !important;
  font-weight: 500 !important;
}
.differ-para {
  padding-bottom: 15px;
}