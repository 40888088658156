.industry-type .pending-water-test {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 13px 10px;
    border-radius: 5px;
    background: white;
    margin-bottom: 15px;
}

.industry-type .pending-water-test .open-date.client-notes {
    min-width: auto;
}

.industry-type .basic {
    margin-bottom: -5px;
}
.industry-type .client-main-contact{
    background-color: transparent;
    padding: 0;
}
.industry-type .pending-water-test h3{
    font-size: 15px;
}

@media screen and (min-width: 991px) {
    .industry-type .pending-water-test h3 {
        font-size: 20px;
    }
    .industry-type .pending-water-test {
        padding: 20px 20px;
        margin-bottom: 20px;
        width: 49%;
    }
    .industry-type  .view-btn {
        display: flex;
        justify-content: center;
    }
    .industry-type .contact-container.client-main-contact {
        padding: 0;
    }
    .industry-type  .contact-container.client-main-contact{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}