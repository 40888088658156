.staff-table.client-table {
  margin-bottom: 20px;
}
.staff-table.client-table .mob-table {
  display: none;
}
.client-search-box {
  position: relative;
  margin-bottom: 30px;
}
.client-search-box form.form-inline {
  /* margin-bottom: 20px; */
}
.client-search-box .client-search.form-group {
  display: flex;
  align-items: center;
  width: 100%;
  flex-flow: column;
  margin-bottom: 0;
  position: relative;
}
.client-search-box .client-search.form-group .form-control {
  width: 100%;
  position: relative;
  padding: 12px 90px 12px 14px;
  height: 100%;
  font-size: 16px;
  background: transparent;
  color: #000;
  font-weight: 500;
}
.client-search-box .client-search.form-group .form-control::placeholder {
  color: #000;
}
.client-search-box .client-search.form-group .search-btn.btn-primary {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  min-width: auto;
  background: #0070c0;
  border-color: #0070c0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.download-btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.download-btn .btn-dwnld.btn-primary {
  background-color: #0048ba !important;
  border-color: #0048ba !important;
  border-radius: 0;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  width: 49%;
}
/* for pagination */

.history-pagination {
  text-align: center;
}

.history-pagination .pagination {
  justify-content: center;
  padding: 20px 0;
}

.history-pagination .page-link {
  color: #000;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  width: 40px;
  height: 40px;
  line-height: 1.2;
  margin: 0px 5px;
  font-size: 18px;
  border-radius: 100%;
}

.history-pagination .page-item .page-link {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0px 5px;
  border-radius: 100%;
  box-shadow: none;
  /* font-size: 30px; */
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  font-size: 30px;
  line-height: 30px !important;
}
/* .page-item.active .page-link {
  font-size: 16px;
  line-height: 40px;
} */
.history-pagination .page-item:first-child .page-link {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.history-pagination .page-item:last-child .page-link {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.history-pagination .page-link:hover,
.history-pagination .page-link.active {
  color: #fff;
  background-color: #0070c0;
  border: 1px solid #0070c0;
}

.pagination-chk {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  cursor: pointer;
  flex-wrap: wrap;
}
.pagination-chk a {
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
}
.pagination-chk a {
  padding: 5px 10px;
  border-radius: 5px;
  /* border: 1px solid #373373;
  color: #373373; */
  border: 1px solid #0048ba;
  color: #0048ba;
  outline: none;
  font-size: 16px;
}
.pagination-chk li {
  margin: 0 5px;
  margin-bottom: 10px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  /* background: #373373; */
  background: #0048ba;
  outline: none;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border-color: rgb(228 228 228);
  outline: none;
}
.user-pagination {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}
a.pagination__link {
  width: 110px;
  display: inline-block;
  text-align: center;
  height: auto;
}
.client-container .client-search img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 15px;
  opacity: 0.5;
  margin-top: 2px;
}
.client-container .card {
  margin-bottom: 25px;
  border: none;
}
.client-container .mob-accordion .card-header {
  padding: 0px;
}
.client-container .staff-list .staff-list-item {
  width: 100%;
}

.edit-working-popup .view-btn {
  margin-bottom: 0px;
  margin-top: 30px;
}
.edit-working-popup .shift-container {
  background: #fff;
  border-radius: 5px;
  padding: 15px !important;
}
.edit-working-popup .table-dropdown.datetime-table {
  margin-bottom: 15px !important;
  margin-top: 0px;
}

.client-container .new-manage-client .heading-accordion button.btn.btn-link {
  padding: 0px 0px !important;
}
.client-heading-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px 0;
}
.client-container .new-client-section .client-heading-option h5 {
  color: #202021;
  font-weight: 600;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.client-container .new-client-section .client-heading-option h5 i {
  padding-right: 6px;
  color: #808183;
  font-size: 22px;
  line-height: 20px;
}

.client-container .mob-accordion.inner-item.accordion .card {
  padding-left: 18px;
}
.staff-box .action-btn .btn img,
.grid-box .action-btn .btn img {
  width: 16px;
  margin-top: -5px;
}
.client-container .client-heading-option {
  padding: 14px 12px;
}
.client-container .client-heading-option button.btn.btn-link {
  padding: 0px 0px !important;
}
.client-container .mob-accordion.inner-item.accordion button {
  padding: 0px !important;
}
.client-container .mob-accordion.inner-item.accordion .client-heading-option {
  padding-right: 0px;
}

.client-container .mob-accordion.nested-accordian.accordion .card {
  /* padding-left: 27px !important; */
  padding-left: 27px !important;
}

.client-container .mob-accordion.nested-accordian.accordion .card {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.client-container .mob-accordion.nested-accordian.accordion .card-body {
  padding-bottom: 0px;
}
.client-container .mob-accordion.inner-item.accordion .card-body {
  padding-bottom: 0px;
  padding-right: 0px;
}

.client-container .mob-accordion.inner-item.accordion .card {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.client-container .mob-accordion.nested-accordian.accordion ul.staff-list {
  padding-left: 20px;
}
.client-heading-option.nested-listing {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.client-container
  .mob-accordion.inner-item.accordion
  .client-heading-option.nested-listing
  h5 {
  font-size: 13px !important;
}

.client-container
  .mob-accordion.inner-item.accordion
  .client-heading-option
  h5 {
  width: calc(50% + 35px);
  font-size: 16px;
}
.client-container
  .mob-accordion.inner-item.accordion
  .client-heading-option
  h6 {
  width: calc(50% + 16px);
  font-size: 19px;
  margin-bottom: 0px;
  color: #7c7c7c !important;
  font-weight: 400;
  padding-left: 0px;
}
.client-heading-option.nested-listing {
  padding: 10px 0px;
  padding-left: 0px;
}
.client-heading-option.nested-listing .card-body {
  padding-right: 0px;
}
.client-container .main-inner-heading h5 {
  width: 100% !important;
}
.client-container .mob-accordion.nested-accordian.nested-inner.accordion .card {
  padding-left: 0px !important;
}
.client-container .client-heading-option.nested-listing h6.add-householder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.client-container
  .client-heading-option.nested-listing
  h6.add-householder
  .right-hide-icon {
  width: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-option.service-edit-icon {
  /* width: 30px; */
  width: auto;
  text-align: right;
}
.client-container .client-option.service-edit-icon i {
  color: #0048ba;
  cursor: pointer;
}

.client-container
  .client-heading-option.nested-listing
  h6.add-householder
  .right-hide-icon
  i {
  color: #0048ba;
}
.client-container .client-heading-option .service-edit-icon .action {
  padding: 10px 10px;
  display: flex;
  justify-content: initial;
  align-items: baseline;
}
.mob-accordion.new-client-section.accordion .card {
  overflow: inherit;
}
.client-container .client-heading-option .service-edit-icon .action span img {
  margin-top: -3px;
}
.mob-accordion.new-client-section.accordion .card,
.mob-accordion.new-client-section.accordion .card-header {
  border-radius: 5px;
}
.client-container .staff-list .staff-box h3 {
  color: #202021;
  font-weight: 600;
}
.client-container
  .new-client-section
  .mob-accordion.inner-item.accordion
  .client-heading-option
  h5
  i {
  color: #cccdce;
}
.new-client-section ul.staff-list .staff-box {
  align-items: baseline;
}
.main-show.card-header {
  display: flex;
  justify-content: space-between;
}

.visibility-icon {
  display: flex;
  width: 40px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0px;
  margin-top: 16px;
  transform: translateY(6px);
}
.visibility-icon i {
  color: #0048ba;
}
.client-notes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  margin-bottom: 0px;
}

/* .client-notes h6 {
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 0px;
  font-style: italic;
  color: #716f6f;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.client-notes h6 img {
  height: 25px;
  width: 25px;
  background-color: #5bd125;
  border-radius: 50%;
  margin-left: 8px;
  padding: 2px;
} */
.client-notes label {
  font-size: 20px !important;
  font-weight: 700;
}
.client-notes .basic input:checked:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 11px;
  background: url(../../assets/home/check1.png);
  background-size: cover;
  background-color: #5bd125;
  border-radius: 50%;
  border: none !important;
}
.client-notes .basic.cross-check input:checked:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 10px;
  background: url(../../assets/home/uncheck.png);
  background-size: cover;
  background-color: #ffffff;
  border-radius: 50%;
  border: none !important;
}
.client-notes .basic input,
.client-notes .basic input:focus {
  outline: none;
  box-shadow: none;
}
.client-notes .basic input {
  height: 22px;
  width: 22px;
  border-radius: 50% !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: 1px solid #e0e0e0;
  position: relative !important;
  padding: 0px !important;
}
.client-notes .basic {
  display: flex;
  white-space: nowrap;
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 0px;
  font-style: italic;
  color: #716f6f;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.client-notes .basic .form-check {
  margin-left: 8px;
}

.client-heading-option.first-heading {
  padding: 20px 12px;
  /* padding-bottom: 15px; */
  align-items: center;
}
.client-option.service-edit-icon .dropdown-menu {
  min-width: 8rem;
}
.client-option.service-edit-icon .dropdown-menu .action {
  color: #b4b5b8;
  display: flex;
  cursor: pointer;
}
.client-option.service-edit-icon .action i {
  margin-right: 5px;
}
.client-notes .basic input:checked {
  border: none;
}

.new-client-section ul.staff-list .staff-box,
.new-client-section ul.grid-list .grid-box {
  padding: 8px 0;
}
.staff-modal .modal-body input {
  padding: 6px 10px !important;
}
.client-container .new-client-section .staff-list .staff-box h5 {
  padding-right: 0px;
  width: 50% !important;
  padding-left: 0px !important;
}
.client-container .new-client-section ul.staff-list .staff-box h3 {
  width: 50% !important;
}
.client-container .new-client-section .staff-box .action-btn {
  padding-left: 0px;
  width: 50%;
}
.staff-modal .form-group label {
  height: auto;
  margin-bottom: 8px;
  display: inline;
  line-height: initial;
  /* display:inline
line-hiegt:initial
height: auto; */
}

.new-client-section .service-edit-icon button img {
  margin-top: -5px !important;
}
/* .client-container  .mob-accordion.inner-item.accordion .client-heading-option {
  padding: 15px 12px;
} */

.search-text input::placeholder {
  color: red !important;
}
.client-container .service-edit-icon .action {
  padding: 10px 10px;
}
.client-search-box .search-box.search-text.form-group {
  margin-bottom: 0px !important;
}
.client-container .hidden-data {
  color: green;
  font-size: 14px;
  font-weight: 400;
}
.client-container .hidden-data img {
  margin-left: 15px;
}

.client-container .hidden-data {
  color: green;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.react-confirm-alert-overlay {
  z-index: 999999;
}
.client-option .action .btn-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0;
  color: #babbbd;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 0px !important;
}
.client-option .action .download-btn {
  max-width: 0px !important;
}

.client-container .occupier-acc .occupier-heading {
  width: calc(100% - 40px);
}

.client-container .occupier-acc ul.staff-list.occupier-list {
  width: calc(100% - 40px);
}

/* new client design */

.client-name-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px dashed #dedede;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.client-name-contact:last-child {
  border-bottom: none !important;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.client-main-contact .open-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 65px;
  width: auto;
}
.client-name-contact .open-date img {
  height: 28px;
  width: 28px;
  opacity: 0.4;
  transform: rotate(-90deg);
  /* pointer-events: none; */
  position: absolute;
  right: -6px;
  cursor: pointer;
}

.client-main-contact {
  background: #fff;
  border-radius: 5px;
  padding: 12px 12px;
  margin-bottom: 10px;
}
.client-name-contact .client-company-name {
  font-size: 18px;
  margin-bottom: 0px;
}

.client-name-contact .client-name {
  font-size: 16px;
  margin-bottom: 0px;
}

.client-name-contact p.client-address {
  font-size: 14px;
  color: #7c7c7c !important;
}

.site-addresses .client-name {
  font-size: 14px;
  margin-bottom: 0px;
  color: #7c7c7c !important;
  font-weight: 500;
}
.site-addresses p.client-address {
  font-size: 14px;
  color: #7c7c7c !important;
  margin-top: -5px;
  font-weight: 500;
}
.site-addresses .client-company-name {
  font-size: 16px;
  margin-bottom: 0px;
}
.new-client-section .mob-accordion.inner-item.accordion {
  border-bottom: 1px dashed #dedede !important;
  /* margin-bottom: 70px; */
  padding: 2px 0px;
}
.client-container .client-back-btn button {
  min-width: auto;
  color: #0048ba;
  padding: 0px;
}

.client-container .client-back-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.new-client-section .mob-accordion.inner-item.accordion:last-child {
  border-bottom: none !important;
}
.new-client-section .top-client-details {
  border-bottom: 1px dashed #dedede !important;
}
/* .occupier-contact{
  margin-top: -18px;
} */
.occupier-contact ul.staff-list {
  padding-left: 0px;
}

.client-container .site-with-address button {
  display: flex !important;
  align-items: baseline !important;
}

.client-heading-option.main-inner-heading.first-heading ul.staff-list {
  margin-top: -10px;
}
.occupier-contact {
  margin-top: -10px;
}
.associated-close {
  width: calc(50% - 0px);
  margin-left: auto;
  font-size: 12px;
  margin-top: -20px;
  padding-left: 8px;
}
.associated-close h6 {
  font-size: 13px;
  color: #7c7c7c !important;
  margin-bottom: 0px;
  font-weight: 400;
}
.associated-close.show-associated {
  display: none;
}
.client-container ul.staff-list.ass-contact {
  margin-top: -10px;
}
.new-client-section
  .mob-accordion.inner-item.accordion
  ul.staff-list
  .staff-box
  h3 {
  width: calc(50% - 12px) !important;
}
.mob-accordion.nested-accordian.occupier-acc.accordion
  ul.staff-list
  .staff-box
  h3 {
  width: calc(50% - 24px) !important;
}
.client-container
  .mob-accordion.inner-item.accordion
  .client-heading-option.nested-listing
  h5 {
  width: calc(50% - 0px) !important;
}
.mob-accordion.nested-accordian.site-acc.accordion ul.staff-list .staff-box h3 {
  width: calc(50% - 24px) !important;
}
.client-container .client-back-btn img {
  background-size: 25px;
  width: 25px;
  /* position: absolute; */
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.client-container .cover-client-page {
  margin-top: -30px;
}
.client-container .client-back-btn .staff-close {
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  z-index: 9;
}

.client-container .client-back-btn .staff-close i {
  position: relative;
  top: auto;
}
.client-main-contact .open-date .arrow-img {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
}
.cover-client-page .action-btn button {
  margin-right: 8px !important;
}
.client-option.service-edit-icon.hide-img i {
  display: none;
}

.client-option.service-edit-icon.show-associated img {
  display: none;
}

.client-option .upload input {
  height: 0px;
  width: 0px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.client-option .upload label {
  margin: 0 !important;
  padding: 0px !important;
  line-height: 0px !important;
  height: initial;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0;
  color: #babbbd;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 0px !important;
}

.client-option .upload .form-group {
  margin: 0px !important;
  line-height: 0px !important;
}

.client-option .action.upload {
  display: flex;
  align-items: center;
}
.number-box .dropdown span {
  font-size: 12px;
  color: #333 !important;
  margin-right: 8px;
  font-weight: 400;
}

.client-container .client-search-box {
  float: none !important;
}

@media (min-width: 768px) {
  /* Number Box */
  .addClient-modal .modal-body .form-group .number-box {
    width: 170px;
  }
  .addClient-modal .modal-body .form-group .number-box .dropdown {
    width: 100%;
  }
  .addClient-modal .modal-body .form-group .number-box .flag-icon {
    width: 24px;
    height: 24px;
  }
  .addClient-modal .modal-body .form-group .number-box span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .addClient-modal .modal-body .form-group .number-box span:last-child {
    margin-right: 0;
  }
  .addClient-modal .modal-body .form-group .number-field input {
    max-width: calc(100% - 170px);
  }
}

@media (min-width: 991px) {
  /* Add Client Popup */
  .addClient-modal .modal-dialog {
    margin: 1.75rem auto;
  }
  .addClient-modal .modal-content {
    max-height: calc(100vh - 3.5em);
    overflow-y: hidden;
    padding: 20px !important;
  }
  .addClient-modal .modal-content .modal-body {
    max-height: calc(100% - 116px);
    overflow-y: auto;
    padding-top: 0;
  }
  .addClient-modal .modal-content .modal-body::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }
  .addClient-modal .modal-content .modal-body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #e0e0e0;
  }
  .addClient-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #7b7c7e;
  }
  .addClient-modal .modal-content .staff-close {
    padding: 0;
    height: 25px;
  }
  .addClient-modal .modal-content .staff-close i {
    top: 0;
    right: 0;
  }
  .addClient-modal .modal-body .form-group {
    margin-bottom: 20px;
  }
  /* .addClient-modal .modal-body .form-radio.form-group {
  height: 100%;
} */
  .addClient-modal .modal-body .form-group label {
    color: #3c424b;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    margin-bottom: 10px;
  }
  .addClient-modal .modal-body .form-group .form-control {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  .addClient-modal .modal-body .view-btn {
    margin-top: 0;
  }
  .client-notes .basic input {
    height: 26px;
    width: 26px;
    cursor: pointer;
  }
  .client-notes .basic input:checked:after {
    padding: 13px;
  }
}
.no-of-page {
  font-size: 14px;
  font-weight: 500;
  color: #606060;
}

span.outstanding-amt {
  margin-left: auto;
  font-size: 12px;
  margin-right: 5px;
  color: green;
}
.due-amt{
  color: red;
}
.mob-accordion.new-client-section.accordion button.btn.btn-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mob-accordion.new-client-section.accordion button.btn.btn-link {
  display: flex;
  justify-content: space-between;
  padding-right: 20px!important;
}
