.payment-popup input,
.payment-popup .StripeElement {
  padding: 10px 14px;
  border-radius: 5px;
  background: white;
  height: 35px;
  border: 1px solid #ced4da;
  padding: 10px 10px!important;
}
.payment-popup .StripeElement .__PrivateStripeElement{
    margin-top: 5px !important;
}
.payment-popup .service-submit{
    display: flex;
    justify-content: flex-end !important;
}