.as-form select.form-control {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #333;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
}
.select-box {
  position: relative;
}
.as-form .select-box > i {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  background: rgb(0, 0, 0, 0.1);
  border: 1px solid rgb(206 212 218);
  height: 100%;
  width: 30px;
  font-size: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.as-form label.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding-left: 18px;
}
.appointment-container .table-bordered tr td:nth-child(2),
.appointment-container .table-bordered tr th:nth-child(2) {
  text-align: center;
}
.appointment-container .heading-table h5 {
  background: #0070c0;
}
.appointment-container .heading-table {
  border: 1px solid #0070c0 !important;
}
.appointment-container .heading-table thead th {
  border-bottom: 1px solid #0070c0 !important;
}
.appointment-container .view-btn {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.view-btn .back-btn span {
  padding-right: 6px;
}
h6.tag-line {
  text-align: center;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 10px;
}
.appointment-container .heading-table .table-bordered tbody td:first-child,
.appointment-container .heading-table .table-bordered thead th:first-child {
  text-align: left;
}


.edit-appt .appointment-container .add-appointment .search-container {
  padding: 0px !important;
}

.edit-appt .appointment-view {
  padding: 0;
}