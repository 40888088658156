.staff-table.client-table {
    margin-bottom: 20px;
  }
  .staff-table.client-table .mob-table {
    display: none;
  }
  .client-search-box {
    position: relative;
    margin-bottom: 30px;
  }
  .client-search-box form.form-inline {
    /* margin-bottom: 20px; */
  }
  .client-search-box .client-search.form-group {
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: column;
    margin-bottom: 0;
    position: relative;
  }
  .client-search-box .client-search.form-group .form-control {
    width: 100%;
    position: relative;
    padding: 12px 90px 12px 14px;
    height: 100%;
    font-size: 16px;
    background: transparent;
    color: #000;
    font-weight: 500;
  }
  .client-search-box .client-search.form-group .form-control::placeholder {
    color: #000;
  }
  .client-search-box .client-search.form-group .search-btn.btn-primary {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    min-width: auto;
    background: #0070c0;
    border-color: #0070c0;
    color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .download-btn {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .download-btn .btn-dwnld.btn-primary {
    background-color: #0048ba !important;
    border-color: #0048ba !important;
    border-radius: 0;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    width: 49%;
  }
  /* for pagination */
  
  .history-pagination {
    text-align: center;
  }
  
  .history-pagination .pagination {
    justify-content: center;
    padding: 20px 0;
  }
  
  .history-pagination .page-link {
    color: #000;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    width: 40px;
    height: 40px;
    line-height: 1.2;
    margin: 0px 5px;
    font-size: 18px;
    border-radius: 100%;
  }
  
  .history-pagination .page-item .page-link {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    margin: 0px 5px;
    border-radius: 100%;
    box-shadow: none;
    /* font-size: 30px; */
  }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    font-size: 30px;
    line-height: 30px !important;
  }
  /* .page-item.active .page-link {
    font-size: 16px;
    line-height: 40px;
  } */
  .history-pagination .page-item:first-child .page-link {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }
  
  .history-pagination .page-item:last-child .page-link {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }
  
  .history-pagination .page-link:hover,
  .history-pagination .page-link.active {
    color: #fff;
    background-color: #0070c0;
    border: 1px solid #0070c0;
  }
  
  .pagination-chk {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    cursor: pointer;
    flex-wrap: wrap;
  }
  .pagination-chk a {
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .pagination-chk a {
    padding: 5px 10px;
    border-radius: 5px;
    /* border: 1px solid #373373;
    color: #373373; */
    border: 1px solid #0048ba;
    color: #0048ba;
    outline: none;
    font-size: 16px;
  }
  .pagination-chk li {
    margin: 0 5px;
    margin-bottom: 10px;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff !important;
    /* background: #373373; */
    background: #0048ba;
    outline: none;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border-color: rgb(228 228 228);
    outline: none;
  }
  .user-pagination {
    display: flex;
    justify-content: center;
    /* margin-top: 20px; */
    padding-top: 20px;
  }
  a.pagination__link {
    width: 110px;
    display: inline-block;
    text-align: center;
    height: auto;
  }
  .client-container .client-search img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    height: 15px;
    opacity: 0.5;
    margin-top: 2px;
  }
  .client-container .card {
    margin-bottom: 25px;
    border: none;
  }
  .client-container .mob-accordion .card-header {
    padding: 0px;
  }
  .client-container .staff-list .staff-list-item {
    width: 100%;
  }
  
  .edit-working-popup .view-btn {
    margin-bottom: 0px;
    margin-top: 30px;
  }
  .edit-working-popup .shift-container {
    background: #fff;
    border-radius: 5px;
    padding: 15px  !important;
  }
  .edit-working-popup .table-dropdown.datetime-table {
    margin-bottom: 15px  !important;
    margin-top: 0px;
  }
  
  
  
  .client-container .new-manage-client .heading-accordion button.btn.btn-link {
    padding: 0px 0px !important;
  }
  .client-heading-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 8px 0;
  }
  .client-container .new-client-section .client-heading-option h5 {
    color: #202021;
    font-weight: 600;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
  
  .client-container .new-client-section .client-heading-option h5 i {
    padding-right: 6px;
    color: #808183;
    font-size: 22px;
     line-height: 20px;
  }
  
  .client-container .mob-accordion.inner-item.accordion .card {
    padding-left: 18px;
  }
  .staff-box .action-btn .btn img, .grid-box .action-btn .btn img{
    width: 16px;
    margin-top: -5px;
  } 
  .client-container .client-heading-option {
    padding: 14px 12px;
  }
  .client-container .client-heading-option button.btn.btn-link {
    padding: 0px 0px !important;
  }
  .client-container .mob-accordion.inner-item.accordion button {
    padding: 0px !important;
  }
  .client-container .mob-accordion.inner-item.accordion .client-heading-option {
    padding-right: 0px;
  }
  
  .client-container .mob-accordion.nested-accordian.accordion .card {
    /* padding-left: 27px !important; */
    padding-left: 27px !important;
  }
  
  .client-container .mob-accordion.nested-accordian.accordion .card {
      padding-bottom: 0px;
      margin-bottom: 0px;
  }
  
  .client-container .mob-accordion.nested-accordian.accordion .card-body {
      padding-bottom: 0px;
  }
  .client-container .mob-accordion.inner-item.accordion .card-body {
      padding-bottom: 0px;
      padding-right: 0px;
  }
  
  
  .client-container .mob-accordion.inner-item.accordion .card {
      padding-bottom: 0px;
      margin-bottom: 0px;
  }
  .client-container .mob-accordion.nested-accordian.accordion ul.staff-list {
    padding-left: 20px;
  }
  .client-heading-option.nested-listing {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  
  
  .client-container .mob-accordion.inner-item.accordion .client-heading-option.nested-listing h5 {
    font-size: 13px !important;
  }
  
  .client-container .mob-accordion.inner-item.accordion .client-heading-option h5 {
    width: calc(50% + 35px);
    font-size: 13px;
  }
  .client-container .mob-accordion.inner-item.accordion .client-heading-option h6 {
    width: calc(50% + 16px);
    font-size: 19px;
    margin-bottom: 0px;
    color: #7c7c7c !important;
  font-weight: 400;
  padding-left: 0px;
  }
  .client-heading-option.nested-listing {
    padding: 10px 0px;
    padding-left: 0px;
  }
  .client-heading-option.nested-listing .card-body{
    padding-right: 0px;
  }
  .client-container .main-inner-heading h5 {
    width: 100% !important;
  }
  .client-container .mob-accordion.nested-accordian.nested-inner.accordion .card {
    padding-left: 0px !important;
  }
  .client-container .client-heading-option.nested-listing h6.add-householder {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .client-container .client-heading-option.nested-listing h6.add-householder .right-hide-icon {
    width: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .client-option.service-edit-icon {
    /* width: 30px; */
    width: auto;
    text-align: right;
  }
  .client-container .client-option.service-edit-icon i {
    color: #0048ba;
  }
  
  .client-container .client-heading-option.nested-listing h6.add-householder .right-hide-icon i {
    color: #0048ba;
  }
  .client-container .client-heading-option .service-edit-icon .action {
    padding: 10px 10px;
    display: flex;
    justify-content: initial;
    align-items: baseline;
  }
  .mob-accordion.new-client-section.accordion .card {
    overflow: inherit;
  }
  .client-container .client-heading-option .service-edit-icon .action span img {
    margin-top: -3px;
  }
  .mob-accordion.new-client-section.accordion .card, .mob-accordion.new-client-section.accordion .card-header {
    border-radius: 5px;
  }
  .client-container  .staff-list .staff-box h3{
    color: #202021;
    font-weight: 600;
  }
  .client-container .new-client-section .mob-accordion.inner-item.accordion .client-heading-option h5 i {
    color:#cccdce;
  }
  .new-client-section ul.staff-list .staff-box {
    align-items: baseline;
  }
  .main-show.card-header {
    display: flex;
    justify-content: space-between;
  }
  
  .visibility-icon {
    display: flex;
    width: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0px;
    margin-top: 16px;
    transform: translateY(6px);
  }
  .visibility-icon i{
    color: #0048ba;
  }
  .client-notes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    margin-bottom: 0px;
  }
  
  /* .client-notes h6 {
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 0px;
    font-style: italic;
    color: #716f6f;
    display: flex;
    align-items: center;
    font-weight: 400;
  }
  .client-notes h6 img {
    height: 25px;
    width: 25px;
    background-color: #5bd125;
    border-radius: 50%;
    margin-left: 8px;
    padding: 2px;
  } */
  .client-notes label {
    font-size: 20px !important;
    font-weight: 700;
  }
  .client-notes  .basic input:checked:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 11px;
    background: url(../../assets/home/check1.png);
    background-size: cover;
    background-color: #5bd125;
    border-radius: 50%;
    border: none!important;
  }
  .client-notes  .basic.cross-check input:checked:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 10px;
    background: url(../../assets/home/uncheck.png);
    background-size: cover;
    background-color: #ffffff;
    border-radius: 50%;
    border: none!important;
  }
  .client-notes .basic input, .client-notes .basic input:focus {
    outline: none;
    box-shadow: none;
  }
  .client-notes .basic input {
    height: 22px;
    width: 22px;
    border-radius: 50%!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
    border: 1px solid #e0e0e0;
    position: relative!important;
    padding: 0px !important;
  }
  .client-notes  .basic {
    display: flex;
    white-space: nowrap;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 0px;
    font-style: italic;
    color: #716f6f;
    display: flex;
    align-items: center;
    font-weight: 400;
  }
  .client-notes  .basic .form-check{
    margin-left: 8px;
  
  }
  
  .client-heading-option.first-heading {
    padding: 20px 12px;
    /* padding-bottom: 15px; */
  }
  .client-option.service-edit-icon .dropdown-menu{
    min-width: 8rem;
  }
  .client-option.service-edit-icon .dropdown-menu .action {
    color: #b4b5b8;
    display: flex;
  }
  .client-option.service-edit-icon .action i {
    margin-right: 5px;
  }
  .client-notes .basic input:checked {
    border: none;
  }
  
  .new-client-section ul.staff-list .staff-box, .new-client-section ul.grid-list .grid-box {
    padding: 8px 0;
  }
  .staff-modal .modal-body input {
    padding: 6px 10px!important;
  }
  .client-container .new-client-section .staff-list .staff-box h5{
    padding-right: 0px;
    width: 50% !important;
    padding-left: 0px !important;
  }
  .client-container .new-client-section ul.staff-list .staff-box h3{
    width: 50% !important;
  }
  .client-container .new-client-section .staff-box .action-btn {
    padding-left: 0px;
    width: 50%;
  }
  .staff-modal .form-group label{
    height: auto;
    margin-bottom: 8px;
    display: inline;
    line-height: initial;
    /* display:inline
  line-hiegt:initial
  height: auto; */
  }
  
  .new-client-section .service-edit-icon button img{
    margin-top: -5px;
  }
  /* .client-container  .mob-accordion.inner-item.accordion .client-heading-option {
    padding: 15px 12px;
  } */
  
  .search-text input::placeholder {
    color: red !important;
  }
  .client-container .service-edit-icon .action {
    padding: 10px 10px;
  }
  .client-search-box .search-box.search-text.form-group {
    margin-bottom: 0px !important;
  }
  .client-container .hidden-data{
    color: green;
    font-size: 14px;
    font-weight: 400;
  }
  .client-container .hidden-data img {
    margin-left: 15px;
  }
  
  .client-container .hidden-data {
    color: green;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .react-confirm-alert-overlay{
    z-index: 999999;
  }
  .client-option .action  .btn-primary {
    background-color: transparent  !important;
    border-color: transparent !important;
    border-radius: 0;
    color: #babbbd;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    padding: 0px !important;
  }
  .client-option .action .download-btn{
    max-width: 0px !important;
  }
  
  .client-container .occupier-acc .occupier-heading {
    width: calc(100% - 40px);
  }
  
  .client-container .occupier-acc ul.staff-list.occupier-list{
    width: calc(100% - 40px);
  }
  
  
  
  /* new client design */
  
  .client-name-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px dashed #dedede;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .client-name-contact:last-child{
    border-bottom: none !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .client-main-contact .open-date {
    display: flex;
    align-items: center;
    justify-content:space-between;
    min-width: 65px;
    width: auto;
  }
  .client-name-contact .open-date img {
    height: 28px;
    width: 28px;
    opacity: .4;
    transform: rotate(
  -90deg
  );
    /* pointer-events: none; */
    position: absolute;
    right: -6px;
  }
  
  .client-main-contact {
    background: #fff;
    border-radius: 5px;
    padding: 12px 12px;
    margin-bottom: 10px;
  }
  .client-name-contact .client-company-name {
    font-size: 18px;
    margin-bottom: 0px;
  }
  
  .client-name-contact .client-name {
    font-size: 16px;
    margin-bottom: 0px;
  }
  
  .client-name-contact p.client-address {
    font-size: 14px;
    color: #7c7c7c !important;
  }
  
  .site-addresses .client-name {
    font-size: 14px;
    margin-bottom: 0px;
    color: #7c7c7c !important;
    font-weight: 500;
  }
  .site-addresses p.client-address {
    font-size: 14px;
    color: #7c7c7c !important;
    margin-top: -5px;
    font-weight: 500;
  }
  .site-addresses .client-company-name {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .new-client-section .mob-accordion.inner-item.accordion {
    border-bottom: 1px dashed #dedede !important;
    /* margin-bottom: 70px; */
    padding: 2px 0px;
  }
  .client-container .client-back-btn button {
    min-width: auto;
    color: #0048ba;
    padding: 0px;
  }
  
  .client-container .client-back-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  .new-client-section .mob-accordion.inner-item.accordion:last-child {
    border-bottom: none !important;
  }
  .new-client-section .top-client-details {
    border-bottom: 1px dashed #dedede !important;
  }
  /* .occupier-contact{
    margin-top: -18px;
  } */
  .occupier-contact ul.staff-list {
    padding-left: 0px;
  }
  
  .client-container .site-with-address button{
    display: flex !important;
    align-items: baseline !important;
  }
  
  .client-heading-option.main-inner-heading.first-heading ul.staff-list {
    margin-top: -10px;
  }
  .occupier-contact{
    margin-top: -10px;
  }
  .associated-close {
    width: calc(50% - 0px);
    margin-left: auto;
    font-size: 12px;
    margin-top: -20px;
    padding-left: 8px;
  }
  .associated-close h6{
    font-size: 13px;
    color: #7c7c7c !important;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .associated-close.show-associated {
    display: none;
  }
  .client-container ul.staff-list.ass-contact {
    margin-top: -10px;
  }
  .new-client-section .mob-accordion.inner-item.accordion ul.staff-list .staff-box h3 {
    width: calc(50% - 12px) !important;
  }
  .mob-accordion.nested-accordian.occupier-acc.accordion ul.staff-list .staff-box h3 {
    width: calc(50% - 24px) !important;
  }
  .client-container .mob-accordion.inner-item.accordion .client-heading-option.nested-listing h5 {
    width: calc(50% - 0px) !important;
  }
  .mob-accordion.nested-accordian.site-acc.accordion ul.staff-list .staff-box h3{
    width: calc(50% - 24px) !important;
  }
  .client-container .client-back-btn img {
    background-size: 25px;
    width: 25px;
    /* position: absolute; */
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .client-container.subcription-page .cover-client-page {
    margin-top: -55px;
  }
  .client-container .client-back-btn .staff-close {
    padding: 0px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    z-index: 9;
  }
  
  .client-container .client-back-btn .staff-close i {
    position: relative;
    top: auto;
  }
  .client-main-contact .open-date .arrow-img {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
  }
  .cover-client-page .action-btn button {
    margin-right: 8px !important;
  }
  .client-option.service-edit-icon.hide-img i {
    display: none;
  }
  
  .client-option.service-edit-icon.show-associated img {
    display: none;
  }




  .reports-page .card {
    background-color: #ffff;
    margin-bottom: 15px;
    border-radius: 5px !important;
    border: none;
    /* box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%); */
    padding: 15px 12px;
  }
  
  .reports-page .reports-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    font-family: "Exo 2" !important;
  }
  
  .reports-page .reports-heading img {
    width: 32px;
    object-fit: cover;
    transform: rotate(-90deg);
    filter: opacity(0.5);
  }
  
  .appointment-summary .custom-datepicker::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background: url("../../assets//home/newcal.png");
    background-repeat: no-repeat;
    right: 5px;
    background-size: contain;
  }
  
  .appointment-summary .custom-datepicker {
    padding: 0px 12px 0px 12px;
    color: black !important;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    font-weight: 400;
    background-color: white;
    border: 1px solid #e0e0e0;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #a5a5a5 !important;
  }
  .appointment-summary .start-date-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .appointment-summary .start-date-section .form-group {
    margin-bottom: 0px;
    width: 45%;
  }
  .appointment-summary {
    background: #fff;
    border-radius: 5px;
    padding: 12px 12px;
  }
  .appointment-summary-section p {
    line-height: initial;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
    color: #0659dc;
  }
  .appointment-summary-section {
    margin: 15px 0px;
  }
  .reports-page .form-group label.form-label {
    color: #333;
    font-size: 13px;
    font-weight: 600;
  }
  .reports-page .summary-table-header,
  .reports-page .summary-table-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .reports-page .summary-table-header h6 {
    font-size: 12px;
    width: 22%;
    padding: 0px 5px;
    color: black;
    font-weight: 700;
  }
  .reports-page .summary-table-header h6:nth-child(2) {
    width: 15% !important;
    text-align: right;
  }
  .reports-page .summary-table-header h6:first-child,
  .reports-page .summary-table-body p:first-child {
    width: 25% !important;
  }
  .reports-page .summary-table-body p {
    width: 22%;
    text-align: left;
    font-weight: 500;
    color: #757575;
    padding: 0px 5px;
  }
  .reports-page .summary-table-body p:nth-child(2) {
    width: 15% !important;
    /* text-align: right; */
  }
  .reports-page .summary-table-body {
    margin-bottom: 8px;
  }
  .summary-table-body.total-section p {
    line-height: initial;
    font-weight: 700;
    margin-bottom: 0px;
    text-align: center;
    color: #0659dc;
  }
  .summary-table hr {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .reports-page .summary-table-body.total-section p:first-child {
    width: 30% !important;
    text-align: left;
  }
  .reports-page .summary-table-body.total-section p:nth-child(2) {
    width: 10% !important;
  }
  .staff-container .select-box select.form-control:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #e0e0e0;
  }
  .startend-popup h6.end-date {
    margin-top: 20px;
  }
  
  .startend-popup h6 {
    color: #1414ff;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .startend-popup .view-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
  }
  .reports-appointment .target-heading.client-profile {
    margin-top: 0px;
  }
  .reports-page .details-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .reports-page .details-body h6 {
    width: 50%;
    font-size: 12px;
    color: black;
    margin-bottom: 0px;
    font-weight: 700;
  }
  .reports-page .details-body p {
    width: 50%;
    text-align: left;
    font-weight: 500;
    color: #757575;
  }
  .reports-page .details-body {
    margin-bottom: 8px;
  }
  .details-body.period-total h6 {
      color: #0659dc;
  }
  .ageing-datepicker{
  position: relative;
  }
  .ageing-page .summary-table .summary-table-header h6:nth-child(2),
  .ageing-page .summary-table .summary-table-body p:nth-child(2)
  {
    text-align: left !important;
    width: 20% !important;
  }
  .ageing-page .summary-table .summary-table-header h6
  {
  padding-right: 10px;
  
  }
  .ageing-page .summary-table .summary-table-header h6,
  .ageing-page .summary-table .summary-table-body p
  {
    text-align: left !important;
    width: 20% !important;
  }
  
  .ageing-page .summary-table .summary-table-body p:nth-child(1),
  .ageing-page .summary-table .summary-table-header h6:nth-child(1)
  {
    text-align: left !important;
    width: 30% !important;
  }
  .ageing-page .summary-table-body.total-section p:first-child{
    width: 30% !important;
  }
  .ageing-page .summary-table .summary-table-body p:nth-child(1){
    padding-right: 15px;
  }
  .period-total-other h6{
  color: #28b8f2 !important;
  font-style: italic;
  }
  .reports-page .search-box {
    position: relative;
  }
  .new-location p{
    text-align: left !important;
  }
  p.location-name {
    color: #0000ff !important ;
  }
  
  p.location-light {
    color: #87a7db !important;
  }
  .location-text p{
    text-align: left;
    color: #959f95;
    font-weight: 400;
  }
  /* .summary-table p{
  text-align: left;
  color: #a39898;
  font-weight: 500;
  font-size: 12px;
  } */
  .appointment-summary .new-location {
    margin-bottom: 20px;
  }
  .appointment-summary hr {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .reports-page .pageone-payment .summary-table-header h6:nth-child(2) {
    width: 25% !important;
    text-align: left;
  }
  .pageone-payment .summary-table-body p:last-child {
    text-align: right;
  }
  .pageone-payment .summary-table-header h6:last-child {
    text-align: right;
  }
  .pageone-payment 
  .location-text {
      padding-left: 5px;
  }
  
  .pageone-payment  .location-text p {
      font-size: 11px !important;
  }
  .pageone-payment .details-body.period-total h6 {
    color: #0000ff;
  }
  .new-location p {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .pagetwo-payment .summary-table-header h6:first-child{
    width: 25% !important;
  }
  .pagetwo-payment .summary-table-header h6{
    width: 17% !important;
  }
  .pagetwo-payment .summary-table-header h6:nth-child(4){
    width: 25% !important;
  }
  .pagetwo-payment .summary-table-body p:first-child{
    width: 25% !important;
  }
  .pagetwo-payment .summary-table-body p{
    width: 17% !important;
  }
  .pagetwo-payment .summary-table-body p:nth-child(4){
    width: 25% !important;
  }
  .reports-page .pagetwo-payment .summary-table-header h6:nth-child(2) {
    width: 17%  !important;
    text-align: left;
  } 
  
  .hyperLink-test u{
    text-decoration-color: #0645AD;
    color: #0645AD;
  }
  .reports-appointment .summary-table-body p:nth-child(2), .reports-appointment .summary-table-header h6:nth-child(2) {
    text-align: left !important;
  }
  
  .appointment-summary.pageone-payment .summary-table-body p:nth-child(2) {
    width: 25% !IMPORTANT;
  }
  .appointment-summary.pagetwo-payment .summary-table-body p:nth-child(2) {
    width: 17% !IMPORTANT;
  }
  .pagetwo-payment .summary-table .summary-table-body p{
    width: 17% ;
  }
  .subsciption-popup .form-group span {
    font-style: italic;
    font-size: 10px;
    line-height: 0;
    color: #a5a5a5;
}
.subsciption-popup .staff-form label.form-label {
  color: #333;
  text-transform: capitalize;
  padding: 0 !important;
  font-size: 13px;
  font-weight: 600;
}
.subsciption-popup .appointment-summary{
  padding: 0px ;
  margin-bottom: 8px;
}
/* .subsciption-popup .subsciption-period label.form-label {
  margin-bottom: -8px !important;
} */
.subsciption-popup .form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef59 !important;
  opacity: 1;
  color: #a5a5a5 !important;
}
.subsciption-popup .col-md-12.detailed-data label {
  display: inherit;
  margin-top: 5px;
}
.subcription-page .associated-close {
  margin-top: -40px;
}
 .client-container .client-search-box {
  float: none !important;
  padding: 15px 12px;
  background: white;
  border-radius: 5px;
}