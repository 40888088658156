.equipemnt-page .grid-box.grid-notes h3 {
    width: 100% !important;
}
.equipemnt-page .client-notes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    margin-bottom: -5px;
    margin-top: -5px;
}
.equipemnt-page .client-notes .basic .form-check {
    margin-left: 0;
}
.checklist-popup input#upload-image {
    visibility: hidden;
    display: none;
}

.checklist-popup .upload-img .form-group {
    width: 100%;
    margin-left: auto;
}
.equipment-details .form-group input::placeholder{
    color: red  !important;
    font-style: italic;
}