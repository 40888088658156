.heading h1 span {
  position: relative;
}
.heading h1 span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #f8d400;
  top: 100%;
  left: 0;
}
.onlinedemo-img {
  width: 100%;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  overflow: hidden;
}
.onlinedemo-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
