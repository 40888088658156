.product-service-container  .main-titles{
  padding: 0;
  margin: 10px 0px 60px 0px;
}
.web-header {
  display: block;
}
.web-header .web-header-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}
.web-header .navbar-collapse {
  padding-bottom: 0;
}
.web-header .navbar-light .navbar-nav .nav-link {
  color: white;
  font-weight: 300;
  font-size: 16px;
  padding: 0px;
  margin-left: 30px;
}
.web-header .web-header-layout img {
  height: 80px;
}
.web-header nav.navbar.navbar-expand-lg.navbar-light {
  padding: 8px 0px;
}
.web-header .dropdown-toggle::after {
  content: "";
  background: url("../../assets//header/down-arrow.png");
  height: 15px;
  width: 15px;
  background-size: cover;
  pointer-events: none;
}
.web-header .navbar-light .navbar-brand {
  padding: 0px !important;
}

.web-header .auth-login {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
}

.web-header .auth-login p {
    margin: 0px 15px 0px 0px;
    color: #00b0f0;
    font-weight: 400;
    font-size: 14px;
    line-height: initial;
}
.web-header .auth-login button, .web-header .auth-login button:hover, .web-header .auth-login button:focus,
.web-header .auth-login .btn-primary:not(:disabled):not(.disabled):active {
    background: #00b0f0 !important;
    font-size: 15px !important;
    border-radius: 10px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border: 1px solid #00b0f0;
    color: white;
    font-weight: 600 !important;
    height: 38px !important;
}
.web-header .dropdown-item {
    padding: 5px 20px 5px 10px;
    font-size: 14px;
}
.web-header .header-relative {
  position: relative;
}
@media screen and (max-width: 991px) {
  .web-header {
    display: none;
  }
}
