@media screen and (min-width: 991px) {
  /****************** Saved Invoices *************/
  .pool-set-up.invoices-list.invoice-detail.search-invoice.template-page .main-titles-mob {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
}

.pool-set-up.invoices-list.invoice-detail.search-invoice.template-page .main-titles-mob h3 {
    text-align: left;
}
.pool-set-up.invoices-list.invoice-detail.search-invoice.template-page   .main-titles-mob .content-dropdown{
  display: block;
}
  .main-titles-desktop {
    display: block;
  }
  .newQuote-page .quotes-client-edit {
    width: 100%;
}
  .invoices-list .main-titles-mob h3,
  .quotes-page .main-titles-mob h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
  }

  .invoices-list .main-titles-mob .option-list,
  .quotes-page .main-titles-mob .option-list {
    /* width: calc(12.5% - 16px); */
    width: 165px;
  }

  .invoices-list .main-titles-mob .content-dropdown,
  .quotes-page .main-titles-mob .content-dropdown {
    display: none;
  }

  /* Invoices List Table */

  .invoices-list .table-section {
    padding-right: 20px;
  }
  .invoices-list .table-section .invoices-head {
    padding: 20px 20px 10px;
  }

  .invoices-list .table-section .invoices-head h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
    text-align: center;
  }
  .invoices-list .table-section .invoices-head h6:first-child {
    text-align: left;
  }

  .invoices-list .table-section .invoice-body {
    padding: 10px 20px;
  }

  .invoices-list .table-section .invoice-body p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: lowercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .invoices-list .table-section .invoice-body p:first-child {
    align-items: flex-start;
  }

  .invoices-list .table-section .invoice-body h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
  .invoices-list .user-pagination {
    padding-top: 10px;
  }
  .invoices-list .invoices-table-right:hover {
    cursor: pointer;
  }

  /******************* Invoice Details  ***************************/
  .invoices-list .add-appointment {
    background-color: #fff;
  }
  .invoices-list .invoice-detail-header {
    padding: 20px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 8px;
  }
  .invoices-list .invoice-detail-header h6 {
    margin: 0;
  }
  .invoices-list .invoice-detail-body {
    padding: 8px 13px;
  }
  .invoices-list .new-invoice .appointment-view .appointment-date {
    padding: 0 0 10px !important;
  }
  .new-invoice .client-option.service-edit-icon .dropdown-menu .dropdown-item {
    padding: 0;
  }
  .invoices-list .new-invoice .appointment-view .appointment-date h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
    text-transform: uppercase;
  }
  .invoices-list .search-container {
    position: relative;
  }
  .invoices-list .owing-amount {
    position: absolute;
    top: 44px;
    right: 36px;
    width: fit-content;
  }
  .invoices-list .owing-amount p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
  }
  .invoices-list .search-title.service-head {
    padding: 0 0 8px;
  }
  .invoices-list .search-title .client-info h3 {
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
  }
  .invoices-list .search-title .client-info p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  .invoices-list .appointment-view .client-profile {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  .invoices-list .search-title .client-profile .client-info {
    width: calc(100% - 100px);
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .invoices-list .client-profile h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;
    color: #8d8d8d;
  }

  .invoices-list .client-profile p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    color: #8d8d8d;
  }
  .invoices-list .search-title {
    padding: 0 0 8px;
  }
  .invoices-list .search-title:last-child {
    padding: 0 0 10px;
  }
  .invoices-list .appointment-total {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  .invoices-list .appointment-total h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .invoices-list .appointment-total p.labour-margin {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .invoices-list .appointment-view .footer-btn button {
    max-width: 160px !important;
    max-height: 36px;
  }

  .invoices-list .client-left-details h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #8d8d8d;

    text-transform: uppercase;
  }
  .invoices-list .client-left-details p {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #8d8d8d;
    text-transform: uppercase;
  }

  /************ Awaiting Send Approval ****************/
  .awaiting-approvals .invoice-body .customer-name p {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .awaiting-approvals .invoice-body .customer-name p:nth-child(2) {
    color: #333333;
  }
  .awaiting-approvals .invoice-body .customer-name p:nth-child(3) {
    font-style: italic;
    color: #6d7287 !important;
    font-weight: 400;
  }
  .awaiting-approvals .invoice-body .customer-name p span {
    text-transform: lowercase;
  }
  .awaiting-approvals .invoice-body p.amt-due {
    width: 20%;
    color: #6d7287;
  }
  .awaiting-approvals .invoice-body p.check-paid.amt-due {
    color: #0dbf13;
  }
  .awaiting-approvals .invoice-body .status-text {
    color: #0dbf13;
    font-style: normal;
    font-weight: 600 !important;
    width: 20%;
    text-transform: capitalize !important;
    gap: 4px;
  }
  .awaiting-approvals .invoice-body .status-text span {
    color: #6d7287;
    font-weight: 400 !important;
    text-transform: lowercase !important;
  }
  .awaiting-approvals .invoices-table-right img {
    opacity: 1;
  }

  /**************** Pending Invoices ******************/
  .table-section.desktop-comp {
    display: flex !important;
  }
  .table-section.mobile-comp {
    display: none !important;
  }
  .pending-invoices .table-section.desktop-comp {
    padding-right: 0;
  }
  .pending-invoices .desktop-comp .invoices-table-left {
    width: 100%;
  }
  .pending-invoices .desktop-comp .invoices-head,
  .pending-invoices .desktop-comp .invoice-body {
    gap: 10px;
  }
  .pending-invoices .table-section.desktop-comp .invoices-head h6,
  .pending-invoices .desktop-comp .invoice-body .customer-name,
  .pending-invoices .desktop-comp .invoice-body p.amount-due,
  .pending-invoices .desktop-comp .invoice-body p.checkbox-section,
  .pending-invoices .desktop-comp .invoice-body p.check-paid {
    width: 160px !important;
  }
  .pending-invoices .desktop-comp .invoice-body p {
    align-items: center !important;
  }

  .pending-invoices .desktop-comp .invoice-body .customer-name:first-child,
  .pending-invoices .table-section.desktop-comp .invoices-head h6:first-child {
    width: calc(100% - 720px) !important;
  }
  .pending-invoices .desktop-comp .invoice-body .customer-name:first-child p {
    align-items: flex-start !important;
    font-style: normal;
    color: #0048ba !important;
  }
  .pending-invoices .desktop-comp .invoice-body .visit-date p {
    font-style: normal;
    font-weight: 400;
    color: #333333 !important;
  }
  .pending-invoices .desktop-comp .invoice-body .team-member p,
  .pending-invoices .desktop-comp .invoice-body p.check-paid,
  .pending-invoices .desktop-comp .invoice-body p.amount-due {
    color: #6d7287 !important;
    font-style: italic;
    font-weight: 400;
  }
  .pending-invoices .desktop-comp .invoice-body p.check-paid,
  .pending-invoices .desktop-comp .invoice-body p.amount-due {
    font-style: normal;
  }

  /******************* Receive Payments ***************/
  .receive-payments .desktop-comp .invoices-table-left {
    width: 100%;
  }
  .receive-payments .desktop-comp .invoices-head {
    width: calc(100% - 20px);
    padding: 20px;
  }
  .receive-payments .desktop-comp .invoice-body {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  .receive-payments .desktop-comp .invoice-body p,
  .receive-payments .desktop-comp .invoices-head h6 {
    width: calc(20% - 5px);
  }
  .receive-payments .desktop-comp .invoice-body p {
    color: #6d7287 !important;
    font-weight: 400;
  }
  .receive-payments .desktop-comp .invoice-body p:first-child {
    color: #0048ba !important;
    font-weight: 500;
  }
  .receive-payments .desktop-comp .invoice-body .invoices-table-right {
    width: 20px;
  }
  .receive-payments .desktop-comp .invoices-table-right img {
    opacity: 1;
  }

  /********************** Search Invoice ***************/
  .search-invoice .appointment-service-modal {
    padding: 21px 20px 10px;
  }
  .search-invoice .appointment-service-modal label {
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 !important;
  }
  .search-invoice .appointment-service-modal .select-box {
    padding: 21px 0 20px;
  }
  .search-invoice .appointment-service-modal .select-box select.form-control {
    border: 1px solid rgba(141, 141, 141, 0.6);
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 7.5px 20px;
  }
  .search-invoice .appointment-service-modal .search-invoces-box {
    padding: 20px;
  }
  .search-invoice .appointment-service-modal .search-invoces-box h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 0;
  }
  .search-invoice
    .appointment-service-modal
    .search-invoces-box
    .search-invoice-bg {
    margin: 20px 0;
  }
  .search-invoice .appointment-service-modal .search-box label {
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .search-invoice .appointment-service-modal .search-box.form-group {
    margin-bottom: 10px;
  }
  .search-invoice .appointment-service-modal p.group-time {
    margin: 0 0 20px;
  }
  .search-invoice
    .appointment-service-modal
    .search-invoces-box
    .view-btn.search-invoice-btn {
    margin: 0;
  }
  .search-invoice
    .appointment-service-modal
    .view-btn.search-invoice-btn
    button,
  .receive-invoice-detail
    .appointment-service-modal
    .view-btn.search-invoice-btn
    button {
    max-width: 160px !important;
    /* max-height: 36px; */
  }
  .search-invoice .appointment-service-modal .no-invoice {
    margin: 20px 0 0;
  }
  .search-invoice .appointment-service-modal .no-invoice p.table-section {
    margin: 0;
  }
  .search-invoice .target-heading .client-left-details p {
    color: #7d7d7d !important;
  }

  /********************** Receive Invoice Detail ***************/
  .receive-invoice-detail .pool-setup {
    padding: 21px 20px 20px;
    background: #fff;
  }
  .receive-invoice-detail .target-heading {
    padding: 0;
  }
  .receive-invoice-detail .target-heading h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #3c424b;
    margin-bottom: 0;
  }
  .receive-invoice-detail .pool-chemistry-box.appointment-service-modal {
    padding: 20px 0 0;
  }
  .receive-invoice-detail .target-heading.client-profile {
    margin: 0;
    font-style: normal;
  }
  .receive-invoice-detail .target-heading.client-profile h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }
  .receive-invoice-detail .target-heading.client-profile p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 4px 0 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .outstandings-invoices {
    padding: 0 0 10px;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .outstandings-invoices
    h6 {
    font-size: 20px;
    line-height: 27px;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box {
    padding: 20px 20px 0;
    margin: 20px 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .row {
    margin: 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .col-md-12 {
    padding-right: 0;
    padding-left: 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .search-box {
    margin-bottom: 20px;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .search-box
    .search-box {
    margin-bottom: 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .search-box
    .form-control {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #333333 !important;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .search-box
    .select-box {
    padding: 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .search-invoces-box
    .search-box
    label {
    margin-bottom: 0;
  }
  .receive-invoice-detail
    .pool-chemistry-box.appointment-service-modal
    .view-btn.search-invoice-btn {
    margin-top: 0;
  }
  .receive-invoice-detail .invoice-detail-section .payment-details h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
  }
  .receive-invoice-detail .search-invoces-box .multiple-invoice {
    margin-bottom: 10px;
  }
  .receive-invoice-detail .search-invoces-box.total-applied .search-box label {
    color: #fff !important;
  }

  /********************** New Invoice ***************/
  .new-invoice .add-appointment {
    background: transparent;
  }
  .new-invoice .newinvoice-row {
    gap: 20px;
  }

  .new-invoice .add-appointment .search-container {
    padding: 20px;
    margin: 0;
  }
  .new-invoice .search-container .search-title {
    padding: 0;
    margin: 0;
  }
  .new-invoice .search-container .search-title h3 {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #3c424b;
  }
  .new-invoice .add-appointment .appointment-view {
    padding: 20px;
  }
  .new-invoice .add-appointment .appointment-view .service-head {
    padding: 10px 0 20px;
    border-bottom: 1px solid #ececec;
  }
  .new-invoice .add-appointment .appointment-view .appointment-date h3,
  .new-invoice .add-appointment .appointment-view .service-head h3 {
    font-weight: 600;
    color: #3c424b;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
  }
  .new-invoice .add-appointment .appointment-view .content-dropdown img {
    opacity: 1;
    width: 20px;
    height: 20px;
  }
  .new-invoice .add-appointment .appointment-view .appointment-form {
    padding: 20px 0 10px;
  }
  .new-invoice .add-appointment .appointment-view .appointment-form label {
    padding: 0 0 20px;
    font-size: 16px;
    line-height: 21px;
    color: #3c424b;
  }
  .new-invoice .add-appointment .appointment-view .appointment-form textarea {
    padding: 10px;
    color: #a5a5a5;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    background: #ffffff;
    border: 1px solid rgba(141, 141, 141, 0.6);
    border-radius: 6px !important;
    min-height: 100px;
  }
  .new-invoice .add-appointment .appointment-view .appointment-total {
    padding-top: 10px;
    gap: 10px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
  .new-invoice .add-appointment .appointment-view .footer-btn.view-btn {
    margin-top: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  /************** Quotes Page *************/
  .quotes-page .appointment-view .form-group label {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #3c424b !important;
    padding: 0;
    margin-bottom: 20px;
    height: fit-content;
  }
  .quotes-page .search-title {
    margin: 0 !important;
    padding-bottom: 21px !important;
  }
  .quotes-page .search-title h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
  }
  .quotes-page .quotes-client-details p {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
  }
  .quotes-page .client-quotes-notes {
    padding: 10px;
    margin-bottom: 10px;
  }

  .service-edit-icon button img {
    width: 10px !important;
    margin: 0px !important;
  }

  /************** New Quotes Page *************/
  .newQuote-page .form-group {
    margin: 0;
    padding: 10px 0;
  }
  .newQuote-page.appointment-view .appointment-date {
    padding: 10px 0 !important;
  }
  .newQuote-page.appointment-view .search-title.service-head {
    padding: 10px 0 40px !important;
  }
  .newQuote-page.appointment-view .appointment-total {
    padding-top: 0 !important;
    margin: 0 0 10px !important;
  }
  .newQuote-page.appointment-view .appointment-total h6,
  .newQuote-page.appointment-view .appointment-total p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 21px !important;
    text-transform: uppercase;
    color: #3c424b !important;
    margin-bottom: 5px;
  }
  .newQuote-page.appointment-view .appointment-total h6 {
    font-weight: 600;
  }
  .newQuote-page .select-box select.form-control,
  .newQuote-page .select-box:after {
    z-index: 0;
  }
  .newQuote-page .footer-btn.view-btn {
    margin: 0 !important;
    padding: 40px 0 0;
  }

  /* Saved Invoices & Pending Invoices*/
  .quotes-page .search-container .client-profile img {
    width: 12px;
    height: auto;
  }
  .quotes-page .table-section {
    padding-right: 20px;
  }
  .quotes-page .table-section .invoices-head {
    padding: 20px 20px 10px;
  }

  .quotes-page .table-section .invoices-head h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #3c424b;
    text-align: center;
  }
  .quotes-page .table-section .invoices-head h6:first-child {
    text-align: left;
  }

  .quotes-page .table-section .invoice-body {
    padding: 10px 20px;
  }

  .quotes-page .table-section .invoice-body p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: lowercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .quotes-page .table-section .invoice-body p:first-child {
    align-items: flex-start;
  }

  .quotes-page .table-section .invoice-body h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
  .quotes-page p.quotes-status span {
    color: #a7a7a7;
  }
  .quotes-page .invoices-head h6,
  .quotes-page .invoice-body p {
    font-size: 11px;
    padding-right: 5px;
    width: 25%;
  }
  .quotes-page .invoices-head h6:first-child,
  .quotes-page .invoice-body p:first-child {
    width: 12%;
  }
  .quotes-page .invoices-head h6:nth-child(2),
  .quotes-page .invoice-body p:nth-child(2) {
    width: 15% !important;
  }
  .quotes-page .invoices-head h6:nth-child(3),
  .quotes-page .invoice-body p:nth-child(3) {
    width: 23% !important;
  }
  .quotes-page .invoices-head h6:nth-child(4),
  .quotes-page .invoice-body p:nth-child(4) {
    width: 35%;
  }
  .quotes-page .invoices-table-right img {
    opacity: 1;
    cursor: pointer;
  }

/****** Search Pending, Search Saved, Search All Quotes*****/
.quotes-page .appointment-service-modal .quotes-client-edit {
    width: 100%;
}
.quotes-page .appointment-service-modal .quotes-client-edit .dropdown-menu {
    z-index: 99;
    font-weight: 400;
}
.quotes-page .appointment-service-modal .quotes-client-edit .dropdown-menu .dropdown-item img {
    margin-right: 5px !important;
}
.quotes-page .appointment-service-modal .search-invoces-box {
    padding: 20px;
  }
  .quotes-page .appointment-service-modal .select-box {
    padding: 0 0 20px;
  }
  .quotes-page .appointment-service-modal .select-box:after {
    top: 30%;
  }
  .quotes-page .search-invoces-box h6 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }
  .quotes-page .appointment-service-modal .search-box.form-group {
    margin-bottom: 10px;
  }
  .quotes-page .appointment-service-modal .search-box label {
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    margin-bottom: 10px;
    color: #28b8f2 !important;
    font-weight: 600 !important;
  }

  /***** Pending Quotes Popup *****/
  .new-search-popup.modal {
    overflow-y: hidden;
  }
  .new-search-popup.modal .modal-content {
    max-height: calc(100vh - 50px);
    overflow-y: hidden;
    padding-bottom: 30px !important;
  }
  .new-search-popup.modal .modal-content .modal-body {
    max-height: calc(100% - 60px);
    overflow-y: auto;
    padding: 10px 10px 0;
  }

  /***** Quotes POpup *****/
  .Quotes-popup.modal .modal-content .modal-header {
    padding: 8px !important;
  }
  .Quotes-popup.modal .modal-content .modal-body {
    max-height: calc(100% - 100px);
    overflow-y: hidden;
    height: 100%;
  }
  .Quotes-popup.modal .modal-content .modal-body .search-user-list {
    max-height: calc(100% - 65px);
    overflow-y: auto;
    height: 600px;
    margin: 0;
    padding-bottom: 30px;
}
 .Quotes-popup.modal .modal-content .modal-body .search-user-list::-webkit-scrollbar {
  width: 0px;
}
 .Quotes-popup.modal .modal-content .modal-body .search-user-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 .Quotes-popup.modal .modal-content .modal-body .search-user-list::-webkit-scrollbar-thumb {
  background: #888;
}







.quotes-page .target-heading {
display: none;
}



}

@media screen and (min-width: 768px) {
  /* .back-option {
    display: none;
  } */
}

@media (max-width: 1350px) {
  /************ Invoice Details ****************/
  .invoices-list .owing-amount {
    position: unset;
    margin: 0 0 10px auto;
    width: fit-content;
  }
  .invoices-list .search-title .client-profile .client-info {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 991px) {
  .main-titles-desktop {
    display: none !important;
  }

  /* Pending Invoices */
  .table-section.desktop-comp {
    display: none !important;
  }
  .table-section.mobile-comp {
    display: flex !important;
  }

  /* Search Invoices */
  .search-invoice .target-heading .client-left-details p {
    color: #7d7d7d !important;
  }

  /* Quotes Page */
  .quotes-client-edit .dropdown-menu {
    z-index: 99 !important;
    min-width: 195px;
  }
  .quotes-client-edit .dropdown-menu .dropdown-item .action {
    gap: 5px;
  }

  .new-invoice .client-option.service-edit-icon .dropdown-menu .dropdown-item {
    padding: 0;
  }
}


@media (max-width: 480px) {
  .quotes-page .target-heading {
    color: #000;
    padding: 14px 12px;
    border-radius: 5px;
    background-color: #fff;
  }
  .quotes-page .target-heading h6 {
    line-height: 14px;
    font-size: 18px;
    margin-bottom: 0;
  }
  .quotes-page .add-appointment {
    margin-top: 25px;
  }
}

