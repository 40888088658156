
.delete-popup p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center!important;
}

.delete-popup h6 {
    font-size: 18px;
    margin-bottom: 5px !important;
}
.event-popup-btn.footer-btn.view-btn.processing-btn {
    display: flex;
    justify-content: center;
}
p.warning-text {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    font-style: italic;
    color: #28b8f2;
    line-height: initial;
    margin-top: 15px;
    font-size: 12px;
}
p.processing-text {
    margin-top: 10px;
    font-weight: 500;
}
.processing-popup .event-popup-btn {
    margin-top: 20px !important;
}
.processing-popup p.warning-text{
    max-width: 87%;
    margin: 10px auto 0px;
}
.processing-popup {
    padding-top: 30px !important;
}
h6.img-upload-text {
    margin: 0 !important;
}

p.note-text {
    font-style: italic;
    font-size: 14px;
    font-weight: 500;
}
@media screen and (min-width: 991px) {
    .delete-popup h6 {
        font-size: 22px;
    }
}