@media screen and (min-width: 991px) {
  .appointment-container .appointment-view .search-title .client-profile h3{
    font-size: 14px;
  }
  .appointment-view .client-profile span{
    font-size: 14px;
  }
  .appointment-container .search-title h3{
    font-size: 20px;
  }
  .search-title.service-head .sorting {
    padding-right: 10px;
}
.appointment-details.modal .modal-body .staff-form .footer-btn.view-btn{
  margin-bottom: 0px;
}
.finalize-cost-page .footer-btn.view-btn .btn{
  max-width: 200px;
}
  .desktop-content {
    display: block;
  }

  .mob-content {
    display: none;
  }

  /* Add Appointment */
  .add-appointment-page.appointment-container .add-appointment {
    background: transparent;
  }

  .add-appointment-page.appointment-container
    .add-appointment
    .search-container {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .add-appointment-page .search-container .search-title .client-profile {
    align-items: flex-start;
  }

  .add-appointment-page .search-container .search-title .client-profile img {
    width: auto;
    height: 18px;
  }

  .add-appointment-page .appointment-view {
    padding: 0 12px 12px;
  }

  .add-appointment-page .appointment-date {
    margin: 0 -12px;
    border-radius: 5px 5px 0 0;
    padding: 10px 12px;
  }

  .add-appointment-page .appointment-date span {
    font-size: 14px;
    line-height: 1.2;
  }

  .add-appointment-page .appointmentbox {
    margin-bottom: 0;
  }

  .add-appointment-page .appointmentbox .search-title .client-profile h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;
  }
  .add-appointment-page .appointmentbox .search-title .client-profile p {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
  }

  .add-appointment-page .appointmentbox .appointment-form {
    padding-bottom: 20px;
  }

  .add-appointment-page .appointmentbox .appointment-form label {
    height: auto;
    margin-bottom: 5px;
    font-size: 15px;
    padding: 0 !important;
  }

  .add-appointment-page .appointment-total.desktop-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
  }

  .add-appointment-page .appointment-total.desktop-content:nth-child(1) {
    padding-top: 0;
  }

  .add-appointment-page .appointment-total.desktop-content h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .add-appointment-page .appointment-total.desktop-content h6 span {
    margin: 0;
    text-transform: capitalize;
  }

  .add-appointment-page .footer-btn.view-btn {
    margin-bottom: 0;
    justify-content: center;
  }

  /* Appointment Date Popup , Appointment Details Popup, Search Client Popup*/
  .apppt-time-popup .modal-content,
  .appointment-service-modal .modal-content,
  .search-modal .modal-content,
  .appointment-details.modal .modal-content {
    height: fit-content;
    max-height: 100vh;
    overflow: auto;
    padding-bottom: 0 !important;
  }

  .apppt-time-popup .modal-content .staff-close,
  .appointment-service-modal .modal-content .staff-close {
    padding: 10px 0px 30px;
  }

  .apppt-time-popup .modal-content .modal-header {
    padding: 0.5em 1em 0;
  }

  .apppt-time-popup .modal-content .modal-body .search-popup.shift-container {
    margin-bottom: 20px;
  }

  .apppt-time-popup
    .modal-content
    .modal-body
    .search-popup.shift-container:last-child {
    margin-bottom: 10px;
  }

  .apppt-time-popup.time-popup .select-box button.btn,
  .apppt-time-popup.apppt-time-popup.time-popup
    .select-box
    select.form-control {
    height: 40px;
    font-size: 14px;
    box-shadow: none !important;
  }

  .appointment-service-modal .modal-content .modal-body {
    padding: 1rem 0;
  }
  .appointment-service-modal .view-btn.service-submit button {
    border: 0 !important;
  }

  .search-modal .modal-content,
  .appointment-details.modal .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .search-modal .modal-content .search-user-list .search-client-address:hover {
    cursor: pointer;
  }
  .search-modal .modal-content .staff-close i {
    right: 0px;
  }
  .appointment-service-modal .modal-content .staff-close i,
  .apppt-time-popup .modal-content .staff-close i {
    right: 10px;
  }

  .appointment-details.modal
    .modal-body
    .staff-form
    .footer-btn.view-btn
    button {
    max-width: fit-content !important;
  }
  .appointment-details.modal
    .modal-body
    .staff-form
    .footer-btn.view-btn
    button.dropdown-toggle {
    padding: 10px;
  }
  .appointment-details.modal
    .modal-body
    .staff-form
    .footer-btn.view-btn
    .dropdown {
    width: fit-content;
  }
  .appointment-details.modal .modal-body .staff-form .footer-btn.view-btn {
    justify-content: center !important;
    gap: 20px;
  }
  .appointment-details.modal
    .modal-body
    .client-details
    .service-edit-icon
    .action {
    font-size: 16px;
    padding: 0 6px;
  }
  /* .appointment-details.modal .modal-body .staff-form .pool-visit-cost h5 {
        font-weight: 700;
font-size: 18px;
line-height: 24px;
text-transform: uppercase;
color: #3C424B;
    } */
  .appointment-details.modal .modal-body .staff-form .pool-visit-cost h6 {
    /* color: #8D8D8D;
        font-weight: 700;
font-size: 20px;
line-height: 27px;
text-transform: uppercase; */
    font-size: 16px;
    line-height: 1.2;
  }
  .appointment-details.modal .modal-body .staff-form .pool-visit-cost p {
    color: #8d8d8d;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
  }
  .appointment-details.modal
    .modal-body
    .staff-form
    .appointment-form
    .form-group
    label {
    height: auto;
    margin-bottom: 5px;
    font-size: 15px !important;
    padding: 0 !important;
    font-weight: 600;
  }
  .appointment-details.modal
    .modal-body
    .staff-form
    .appointment-form
    .form-group
    .job-notes-content {
    min-height: 90px;
    font-size: 14px;
    height: 100%;
    color: #8d8d8d;
    background-color: #fefefe;
  }

  /* Scrollbar Design */
  .search-modal .modal-content::-webkit-scrollbar,
  .apppt-time-popup .modal-content::-webkit-scrollbar,
  .appointment-service-modal .modal-content::-webkit-scrollbar,
  .appointment-service-modal
    .modal-content
    .service-popup
    .auto-complete::-webkit-scrollbar,
  .appointment-details.modal .modal-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .search-modal .modal-content::-webkit-scrollbar-thumb,
  .apppt-time-popup .modal-content::-webkit-scrollbar-thumb,
  .appointment-service-modal .modal-content::-webkit-scrollbar-thumb,
  .appointment-service-modal
    .modal-content
    .service-popup
    .auto-complete::-webkit-scrollbar-thumb,
  .appointment-details.modal .modal-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #999999;
  }

  .search-modal .modal-content::-webkit-scrollbar-track,
  .apppt-time-popup .modal-content::-webkit-scrollbar-track,
  .appointment-service-modal .modal-content::-webkit-scrollbar-track,
  .appointment-service-modal
    .modal-content
    .service-popup
    .auto-complete::-webkit-scrollbar-track,
  .appointment-details.modal .modal-content::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  .table-section.desktop-comp p {
    width: 20% !important;
}
  .table-section.desktop-comp .customer-name p{
    width: 100% !important;
}
.awaiting-approvals .awating-new-section .visit-color-green{
    padding: 0px 20px;
}
}

@media screen and (max-width: 991px) {
  .mob-content {
    display: block;
  }

  .desktop-content {
    display: none;
  }
  .add-service-modal .view-btn.service-submit {
    justify-content: space-between;
  }
}
