.header {
  background-color: #0048ba;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 9999;
  transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}
.homepage .header {
  background-color: transparent;
}
.header.sticky {
  /* height: 100px; */
  background-color: #0048ba;
  /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06); */
}

.homepage .header .navbar-dark .navbar-nav .nav-link:focus,
.homepage .header .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.75);
}
.homepage .header .navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #333 !important;
}
.header.sticky .navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
  padding: 0 20px;
}
.header.sticky .navbar-dark .navbar-nav .nav-item .nav-link:last-child {
  padding-right: 0;
}
.homepage .header.sticky .navbar-dark .navbar-brand img {
  color: #fff;
  max-height: 90px !important;
  /* width: 110px; */
}
.header.sticky .navbar-dark .navbar-brand img {
  max-height: 76px !important;
  /* width: 110px; */
}

.homepage .navbar-brand img.logo-dark {
  display: none;
}
.homepage .navbar-brand img.logo-light {
  display: block !important;
}
.navbar-brand img.logo-light {
  display: none;
}

/* .navbar-brand img {
  max-height: 241px;
} */
.navbar-brand img {
  max-width: 135px;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  padding: 0 15px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
.navbar-dark .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}
.header.Sticky .navbar-dark .navbar-nav .nav-link {
  font-size: 16px;
}
.homepage .navbar-collapse {
  padding-bottom: 0;
}
.homepage .sticky .navbar-collapse {
  padding-bottom: 20px;
}
.navbar-collapse {
  border: 0;
  border-top: none;
  padding-bottom: 20px;
}
.dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  border: none;
}
.navbar-dark .navbar-brand {
  color: #fff;
  background: #fff;
  margin: 8px 0;
  padding: 5px;
  border-radius: 3px;
  transition: 0.3s all ease-in;
}
.homepage .navbar-dark .navbar-brand {
  background: transparent;
  margin: 0;
  padding: 5px;
}
.homepage .navbar {
  align-items: center !important;
}
.homepage .sticky .navbar {
  align-items: flex-end !important;
}
.navbar {
  width: 100%;
  padding: 0;
  align-items: flex-end !important;
}
/* .Stickey{

} */

.sticky .header-top {
  position: absolute;
  right: 0px;
}

.sticky li.menu-profile-item .btn {
  padding: 7px;
  font-size: 13px;
}
/* 
.sticky div#basic-navbar-nav {
  padding-top: 46px;
} */

/* header top start */

.header-top {
  width: auto;
  line-height: 40px;
  z-index: 9;
  max-width: 100%;
  position: absolute;
  right: 0px;
}
ul.menu-profile-list {
  float: right;
  display: flex;
  align-items: center;
}
ul.menu-profile-list li.menu-profile-list {
  padding: 0 15px;
}
ul.menu-profile-list li.menu-profile-list span {
  font-size: 16px;
  font-weight: 700;
  color: #b1b1b1;
}
ul.menu-profile-list li.menu-profile-list h6 {
  margin-bottom: 0;
  font-size: 16px;
  padding: 0 5px;
  color: #b1b1b1;
}
.menu-profile-item .nav-link {
  display: inline-block;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #333;
}
.homepage .menu-profile-item .nav-link,
.sticky .menu-profile-item .nav-link {
  color: #fff;
}

li.menu-profile-item .btn {
  margin-left: 10px;
  background: #c82333;
  border-radius: 40px;
  color: #fff;
  border-color: transparent;
  padding: 7px;
  font-size: 13px;
}
/* header Top end  */

/* 
.custoem header */

.custom-header .nav-item .nav-link {
  color: #333;
  padding: 0 15px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
}
.cross-icon {
  display: none;
}
.custom-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.mobile-view {
  display: none;
}
.cross-icon.show-header .custom-header {
  display: block !important;
}
/* .show-header-top{
  display: block;
  transition: all ease .5s;
  transition-delay: 2s;
}
.hide-header{
  display: none;
  transition: all ease .5s;
  transition-delay: 2s;
} */
.openheader {
  height: 16px;
  width: 22px;
}
/* .show-header-top .header {
  opacity: 1;
  visibility: visible;
  height: 80px!important;
  transition: all ease .2s;
  overflow: inherit;
  /* transition-delay: 2s; 
}
.hide-header .header {
  height: 0!important;
  overflow: hidden;
  opacity: 0;
} */

/* .show-header-top .header {
top: auto;
}
.hide-header .header {
top: -100px;
} */

/* 
.show-header-top .header {
  height:auto ;
  opacity: 1;
  visibility: visible;
  transition: all ease .5s;
  }
  .hide-header .header {
  height: 0px;
  visibility: hidden;
  opacity: 0;
  transition: all ease .5s;
  }
  .header-view .header{
    transition: all ease .5s;
  } */

/* .header.sticky .nav-item a {
    color: white !important;
} */
.header .desktop-view-logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-link.header-desktop {
  position: fixed;
  background-color: #0048ba;
  height: 100vh;
  bottom: 0px;
  height: 100%;
  width: 0px;
  right: 0px;
  z-index: 999999;
  box-shadow: 0px 0px 10px 0px;
  transition: all ease 0.5s;
  opacity: 0;
}

.header-link.header-desktop .nav-item {
  width: 100%;
  margin: 21px;
}

.header-link.header-desktop .desktop-list {
  background-color: #0048ba;
  height: 100vh;
  margin-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}
.header-link.header-desktop .desktop-list img.logo-dark {
  height: 60px;
}
.header-link.header-desktop .desktop-list .top-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}

.top-icon .cross-desktop-icon img {
  height: 20px;
  cursor: pointer;
}
.header .open-header-desktop {
  margin-top: 25px;
}
.header .open-header-desktop {
  margin-top: 25px;
}

/* .header .cross-desktop-icon img {
  height: 20px;
  cursor: pointer;
} */

/* .header .cross-desktop-icon {
  position: absolute;
  right: 20px;
  top: 30px;
} */
.header-link.header-desktop .desktop-list .nav-item a {
  text-align: left;
  color: white;
}
.header .desktop-icon img {
  cursor: pointer;
  height: 25px;
  width: 28px;
}
.show-desktop-header .header-link.header-desktop {
  display: block;
  transition: all ease 0.5s;
  right: 0px;
  width: 365px;
  opacity: 1;
}
.hide-desktop-header .header-link.header-desktop .cross-desktop-icon {
  display: none;
}
.hide-desktop-header .header-link.header-desktop {
  transition: all ease 0.5s;
  width: 0px;
}
.custom-header .yellow-bg a {
  /* background-color: #ffff00; */
  /* color: #ffff00 !important; */
  line-height: inherit;
  display: block;
  padding: 4px !important;
}
.submenu-item a {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.submenu-item.nav-item a {
  line-height: initial;
}

.mob-header {
  overflow: auto;
}
.yellow-bg.text-bold.spacing.nav-item {
  margin-top: 15px;
}
.mob-header .header-link a {
  line-height: initial !important;
}
.main-menu.nav-item {
  margin-top: 19px;
}
.submenu-item.nav-item {
  margin-top: 5px;
}
a.nav-link.disable-link {
  pointer-events: none;
  cursor: not-allowed;
}
.fixed-body-section {
  overflow: hidden;
}
.main-menu a {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.submenu-item.nav-item a, .submenu-item.nav-item span {
  color: #2ea7e2 !important;
  cursor: pointer;
  line-height: initial;
}
.submenu-item.nav-item a {
  color: #2ea7e2 !important;
  font-size: 18px !important;
}
.main-menu.hidden-menu.nav-item {
  display: none;
}
.logout-btn button.btn.btn-danger.btn.btn-primary,
.logout-btn button.btn.btn-danger.btn.btn-primary:hover,
.logout-btn button.btn.btn-danger.btn.btn-primary:focus {
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 10px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 38px !important;
  max-width: 150px;
  color: #0d51be;
  background-color: white !important;
  
}
.logout-btn {
  margin-top: 30px !important;
}
.submenu-item a.nav-link.disable-link {
  color: #ffee55 !important;
}
/* body.fixed-body-section {
  position: fixed;
} */
.yellow-header .header {
  background: #ffca21 !important;
}
.mbl-header {
  display: none;
}

.web-header-layout .dropdown-item.active,
.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}
body.disable-header .cross-icon {
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.disable-header .footer-wrapper {
  display: none !important;
}
.disable-header .navbar-collapse,
.disable-header .auth-login {
  display: none !important;
}
.custom-mob-section {
  width: 100%;
}
.header .nav-item span {
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .mbl-header {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .main-menu a {
    font-size: 22px !important;
  }
  .submenu-item.nav-item a {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 480px) {
  .main-menu a {
    font-size: 20px !important;
  }
  .submenu-item.nav-item a {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 360px) {
  .main-menu a {
    font-size: 15px !important;
  }
  .submenu-item.nav-item a {
    font-size: 12px !important;
  }
}