.pricing-container .heading {
  font-size: 20px;
}
.pricing-container .heading h3 {
  margin-bottom: 40px;
}
.pricing-container .submit-btn.form-group {
  padding-top: 30px;
}

.price-list {
  width: 100%;
  vertical-align: top;
  display: inline-block;
  padding: 36px 27px 40px;
  border: 1px solid #a6a6a6;
}
.price-list-box {
  position: relative;
  border-radius: 4px;
  margin-bottom: 25px;
}
.price-list-box:last-child {
  margin-bottom: 0;
}
.price-box {
  width: calc(100% - 266px);
  position: relative;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  color: #a0a0a0;
}
.price-box h4 {
  font-size: 27px;
  font-style: normal;
  color: #595959;
}
.price-box h6 span {
  font-style: italic;
}
.price-box h6 {
  margin-bottom: 12px;
  font-family: "Exo 2", sans-serif;
  font-style: italic;
  font-size: 20px;
  font-weight: 400;
  color: #595959;
}

.price-list-box .price-btn {
  position: absolute;
  right: 37px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.price-list-box .price-btn .btn {
  border-radius: 50px;
  min-width: 178px;
  padding: 10px 12px;
  color: #083266;
  font-style: italic;
  border: 1px solid #ffaf34;
  text-transform: capitalize;
  font-weight: 700;
  background: rgb(249, 245, 234); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(249, 245, 234, 1) 0%,
    rgba(252, 205, 77, 1) 57%,
    rgba(248, 181, 0, 1) 66%,
    rgba(249, 232, 189, 1) 99%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(249, 245, 234, 1) 0%,
    rgba(252, 205, 77, 1) 57%,
    rgba(248, 181, 0, 1) 66%,
    rgba(249, 232, 189, 1) 99%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(249, 245, 234, 1) 0%,
    rgba(252, 205, 77, 1) 57%,
    rgba(248, 181, 0, 1) 66%,
    rgba(249, 232, 189, 1) 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f5ea', endColorstr='#f9e8bd',GradientType=0 ); /* IE6-9 */
}

/* pricing form Start */

.pricing-form-container {
  padding-top: 30px;
}
.pricing-form-box {
  border: 3px solid #9ebfe7;
  padding: 30px 50px;
}
.pricing-form-title h6 {
  font-family: "Exo 2", sans-serif;
  background: #c5d9f1;
  color: #1d66c3;
  margin-bottom: 0;
  padding: 5px 8px;
}
.pricing-form-title {
  margin-bottom: 15px;
}
.pricing-form-box label.form-label {
  width: calc(100% + 60px);
  margin-left: -30px;
  justify-content: space-between;
  padding-left: 30px;
  color: #283674;
  font-size: 16px;
}
.pricing-form-box .select-box select.form-control {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
}
.pricing-form-box .form-control {
  border-radius: 5px;
  line-height: 1.2;
  /* height: 100%; */
}
.pricing-pre-form {
  padding-top: 40px;
}
.view-btn .btn-next {
  background: #1d66c3;
  border-color: #1d66c3;
  min-width: 100px;
  border-radius: 0;
}
.pricing-form-box .view-btn {
  margin-bottom: 0;
  margin-top: 40px;
}

/* Reciept Container Start */

.perchase-reciept-container {
  padding-top: 30px;
}
.reciept-container {
  /* border: 3px solid #9ebfe7; */
  /* margin-bottom: 40px; */
}
.reciept-container h5 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 0;
  background: #7f7f7f;
  text-align: center;
  padding: 5px;
  font-family: "Exo 2", sans-serif;
}
.reciept-content {
  padding: 30px 60px;
  border: 3px solid #9ebfe7;
  border-top: 0 solid transparent;
}
.reciept-upper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid #7f7f7f;
}

.content-right {
  text-align: right;
}
.reciept-upper h4,
.reciept-lower h4 {
  color: #2375c8;
  font-size: 22px;
  font-weight: 700;
  font-family: "Exo 2", sans-serif;
}
.reciept-upper h6,
.reciept-lower h6 {
  color: #7f7f7f;
  font-size: 18px;
  line-height: 1.5;
  font-family: "Exo 2", sans-serif;
}
.reciept-lower h6:last-child {
  color: #6f6f6f;
  font-size: 20px;
  margin-bottom: 0;
}
.reciept-lower {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.reciept-lower .content-right {
  max-width: 150px;
  width: 100%;
}
.reciept-lower .content-left {
  width: 100%;
  text-align: right;
}
.reciept-footer {
  width: 100%;
  display: flex;
  align-items: center;
}

.reciept-footer .view-btn {
  margin-bottom: 0;
}
ul.payment-icon-list {
  display: flex;
  align-items: center;
  width: calc(100% + 6px);
  margin-left: -3px;
}

ul.payment-icon-list li {
  padding: 0 3px;
}
ul.payment-icon-list li:first-child {
  padding-right: 20px;
}
.payment-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul.payment-icon-list li.paypal img {
  width: 120px;
}
