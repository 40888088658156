.blockimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blockimg {
  width: 100%;
}
.about-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 30px 0; */
  font-size: 20px;
}
.about-content h3 {
  margin-bottom: 25px;
  font-size: 40px;
}
.about-content .block-btn {
  margin-top: auto;
}
.blockimg:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.blockimg {
  width: 100%;
  /* height: 100%; */
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  position: relative;
}
.about-content h1 span:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 4px;
  background: #f8d400;
  top: 100%;
  left: 0;
}
.about-content h1 span {
  position: relative;
}
.about-content h1 {
  font-size: 50px;
  margin-bottom: 30px;
}
.about-content .block-btn {
  margin-top: auto;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #555;
  background: #dcdcdc;
  font-family: "Merriweather", Georgia, "Times New Roman", serif;
}
