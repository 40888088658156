.automated-payment-reminders .main-titles-mob {
  align-items: flex-start;
}
.automated-payment-reminders .client-option.service-edit-icon .dropdown-menu {
  min-width: 12rem;
  z-index: 99;
  width: auto !important;
}
.automated-payment-reminders
  .client-option.service-edit-icon
  .dropdown-item:active {
  background-color: transparent;
}
.automated-payment-reminders .client-option.service-edit-icon .dropdown-menu a {
  white-space: inherit !important;
}
/******** sending Reminders *****/
.sending-reminders-page .number-field .dropdown-toggle::after {
  display: none;
}
.sending-reminders-page .appointment-summary {
  margin-bottom: 20px;
}
.sending-reminders-page .number.form-group .number-field .form-control {
  height: 38px !important;
}
.sending-reminders-page .pending-water-test {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
}

.sending-reminders-page .open-date.client-notes {
  width: fit-content;
}

.sending-reminders-page .pending-water-test .form-check {
  line-height: initial;
}
.automated-payment-reminders .main-titles-mob h3 {
  font-size: 24px;
  max-width: 90%;
  width: 100%;
}
.sending-reminders .target-heading.client-profile h3 {
  font-size: 18px;
  max-width: 90%;
  width: 100%;
  color: #333 !important;
}

/***** edit mobile popup **/
.edit-mobile-popup .view-btn {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.edit-mobile-popup .appointment-summary {
  margin-top: 20px;
}
.verification-code {
  padding-top: 60px;
  width: 100%;
}
.verification-code .view-single-btn {
  margin-top: 20px;
}
.view-single-btn {
  display: flex;
  justify-content: flex-end !important;
  margin: 0px !important;
  margin-top: 10px !important;
}

.view-single-btn button {
  max-width: 205px;
  width: 60% !important;
  width: fit-content !important;
}

/**** email sms sending ******/
.email-sms-sending .target-heading.client-profile h3 {
  font-size: 18px;
  max-width: 100%;
  width: 100%;
  color: #333 !important;
}
.email-sms-sending .sending-days-section {
  background: #fff;
  border-radius: 5px;
  padding: 12px 12px;
}

.email-sms-sending .days-description-list {
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
}

.email-sms-sending .days-description-list h6 {
  width: 20%;
  font-size: 14px;
}

.email-sms-sending .days-description-list span {
  width: 80%;
  color: #757575;
  font-weight: 400;
  font-size: 13px;
}

.email-sms-sending .days-description {
  padding-left: 20px;
}
.email-sms-sending .top-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/**** edit days time popup *********/
.edit-daystime-popup .datetime-table .dropdown::after {
  pointer-events: none;
}

/**** sms invoice template *********/
.sms-invocie-template .top-heading {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}
.sms-invocie-template .days-description {
  padding: 0;
}
.sms-invocie-template .days-description p {
  margin: 0px;
  line-height: 16px;
}

.sms-invocie-template .todays-invoice {
  margin-top: 20px;
}

.sms-invocie-template .todays-invoice h6 {
  margin: 0;
}

.sms-invocie-template .todays-invoice p .inovocie-link {
  color: #0309ff;
  border-bottom: 1px solid #0309ff;
  margin: 0px 5px;
}

.sms-invocie-template .todays-invoice p {
  font-weight: 500;
  color: #333;
}

.sms-invocie-template .todays-invoice p span {
  margin-right: 12px;
}
.sms-invocie-template .bottom-inovoice-text {
  margin-top: 20px;
}

.sms-invocie-template .bottom-inovoice-text p:first-child {
  margin-bottom: 12px;
}

.sms-invocie-template .bottom-inovoice-text p {
  color: #333 !important;
  font-weight: 500;
}
.sms-invocie-template .todays-invoice.past-due h6:last-child {
  margin-top: 10px;
}
/******* email invoice template **********/
.email-invoice-template .bottom-inovoice-text.sample-company-name h6 {
  margin-bottom: 0px;
}
.email-invoice-template .message-subject {
  margin-bottom: 25px;
}

/****** payment reminder page ************/
.payment-reminder-page
  .product-service-container
  .table-data
  .outer-accordion.accordion
  .card-header
  .btn.btn-link {
  width: 100% !important;
}
.payment-reminder-page span.span-measure {
  text-transform: capitalize;
  color: #333 !important;
}
.fade.time-picker-modal.modal.show .modal-content {
  border: none !important;
}
.payment-reminder-page button.edit-btn.search-icon img {
  margin-top: 1px !important;
}
.payment-reminder-page button.edit-btn.search-icon.btn.btn-primary {
  background: #f7910c;
  border: 1px solid #f7910c;
}
/******* payment reminder poupp ***********/
.payment-reminder-popup .add-payment-text {
  font-size: 12px;
  line-height: initial;
  color: #0cc1f5;
  font-style: italic;
  font-weight: 500;
  display: flex;
}

.payment-reminder-popup p.add-payment-text span {
  font-size: 17px;
  margin-right: 5px;
  margin-top: -5px;
}
.payment-reminder-popup .shift-container.bottom-shif-container {
  padding-top: 0px !important;
}

.payment-reminder-popup .shift-container.shift-container-top {
  padding-bottom: 0px !important;
}
.payment-reminder-popup .shift-container.add-client-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0px;
  padding: 22px 15px !important;
  color: #0070c0 !important;
}

.payment-reminder-popup .shift-container.add-client-container h6 {
  margin: 0px;
}
.payment-reminder-popup .shift-container.add-client-container h6 i {
  cursor: pointer;
}
.payment-reminder-popup .shift-container.added-client img {
  height: 18px;
  filter: brightness(0.6);
  cursor: pointer;
}

.payment-reminder-popup .shift-container.added-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-reminder-popup
  .shift-container.added-client
  .client-details-section
  p {
  margin-bottom: 2px;
  font-size: 12px;
  line-height: initial;
}

.payment-reminder-popup
  .shift-container.added-client
  .client-details-section
  h6 {
  font-size: 16px;
  margin: 0;
}

.payment-reminder-popup .client-details-section {
  color: #0070c0 !important;
}
.payment-reminder-page
  .add-payment-popup
  .payment-reminder-popup
  .shift-container.bottom-shif-container {
  padding-top: 15px !important;
}

.payment-reminder-popup
  .add-payment-popup
  .shift-container.shift-container-top {
  padding-bottom: 15px !important;
}
.payment-reminder-popup .staff-form {
  background-color: transparent;
  padding: 0;
}

/***** invoice send approval **************/
.invoice-send-approval .invoice-max-section {
  padding: 20px;
  background: white;
  padding: 14px 12px;
  border-radius: 5px;
  background-color: #ffffff;
}
.invoice-send-approval .target-heading.client-profile h3 {
  font-size: 18px;
  font-weight: 600;
}
.invoice-send-approval .target-heading.client-profile p {
  font-size: 14px;
  color: #333 !important;
  font-weight: 600;
}
.invoice-send-approval .event-popup-btn.footer-btn.view-btn {
  margin-top: 100px;
}

.automated-payment-days-picker .smart-am-pm-container {
  display: none;
}
.payment-reminder-popup .select-box button.btn.btn-primary {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #a5a5a5;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid #e0e0e0;
  height: 35px;
}
.sending-reminders-disabled:disabled {
  background: #fff;
}
.staff-form .text-tarnsform-none {
  text-transform: none !important;
}
.staff-modal.edit-mobile-popup.sending-reminders-page .number-field input {
  border-color: #ced4da;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ced4da !important;
  background: #fff;
  border-radius: 0;
  padding: 1px 8px;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0 !important;
}
