.main-titles {
  text-align: center;
  margin-bottom: 40px;
  padding: 5px 0;
}
.main-titles h3 {
  font-size: 30px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Exo 2", sans-serif;
}
.sub-container {
  background: #eff9ff;
  border: 1px solid #00b050;
  height: 100%;
}
.heading-type {
  background: #009900;
  padding: 8px 12px;
  text-align: center;
}
.heading-type h4 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: "Exo 2", sans-serif;
}
.sub-box {
  padding: 20px 15px;
}
.sub-content h5 {
  font-size: 20px;
  color: #18a940;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-family: "Exo 2", sans-serif;
}
.sub-content h6 {
  color: #35dd2b;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "Exo 2", sans-serif;
}
.sub-content {
  color: #3cfd40;
  font-size: 18px;
  margin-bottom: 15px;
}
.sub-content > p,
.invoice-content > p {
  margin-bottom: 5px;
}
.sub-content:last-child {
  margin-bottom: 0;
}
.sub-content span {
  font-style: italic;
}
.sub-content.additional h6 {
  color: #27d0ff;
  font-size: 20px;
  margin-bottom: 5px;
  font-style: italic;
}
.sub-content.additional {
  color: #56cfff;
  font-style: italic;
  font-weight: 500;
}
.invoice-outer {
  height: 100%;
}
.invoice-container {
  border: 2px solid #0070c0;
}
.invoice-container .heading-type {
  background: #0070c0;
}
.invoice-box {
  padding: 20px 15px;
  margin-bottom: -15px;
}
.invoice-content h5 {
  font-size: 20px;
  color: #4088c5;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-family: "Exo 2", sans-serif;
}
.invoice-content h6 {
  font-size: 18px;
  color: #4088c5;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-family: "Exo 2", sans-serif;
}
.invoice-content {
  color: #62d8f9;
  font-size: 18px;
  margin-bottom: 15px;
}
.invoice-content span {
  font-style: italic;
}
.invoice-content span a {
  color: #4088c5;
  font-weight: 500;
}
.account-container .head-table.table {
  border: none;
}
.account-title h6 {
  font-size: 20px;
  color: #4088c5;
  margin-bottom: 10px;
  text-transform: capitalize;
  padding-left: 15px;
  font-family: "Exo 2", sans-serif;
}
.account-container .head-table.table thead th:first-child,
.account-container .head-table.table tbody td:first-child {
  text-align: center !important;
  border-left: 0;
}
.account-container .head-table.table tbody td:first-child {
  font-weight: 500 !important;
}
.account-container .head-table.table tbody td {
  font-weight: 500;
  font-size: 16px;
}

.account-container .head-table.table thead th:last-child,
.account-container .head-table.table tbody td:last-child {
  border-right: 0;
}

.account-container .head-table.table thead th {
  border-bottom: 1px solid #0070c0;
  background-color: #d1ebff;
  color: #00b0f0;
  border-top: 1px solid #00bdf7;
  font-weight: 700;
  font-size: 18px;
}
/* .invoice-content .table {
  margin-bottom: 0;
  color: #00b0f2;
  text-align: center;
}
.invoice-content .table thead th {
  border-bottom: 1px solid #00b0f0;
  vertical-align: middle;
  background-color: #d1ebff;
  color: #00b0f0;
}
.invoice-content .table tbody td {
  background-color: #eff9ff;
}
.invoice-content .table-bordered td,
.invoice-content .table-bordered th {
  border: 1px solid #00b0f0;
} */
.total-due span {
  border: 1px solid #0070c0;
  padding: 10px;
  margin-left: 10px;
  color: #0070c0;
  font-weight: 600;
}
.total-due {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  padding: 10px 0;
  color: #0070c0;
  font-weight: 600;
}
.btn + .btn {
  margin-left: 4px;
}
.invoice-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invoice-btn .btn.payment {
  background: #00b050;
  border-color: #00b050;
  color: #fff;
}
.invoice-btn .btn.change-sub {
  background: #e46d0a;
  border-color: #e46d0a;
  color: #fff;
}
.invoice-btn .btn.renew-sub {
  background: #0070c0;
  border-color: #0070c0;
  color: #fff;
}
