
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";
/* @import "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap"; */
@import "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";

body {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  font-family: "Exo 2";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form-control {
  outline: none;
}
.container {
  width: 100%;
  max-width: 1480px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Exo 2";
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.btn {
  border-radius: 5px;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 600;
  min-width: 116px;
  padding: 10px 12px;
}
.btn-primary {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: transparent;
  border-color: #333;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background: transparent;
  border-color: transparent;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}
p:last-child {
  margin-bottom: 0 !important;
}

.select-box select.form-control {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #a5a5a5;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #e0e0e0;
}
.select-box {
  position: relative;
}
.select-box > i {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  background: rgb(0, 0, 0, 0.1);
  border: 1px solid rgb(206 212 218);
  height: 100%;
  width: 30px;
  font-size: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.heading-table {
  width: 100%;
  border: 1px solid #00b0f0;
  position: relative;
  margin-bottom: 20px;
}
.heading-table h5 {
  background: #00b0f0;
  padding: 6px;
  text-align: center;
  margin-bottom: 0;
  color: #fff;
  font-size: 22px;
  font-family: "Exo 2";
}
.heading-table table {
  margin-bottom: 0;
  text-align: center;
}
.heading-table .table-bordered tbody td,
.heading-table .table-bordered thead th {
  border: 1px solid #00b0f0;
  padding: 8px 12px;
}
.heading-table .table-bordered thead th {
  background: #d1ebff;
  color: #0070d5;
  vertical-align: top;
}
.heading-table .table-bordered thead th span {
  font-style: italic;
  font-weight: 400;
}
.heading-table .table-bordered tbody td {
  background: #fff;
  color: #00b0f5;
  font-weight: 500;
}
/* .table-bordered tr td:nth-child(2),
.table-bordered tr th:nth-child(2) {
  text-align: left;
} */
.heading-table > a {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff;
}
.view-btn .blue-btn {
  background-color: #0048ba !important;
  border-color: #0048ba !important;
}
.notification-message .title {
  background-color: transparent !important;
}

.close span {
  display: none;
}
.modal-header {
  position: relative;
}
.close:after {
  content: "";
  background-image: url(./assets/admin/close.png);
  background-size: 25px;
  width: 25px;
  position: absolute;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  top: 21px;
}
.btn-delete {
  background-color: transparent;
  border-color: #939191 !important;
  color: #939191 !important;
}
.btn-save {
  background-color: #1aa01d !important;
  border: #1aa01d !important;
  color: white !important;
}
.btn-edit {
  background-color: transparent;
  border-color: #747070 !important;
  color: #747070 !important;
}
.view-btn .btn {
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 10px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 38px !important;
}
.modal-footer {
  padding: 10px 8px !important;
}

.react-confirm-alert-body .react-confirm-alert-button-group button {
  padding: 8px 15px !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  width: 25%;
}
.react-confirm-alert-body
  .react-confirm-alert-button-group
  button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #747070;
  color: #747070;
}
.form-group input.form-control,
textarea.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:focus {
  border: 1px solid #e0e0e0 !important;
}
.modal-content {
  padding-bottom: 80px !important;
}
.notification-container {
  z-index: 9999999999;
}
.time-calender
  button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #3776f1;
}

.time-calender
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #3776f1 !important;
}
.time-calender .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.time-calender .react-calendar__month-view__weekdays__weekday:nth-child(7),
.react-calendar__month-view__weekdays__weekday:nth-child(6) {
  color: #333 !important;
}
.time-calender
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.view-btn .dropdown-menu.show {
  position: absolute !important;
  transform: translate(0%, -100%) !important;
  background-color: white;
  inset: unset !important;
  top: -5px !important;
}
.view-btn .dropdown-toggle::after {
  display: none !important;
}

.client-option .dropdown-item:active {
  background-color: transparent !important;
}
.time-popup.custom-time-popup .modal-content {
  padding-bottom: 0 !important;
}
.startdate-modal .modal-content,
.enddate-modal .modal-content {
  height: auto;
  min-height: auto;
  width: fit-content;
  margin: 0px auto;
  overflow: hidden;
  width: calc(100% - 55px);
}
.view-btn .btn {
  width: 49%;
}

.modal-title {
  text-align: left;
  margin-bottom: 20px;
  background: transparent;
  padding: 0;
  border: none;
  font-weight: 600;
  margin-bottom: 0;
  font-family: "Exo 2" !important;
}
input:hover,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  /* border: 2px solid #0d6efd40 !important; */
}
@media screen and (min-width: 991px) {
  .view-btn .btn {
    font-size: 18px !important;
    height: 45px !important;
  }
  .form-group input {
    height: 40px;
    font-size: 14px;
  }
  .select-box select.form-control {
    height: 40px;
    font-size: 14px;
  }
  .form-check input {
    height: 20px;
    width: 20px;
  }
  .select-box select.form-control {
    z-index: initial;
  }
  .modal-open .modal::-webkit-scrollbar {
    width: 0px;
  }
  .modal-open .modal::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .modal-open .modal::-webkit-scrollbar-thumb {
    background: #888;
  }
}
@media screen and (min-width: 991px) {
  /****** global aal pages css for desktop view fixes **********/
  html{
    background: #f6f7fb;
  }
  .event-popup-btn.footer-btn.view-btn{
    margin-bottom: 0px;
  }
.received-payments .footer-btn {
  justify-content: center;
}
.uncomplete-appointment .footer-btn.view-btn button.appointment-btn.btn-save.btn.btn-primary,
.appointment-details.modal .modal-body .staff-form .footer-btn.view-btn button.dropdown-toggle {
  font-size: 16px !important;
}
.view-btn .btn{
  padding: 8px 15px !important;
}
.staff-close i{
  right: 0;
}
.modal-content{
  padding-bottom: 0px !important;
}
.staff-form .view-btn {
  justify-content: center;
  gap: 20px;
}
.product-service-container.equipemnt-page .service-mbl{
  display: block;
}
.product-service-container .table-data .outer-accordion.accordion .card-header .btn.btn-link,
.table-data .mob-accordion .card-header{
  padding: 0;
}
.service-mbl .mob-accordion {
  border-bottom: 1px dashed #ececec;
}
.mob-accordion .card-header .btn.btn-link{
  font-size: 15px;
}
.action{
  cursor: pointer;
}
.profile-page .view-btn.staff-form-btn {
  justify-content: center !important;
}
}