.company-info .staff-form{
    background: #fff;
    border-radius: 5px;
    padding: 12px 12px;
}
.company-location .sort-icon img{
    width: 32px !important;
    margin-right: 10px;
}
.company-location .sort-icon i{
    font-size: 20px;
}
.company-info .number-field .dropdown-toggle::after{
    display: none;
}
.name-location p {
    color: #9a9a9a;
    font-weight: 500;
    font-size: 15px;
    line-height: initial;
}
.add-location-field .client-option.service-edit-icon {
    /* width: 30px; */
    width: auto;
    text-align: right;
    display: flex;
    align-items: center;
}

.company-location .mob-accordion .card-header .btn.btn-link {
    align-items: flex-start !important;
}
.company-info .company-location .client-heading-option {
    align-items: center !important;
}
.client-container.company-location button.btn.btn-link {
    justify-content: flex-start !important;
}