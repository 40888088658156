/* 
.appointment-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
  min-height: 450px;
}

.appointment-body {
  padding: 20px 25px;
} */

/************* Search Customer *****************/
/* 
.customer-search {
  padding: 20px 25px;
}

.customer-search h4 {
  font-size: 16px;
  font-weight: 700;
}

.customer-search .form-inline .form-control {
  width: 100%;
  padding-right: 40px;
  height: auto;
  padding: 8px 15px;
  border-radius: 0;
}

.customer-search .form-inline .search-card .btn {
  width: 30px;
  min-width: auto;
  padding: 0;
  background: transparent;
  border: none;
  color: #000;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.customer-search .form-inline .btn-primary:focus {
  box-shadow: none;
  outline: none;
}

.customer-search .form-inline {
  position: relative;
}

.search-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.search-icon img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.search-content h4 {
  margin: 0;
  font-size: 18px;
  font-family: "Exo 2";
}

.search-content p {
  margin: 0;
  color: #999;
  font-family: "Exo 2";
}

.search-container-user {
  margin-top: -1px;
}

.search-container-user .search-items:last-child {
  border-bottom: none;
}

.no-customer {
  border: 1px dashed #ddd;
  padding: 20px 30px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.no-customer img {
  width: 70px;
  margin-bottom: 10px;
}

.no-customer p {
  margin: 0;
  color: #999;
}

.search-container-user a {
  display: block;
  text-align: center;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  color: #0175ff;
}

.search-container-user a:hover {
  text-decoration: none;
} */

/************* Footer Buttons ********/
/* 
.appointment-buttons {
  padding: 20px 25px;
  border-top: 1px solid #ddd;
  text-align: center;
  margin-top: 20px;
}

.appointment-buttons span {
  font-size: 16px;
  font-weight: 700;
}

.footer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}

.footer-button .btn {
  width: 48%;
  padding: 10px 15px;
}

.footer-button .border-btn {
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.footer-button .border-btn:hover {
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.footer-button .appointment-btn {
  background: #0048ba;
  border: transparent;
  color: #fff;
  flex-grow: 1;
} 

.footer-button .appointment-btn:hover {
  background: #0048ba;
  border: transparent;
}

.footer-button .dropdown {
  width: 48%;
}

.footer-button .dropdown .btn {
  width: 100%;
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.footer-button .dropdown .btn:hover,
.footer-button .dropdown .btn:focus {
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.footer-button .show > .btn-primary.dropdown-toggle,
.footer-button .btn-primary:not(:disabled):not(.disabled):active {
  background: transparent !important;
  color: #999 !important;
  border: 1px solid #ddd !important;
}

.footer-button .dropdown-item,
.footer-button a {
  padding: 5px 10px;
  color: #999;
  font-size: 14px;
  display: block;
}

.footer-button .dropdown-item:hover,
.footer-button a:hover {
  color: #0175ff;
} */

/********* Appointment Form ************/
/* 
.add-appointment-form h4 {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding-bottom: 20px;
}

.add-appointment-form textarea.form-control {
  height: 120px;
}

.add-more {
  cursor: pointer;
  font-weight: 600;
  color: #2577ff;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 4px;
  position: relative;
}
.add-appointment-form label.form-label {
  color: #000;
}
.search-card {
  width: 100%;
  display: flex;
  align-items: center;
}

.customer-detail {
  width: 100%;
}

.customer-detail-appoint {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.customer-detail-content {
  padding: 20px;
}

.dropdown-toggle::after {
  content: none;
}
.appointment-action.dropdown .btn {
  min-width: auto;
}
.appointment-action.dropdown .btn:hover,
.appointment-action.dropdown .btn:focus {
  color: #000 !important;
  background: transparent !important;
  border-color: transparent !important;
}

.search-container-user .btn.btn-primary {
  background: transparent;
  border: none;
  color: #000;
}

.search-card .form-control {
  width: 100%;
  position: relative;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
}

.search-card .btn.btn-primary {
  width: 30px;
  padding: 0;
  background: transparent;
  border: none;
  color: #999;
  position: absolute;
  right: 23px;
  top: 23px;
  font-size: 18px;
  min-width: auto;
}
.search-items {
  display: block;
  justify-content: flex-start;
  max-height: 200px;
  height: auto;
  overflow-y: auto;
}
.add-customer {
  justify-content: center;
  display: flex;
  display: flex;
  margin: 15px;
}
.search-popup .form-group {
  margin: 0;
}

.search-popup input.form-control {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  border-radius: 5px;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
}

.search-popup .search-user-list {
  margin-top: -1px;
}
.search-content {
  padding: 15px 12px;
  border: 1px solid #ddd;
  margin-top: -1px;
}
.search-box.form-group {
  display: flex;
  align-items: center;
  width: 100%;
  flex-flow: column;
}

.search-box.form-group .form-control {
  width: 100%;
  position: relative;
  padding: 4px 12px;
}

.search-box.form-group .btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  min-width: auto;
}
.add-customer-mob {
  display: none;
}

.pricing-option .select-box > i {
  display: none !important;
} */

/*********************************************** New Code Start Here **********************************/
.appointment-container {
  font-family: "Exo 2";
}
.search-container {
  background: #fff;
  border-radius: 5px;
  padding: 35px 25px;
  height: 100%;
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-flow: column;
}
.search-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.search-title span.plus-icon {
  display: inline-block;
  cursor: pointer;
  color: #0048ba;
  font-size: 18px;
}

.add-appointment .search-title h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #202021;
}

.no-customer {
  border: 1px dashed #ddd;
  padding: 20px 30px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.no-customer img {
  width: 70px;
  margin-bottom: 10px;
}

.no-customer p {
  margin: 0;
  color: #999;
}
.footer-btn {
  margin-top: auto;
  width: 100%;
}
.footer-btn .appointment-btn.btn-primary {
  color: #fff;
  /* width: 100%;
  min-width: 100%; */
}
.footer-btn .appointment-btn.btn-primary:hover,
.footer-btn .appointment-btn.btn-primary:focus {
  background-color: #1aa01d;
  border: #1aa01d;
  color: #fff;
}

.appointment-view {
  background: #fff;
  border-radius: 5px;
  padding: 0px 12px;
}
.appointment-date {
  position: relative;
  cursor: pointer;
}

.appointment-date h3 {
  font-size: 18px;
  font-family: "Exo 2";
  margin-bottom: 5px;
  font-weight: 600;
}

.appointment-date span {
  font-size: 20px;
  display: inline-block;
  color: #e8e7e7;
  margin-top: -5px;
}
.appointment-date:before {
  content: "";
  position: absolute;
  background-image: url("../../assets/home/arrowdown.png");
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  z-index: 9;
  cursor: pointer;
  width: 14px;
  height: 14px;
  opacity: 0.4;
}
span.sep {
  width: 100%;
  display: inline-block;
  position: relative;
}
span.sep:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ececec;
  top: 100%;
  left: 0;
}
.appointment-form {
  padding: 20px 0;
  padding-bottom: 0px;
}
.appointment-view .search-title {
  padding: 6px 0 6px;
  margin-bottom: 0;
}
.search-title.service-head {
  padding: 12px 0 6px;
}
.appointment-form label.form-label {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.appointment-form textarea.form-control {
  min-height: 90px;
  font-size: 14px;
  height: 100%;
  resize: vertical;
  box-shadow: none;
  overflow: hidden;
  border-radius: 0px !important;
}

/* Search Modal Start */
.search-modal.modal .modal-content {
  background: #f6f7fb;
}
.search-modal .modal-content {
  padding: 20px 10px;
  padding-top: 0px;
}

.search-modal .close:after,
.appointment-service-modal .close:after {
  top: 23px;
  right: 0;
}
.search-box.form-group {
  width: 100%;
  position: relative;
}

.search-box.form-group .form-control {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  font-weight: 400;
  padding: 12px 12px 12px 30px;
}
.search-box.form-group .form-control::placeholder {
  color: rgb(0, 0, 0, 0.4);
}

.search-box.form-group > i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #ced4da;
}

.search-modal .modal-header,
.appointment-service-modal .modal-header {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  font-family: "Exo 2";
  font-weight: 600;
}
.search-popup {
  background: #ffff;
  padding: 15px 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.search-user-list {
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
}
.search-items {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
  position: relative;
  /* box-shadow: 0 0 5px 0px rgb(49 49 49 / 10%); */
  border-radius: 5px;
  padding-top: 0px;
}
.search-items + .search-items {
  margin-top: 15px;
}
.search-items:before {
  content: "";
  position: absolute;
  background-image: url("../../assets/home/arrowdown.png");
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  right: 0px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  z-index: 9;
  cursor: pointer;
  width: 14px;
  height: 14px;
  opacity: 0.4;
  margin-top: -8px;
}
.search-items:last-child {
  border-bottom: none;
}
/* 
.user-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.search-content {
  width: 100%;
  font-size: 14px;
  font-family: "Exo 2";
}
.search-content h4 {
  font-size: 18px;
  font-family: "Exo 2";
  margin-bottom: 5px;
  font-weight: 600;
}

/* Service modal start here */
.appointment-service-modal .modal-content {
  padding: 0px 10px;
}
.appointment-service-modal .select-box > i {
  display: none;
}
.appointment-service-modal .select-box:after {
  content: "";
  position: absolute;
  background-image: url("../../assets/home/arrowdown.png");
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.4;
  pointer-events: none;
}
.service-form label.form-label {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.view-btn.service-submit {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.view-btn.service-submit .add-btn.btn-primary {
  background-color: #1aa01d !important;
}
.service-submit .btn-delete {
  margin-left: 0px !important;
}
.service-submit .add-btn {
  margin-right: 0px !important;
}
.service-submit.view-btn .btn {
  width: 49% !important;
}
.service-submit button {
  margin: 0px 0px;
  /* width: calc (50% - 10px); */
}
.service-form .form-group {
  margin-bottom: 15px;
}
.service-form .col i.fas.fa-sort-down {
  display: none;
}
.appointment-container .appointment-form label.form-label {
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

.appointment-container .search-title .content-dropdown img {
  height: 22px;
  width: 22px;
  opacity: 0.4;
  transform: rotate(-90deg);
  margin-right: -6px;
}
.appointment-container .search-title .content-dropdown {
  margin-right: 0px;
}
.appointment-view .client-profile span {
  color: #8d8d8d;
  font-size: 12px;
}
.appointment-container .search-container .client-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.appointment-container button.client-delete.btn.btn-primary {
  background: #f43c3c;
  border-color: #f43c3c;
  color: #fff;
  height: 26px;
  width: 26px;
  min-width: 26px;
  font-size: 12px;
  padding: 0px !important;
  border-radius: 50%;
  line-height: 11px;
  cursor: pointer;
}
.appointment-container button.client-delete.btn.btn-primary img {
  height: auto !important;
  width: 20px !important;
  margin-right: 0px !important;
}
.appointment-container .search-title .content-dropdown .dropdown-toggle::after {
  display: none;
}
.appointment-container .search-container .client-profile {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  width: 100%;
}
.appointment-container .search-container .client-profile p {
  color: #0070c0!important;
  font-size: 13px;
  margin-top: 5px;
}
.client-left-details h5.client-company-name{
  color: #7d7d7d!important;
}
/* .appointment-view .footer-btn button {
  display: none;
} */
.appointment-container
  .appointment-view
  .search-title
  .content-dropdown
  button {
  width: fit-content;
  padding: 0px;
  min-width: fit-content;
}
.appointment-container
  .search-container
  .search-title
  .content-dropdown
  button {
  width: fit-content;
  padding: 0px;
  min-width: fit-content;
}
.search-modal .search-user-list .search-content h4 {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
}
.search-modal .search-user-list .search-content p {
  color: #8d8d8d;
  font-size: 13px;
}
.search-box.form-group > img {
  position: absolute;
  margin-left: 10px;
  top: 18px;
  transform: translateY(-50%);
  cursor: pointer;
  height: 15px;
  opacity: 0.5;
}
.cross-img {
  right: 0px;
  height: 10px !important;
  position: relative;
}
.appointment-view .appointment-date:before {
  display: none;
}
.appointment-container .appointment-view .appointment-date img {
  /* height: 22px; */
  width: 22px;
  /* opacity: 0.4; */
  transform: rotate(-90deg);
  pointer-events: none;
  /* position: absolute;
  right: -6px; */
}
.time-calender .react-calendar__navigation button {
  background: #ffffff;
  color: #000;
}
.time-calender .react-calendar {
  width: 100%;
  border: 1px solid #e6e6e6 !important;
  font-family: "Exo 2";
}
.time-popup h6 {
  font-family: "Exo 2";
  /* margin-top: 40px; */
  font-size: 18px;
}
.select-time {
  margin-top: 20px;
}
.select-time .form-group label {
  font-size: 13px;
  font-weight: 600;
  color: black;
}
.select-time .form-control {
  height: 35px;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.apply-changes .btn-save {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  font-size: 14px;
  font-weight: 400;
}
.apply-changes {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.time-calender .react-calendar__tile--now,
.time-calender .react-calendar__tile--now :focus {
  background: transparent !important;
  color: #393890;
  border-radius: 0px;
  /* border: 1px solid #393890; */
  font-weight: 600;
  border-radius: 50%;
  position: relative;
}
.time-calender
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month::before {
  border: none !important;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade::before {
  border: none;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
  color: #0048ba !important;
}
.time-calender
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month::before {
  border: none !important;
}
.time-calender
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
  color: #0048ba !important;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year::before {
  border: none;
  color: #0048ba !important;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
  color: #0048ba !important;
}
.time-calender
  button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
  background-color: #393890;
  color: white;
}
.time-calender .time-calender .react-calendar__tile--active:hover abbr {
  color: white !important;
}
.time-calender .react-calendar__tile--now::before {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  border: 1px solid #393890;
  background-color: transparent;
  z-index: 9999;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1px;
}
.time-calender .react-calendar__tile--active::before {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: #393890;
  z-index: 9999;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.time-popup .react-calendar__tile:enabled:hover,
.time-popup .react-calendar__tile:enabled:focus {
  background-color: transparent !important;
}
/* .time-calender .react-calendar__tile--now::before, .time-calender .react-calendar__tile--now :focus{
  content: "";
    position: absolute;
    height: 35px;
    width: 35px;
    background-color: #393890;
    z-index: 9999;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}  */
.time-calender
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day:focus
  abbr {
  color: white !important;
}
/* .time-calender .react-calendar__tile--active {
  background: #0048ba !important;
  color: white;
  border-radius: 50%;
} */

.time-calender .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.appointment-date {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.remove-service h6 {
  color: #dc1d1d;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  text-decoration: underline;
}

.appointment-container .main-titles h3 {
  font-size: 24px;
}
.appointment-total span {
  color: #8d8d8d;
  font-weight: 500;
  margin-left: 5px;
}
.duration-color {
  color: #73e4fc !important;
}
/* .appointment-time select option {
  text-transform: lowercase;
} */
.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  background-color: #ededed;
  border-radius: 50%;
  /* padding: 5px; */
  height: 30px;
  width: 30px;
  padding: 0px !important;
  color: #f4f4f4;
  line-height: 0px !important;
  margin: 8px 5px;
  margin-bottom: 0px !important;
  min-width: 30px;
  position: relative;
  margin-bottom: 0px !important;
}
.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  background-color: #ededed;
  border-radius: 50%;
  /* padding: 5px; */
  height: 30px;
  width: 30px;
  padding: 0px !important;
  color: #f4f4f4;
  line-height: 0px !important;
  margin: 8px 2px;
  margin-bottom: 0px !important;
  min-width: 30px;
  position: relative;
}
.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  background-color: #ededed;
  border-radius: 50%;
  /* padding: 5px; */
  height: 30px;
  width: 30px;
  padding: 0px !important;
  color: #f4f4f4;
  line-height: 0px !important;
  margin: 8px 2px;
  margin-bottom: 0px !important;
  min-width: 30px;
  position: relative;
}
.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  background-color: #ededed;
  border-radius: 50%;
  /* padding: 5px; */
  height: 30px;
  width: 30px;
  padding: 0px !important;
  color: #f4f4f4;
  line-height: 0px !important;
  margin: 8px 5px;
  min-width: 30px;
  margin-bottom: 0px !important;
  position: relative;
}
.time-popup .select-box:after {
  background-size: 18px;
  width: 20px;
  height: 20px;
}

.time-popup .select-box:after {
  content: "";
  position: absolute;
  background-image: url("../../assets/home/arrowdown.png");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: 9; */
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.4;
  pointer-events: none;
}
.appointment-total h6 {
  margin-bottom: 0px;
  line-height: 18px;
  font-size: 16px;
}
.duration-time {
  font-weight: 400;
  color: #8d8d8d;
  font-size: 12px;
}

/* .search-title.service-head {
  margin-bottom: 25px;
} */
.auto-complete {
  position: absolute;
  width: 100%;
  max-height: 200px;
  height: auto;
  top: 38px;
  background: #fff;
  left: 0;
  border-radius: 5px;
  z-index: 9999;
  border: 1px solid #eaebec;
  overflow: auto;
  padding: 5px 0px;
}

.auto-complete li {
  padding: 8px;
  margin: 5px;
  line-height: 1.2;
  color: #a5a5a5;
  cursor: pointer;
}
.service-form .form-group .form-control {
  height: 35px;
}
.service-form .form-radio.form-group .form-check:last-child {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.service-form .form-radio.form-group .form-check input {
  height: 15px !important;
  width: 15px !important;
}
.service-form .form-radio.form-group .form-check .form-check-label {
  margin-top: 3px;
}
.service-form {
  position: relative;
}
.appointment-service-modal .service-popup p {
  margin-bottom: 0px;
}

.appointment-container .client-profile .dropdown button {
  background-color: transparent;
  border: none;
  padding: 0px !important;
  margin: 0px !important;
  width: 0px;
  min-width: auto;
  min-width: 10px;
}
.appointment-container .search-container .client-profile img {
  width: 24px;
  height: auto;
}
.appointment-container .client-profile .action {
  display: flex;
  align-items: center;
}
.appointment-container .client-profile .action img {
  width: 19px !important;
}
.service-form .text-muted {
  margin-left: 4px;
}
.service-form input {
  border: 1px solid #ced4da;
  width: 100%;
  border-radius: 5px;
  height: 35px;
  padding: 6px 30px 6px 10px;
  color: #a5a5a5;
}
.sorting-services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 20%; */
}
.sorting-sub-services {
  display: flex;
  align-items: center;
}
.sorting-services .sorting img {
  margin-top: 0px !important;
}
/* .search-title .sorting.up-down.active-sort {
    position: relative;
    z-index: 99999;
}

.client-profile {
    width: calc(100% - 72px);
    padding: 20px 0 10px;
}

.sorting-sub-services {
    width: 72px;
    padding: 20px 0 10px;
} */
.appointment-container .client-profile .action img {
  border-radius: 0px !important;
}
/* .appointment-service-modal  .service-submit.view-btn .btn {
  width: auto !important;
  min-width: 120px !important;
} */
.client-profile .dropdown-menu.show {
  z-index: 1;
}
.client-profile .dropdown button img {
  margin-right: 0px !important;
}
.service-form input::placeholder {
  color: #a5a5a5;
}
.service-form input {
  -webkit-appearance: none;
}
.client-profile .dropdown-toggle::after {
  display: none !important;
}
.appointment-container .main-titles h3 {
  font-size: 24px;
  padding-bottom: 8px;
}

.appointment-container .add-appointment .search-container {
  background: #fff;
  border-radius: 5px;
  padding: 12px 12px;
  margin-bottom: 10px;
}
.appointment-container .sorting-sub-services {
  justify-content: flex-end;
}
.sorting-sub-services.appointment-sort .content-dropdown {
  margin-left: inherit !important;
}
.sorting-sub-services.appointment-sort .up-down img:last-child {
  margin-right: 10px !important;
}

.appointment-container .search-title .client-profile h3 {
  color: #8d8d8d;
  font-weight: 700;
  margin-bottom: 0px;
}
.search-modal .modal-header {
  padding-bottom: 0px !important;
  padding: 8px;
}
.appointment-service-modal .modal-header {
  padding: 8px;
  padding-bottom: 0px !important;
}
.appointment-service-modal .service-popup {
  background-color: white;
  background: #fff;
  padding: 15px 12px;
  border-radius: 5px;
}
.client-profile p {
  line-height: 15px;
  margin-bottom: 0px;
}

.client-profile h6 {
  font-size: 13px;
  color: #8d8d8d;
  font-weight: 400;
}
.appointment-service-modal input {
  font-weight: unset;
}

.addBorder {
  border: 2px red solid !important;
}
input.addBorder::placeholder {
  color: #ff0503 !important;
}

.time-picker-modal .modal-content {
  height: auto;
  min-height: auto;
  margin: 0px auto;
  padding: 0px;
  width: fit-content;
}
.time-picker-modal {
  background-color: transparent;
}
.time-picker-modal .modal-body {
  padding: 0px !important;
}
.time-picker-modal .modal-header {
  display: none;
}
.time-keeper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-keeper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-picker-modal button.btn.btn-primary {
  z-index: 999;
  background-color: #1aa01d;
  color: white;
  font-size: 14px;
  border: none;
  border-top: 1px solid #d9d5d5;
  border-radius: 9px;
  padding: 10px 20px;
  line-height: 20px;
  height: 40px;
  min-width: 100px;
}

.time-keeper-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  /* margin-top: -10px; */
}
.time-picker-modal .modal-content {
  background-color: transparent !important;
}
.service-form .form-group button.btn.btn-primary {
  padding: 6px 10px;
  line-height: 1.2;
  color: #a5a5a5;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  font-size: 13px;
  height: 35px;
  /* border: 1px solid red; */
  font-weight: 400;
  border: 1px solid #ced4da;
}

.appointment-container .appointment-view .search-title .client-profile h3 {
  line-height: 15px;
}
.appointment-container .appointment-form label.form-label {
  margin-bottom: -2px;
  margin-left: 2px;
}
/* .search-title.service-head {
  margin-bottom: 15px;
} */
.appointment-service-modal
  .service-popup
  .search-box.form-group
  input::placeholder {
  color: red !important;
}
.view-btn.time-btn {
  margin-top: 35px;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button::after {
  content: "";
  position: absolute;
  height: 28px;
  width: 27px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../assets/home/left1.png");
  margin-left: 0px;
  margin-top: 0px;
  background-size: inherit;
}
.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button::after {
  content: "";
  position: absolute;
  height: 28px;
  width: 27px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../assets/home/left2.png");
  margin-left: 1px;
  margin-top: 1px;
  background-size: inherit;
}
.time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button::after {
  content: "";
  position: absolute;
  height: 28px;
  width: 27px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../assets/home/right1.png");
  margin-left: 0px;
  margin-top: 1px;
  background-size: inherit;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button::after {
  content: "";
  position: absolute;
  height: 28px;
  width: 27px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../assets/home/right2.png");
  margin-left: -2px;
  margin-top: 1px;
  background-size: inherit;
}
/* 
.time-calender button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  max-width: 65px !important;
  height: 65px !important;
  line-height: 15px !important;
  width: 65px !important;
  padding: 0px !important;
  margin: 0px 15px;
}
.time-calender button.react-calendar__tile.react-calendar__year-view__months__month{

  height: 65px !important;
  line-height: 15px !important;
  width: 65px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.time-calender .react-calendar__year-view__months{
  display: flex;
  justify-content: center;
  align-items: center;

} */
.time-calender .react-calendar__navigation button {
  background-color: transparent;
}
.time-calender .react-calendar__navigation button:enabled:hover,
.time-calender .react-calendar__navigation button:enabled:focus {
  background-color: #f4f4f4 !important;
}
/* .time-calender .react-calendar__tile--active abbr {
  background-color: #0048ba;
  height: 36px;
  border-radius: 50%;
  width: 20px;
  padding: 10px 14px;
  color: white;
} */

/* .time-calender .react-calendar__tile--active:enabled:hover, .time-calender .react-calendar__tile--active:enabled:focus{
  background-color: transparent !important;
} */
.time-calender .react-calendar__tile--active:enabled:hover abbr,
.time-calender .react-calendar__tile--active:enabled:focus abbr {
  color: black !important;
}
.time-calender .react-calendar__tile--active abbr {
  color: white;
  color: white;
  position: relative;
  z-index: 99999999;
}
/* .time-calender .react-calendar__tile--hasActive{
  background-color: transparent;
} */
.time-calender
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #393890;
  z-index: 9999;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1px;
}

.time-calender .react-calendar__tile--active {
  background: transparent !important;
  color: white !important;
  border-radius: 50%;
  position: relative;
}
.time-calender .react-calendar__tile--hasActive {
  background: transparent;
}
.time-calender .react-calendar__tile--active::before {
  content: "";
  position: absolute;
  height: 24px;
    width: 24px;
  background-color: #393890;
  z-index: 9999;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.time-calender
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  position: relative;
  border: none;
}
.time-calender
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day
  abbr {
  position: relative;
  z-index: 99999;
}
.time-calender .react-calendar__month-view__weekdays__weekday:nth-child(7),
.react-calendar__month-view__weekdays__weekday:nth-child(6) {
  color: #757575 !important;
}
.time-calender
  span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-weight: 600;
  font-size: 18px;
  /* max-width: 50px; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.time-calender button.react-calendar__navigation__label {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

/* duration picker */
/* .duration-picker {
  background-color: white;
  height: auto;
  margin-top: 50px;
} */
.circle {
  height: 335px;
  width: 335px;
  border-radius: 50%;
  border: 1px solid red;
  margin: 0px auto;
  position: relative;
}
.duration-picker .modal-content {
  border: none !important;
}
/* .timepicker .face {
  width: 100%;
  height: 100%;
  background-color: white;
  border: none;
  border-radius: 50%;
  position: relative;
}
.timepicker .bubble.selected {
  color: #D6F0F9;
  background-color: #33B4E4;
}

.timepicker .time {
  background-color: #F2F2F2;
}
.timepicker .bubble {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.timepicker {
  background-color: #F2F2F2;
  position: absolute;
  color: #8C8C8C;
  border: 1px solid #B5B5B5;
  -webkit-box-shadow: 2px 2px 4px rgb(0 0 0 / 33%);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 4px rgb(0 0 0 / 33%);
  z-index: 2147483647;
}
.timepicker .face {
  width: 100%;
  height: 100%;
  background-color: white;
  border: none;
  border-radius: 50%;
  position: relative;
}
.hour12{
  margin-left: 85px;
  margin-top: 7px;
}
.hour1{
  margin-left: 124px;
  margin-top: 17.45px;
}
.hour2{
  margin-left: 152.55px;
  margin-top: 46px;
}
.hour3{
  margin-left: 163px;
  margin-top: 85px;
}

.hour4{
  margin-left: 152.55px;
  margin-top: 124px;
}
.hour5{
  margin-left: 124px;
    margin-top: 152.55px;
}
.hour6{
  margin-left: 85px;
  margin-top: 163px;
}
.hour7{
  margin-left: 46px;
  margin-top: 152.55px;
}
.hour8{
  margin-left: 17.45px;
  margin-top: 124px;
}
.hour9{
  margin-left: 7px;
  margin-top: 85px;
}
.hour10{
    margin-left: 17.45px;
    margin-top: 46px;
}
.hour11{
  margin-left: 46px;
  margin-top: 17.45px;
}
.timepicker .hand.hour {
  margin: -23% 0 0 -2px;
  padding: 23% 2px 0;
  border-radius: 2px 2px 0 0;
}

.timepicker .hand {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  pointer-events: none;
  background-color: #8C8C8C;
}
.timepicker .face:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  background-color: #33B4E4;
  border-radius: 50%;
  content: "";
  display: block;
}
.timepicker .clock {
  width: 350px;
  height: 350px;
  position: relative;
  padding: 5px;
} */

.face {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 325px;
  height: 325px;
  background: #eee;
  background-image: linear-gradient(to bottom right, #eee, #f5f5f5);
  border-radius: 50%;
  padding: 0px;
  border: 0px solid #d9d9d9;
  margin: 0px auto;
  /* box-shadow: 20px 20px 30px -10px rgb(0 0 0 / 40%), inset 10px 10px 20px rgb(0 0 0 / 20%); */
}
.time-bubbles {
  position: relative;
  padding: 15px;
}
.hour:nth-child(2) {
  transform: rotate(25deg);
}

.hour {
  position: absolute;
  height: 150px;
  transform-origin: 0 100%;
  font-size: 28px;
}
.hour:nth-child(3) {
  transform: rotate(55deg);
}
.hour:nth-child(4) {
  transform: rotate(85deg);
}
.hour:nth-child(5) {
  transform: rotate(115deg);
}
.hour:nth-child(6) {
  transform: rotate(145deg);
}
.hour:nth-child(7) {
  transform: rotate(175deg);
}

.hour:nth-child(8) {
  transform: rotate(205deg);
}
.hour:nth-child(9) {
  transform: rotate(235deg);
}

.hour:nth-child(10) {
  transform: rotate(265deg);
}

.hour:nth-child(11) {
  transform: rotate(295deg);
}
.hour:nth-child(12) {
  transform: rotate(325deg);
}
.hour:nth-child(1) {
  transform: rotate(355deg);
}
.hour:nth-child(2) span {
  display: block;
  transform: rotate(-25deg);
}
.hour:nth-child(3) span {
  display: block;
  transform: rotate(-55deg);
}
.hour:nth-child(4) span {
  display: block;
  transform: rotate(-85deg);
}
.hour:nth-child(5) span {
  display: block;
  transform: rotate(-115deg);
}
.hour:nth-child(6) span {
  display: block;
  transform: rotate(-145deg);
}
.hour:nth-child(7) span {
  display: block;
  transform: rotate(-175deg);
}
.hour:nth-child(8) span {
  display: block;
  transform: rotate(-205deg);
}
.hour:nth-child(9) span {
  display: block;
  transform: rotate(-235deg);
}
.hour:nth-child(10) span {
  display: block;
  transform: rotate(-265deg);
}
.hour:nth-child(11) span {
  display: block;
  transform: rotate(-295deg);
}
.hour:nth-child(12) span {
  display: block;
  transform: rotate(-325deg);
}
.hour:nth-child(1) span {
  display: block;
  transform: rotate(-355deg);
}

.face.face-minute {
  width: 200px;
  width: 220px;
  height: 220px;
}

.face.face-minute .hour {
  position: absolute;
  height: 100px;
  transform-origin: 0 100%;
  font-size: 22px;
}
.face.face-minute .time-bubbles {
  position: relative;
  padding: 10px;
}
.face.face-minute {
  position: absolute;
  top: 115px;
  margin-left: 62px;
}

.time-calender
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day:hover
  abbr {
  color: white !important;
}
.service-form .halfright input[type="text"]:focus {
  outline: none;
}
.time-keeper-popup {
  border-radius: 12px !important;
  background-color: white;
  padding: 20px 30px;
}
.time-keeper-popup .react-timekeeper__top-bar {
  background-color: transparent;
}
.time-keeper-popup .react-timekeeper {
  box-shadow: none;
}
.time-keeper-popup .react-timekeeper__clock-wrapper {
  background-color: transparent;
  padding: 10px 0 14px;
}
.time-keeper-popup .react-timekeeper__clock {
  background: #f5f5f5;
  width: 220px;
  height: 220px;
}
/* .time-keeper-popup .css-1aty463-TopBar {
  font-size: 50px;
  font-family: 'Exo 2';
  font-weight: 600;
  color: #242424;
} */
.time-keeper-popup span.react-timekeeper__tb-colon {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
  margin: 0px 8px;
}
.time-keeper-popup span.react-timekeeper__tb-minute {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
}
.time-keeper-popup
  button.react-timekeeper-button-reset.react-timekeeper__tb-meridiem {
  font-size: 12px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
  margin-left: 5px;
  text-transform: lowercase;
}
.time-keeper-popup .react-timekeeper__top-bar {
  padding: 0px 16px;
  /* padding-top: 15px; */
}
.time-keeper-popup circle.react-timekeeper__hand-circle-outer {
  fill: #1550b9;
}
.time-keeper-popup circle.react-timekeeper__hand-circle-center {
  fill: #1550b9;
  r: 5px;
}
.time-keeper-popup line.react-timekeeper__clock-hand {
  stroke: #1550b9 !important;
}
.time-keeper-popup
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle,
.time-keeper-popup
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle {
  background: #f5f5f5;
  color: #636363;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  /* padding: 0px 15px; */
  /* height: auto; */
  /* width: auto; */
  font-family: "Exo 2";
  height: 25px;
  width: 45px;
  line-height: 25px;
}

/* .time-keeper-popup .css-1r6tdpo-Meridiems {
  padding: 0 0px;
  margin-top: 5px;
} */
.time-keeper-popup .react-timekeeper__clock-wrapper div:last-child {
  padding: 0px !important;
  margin-top: -5px;
  /* display: none; */
}
.time-keeper-popup .react-timekeeper__clock-hours span {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  font-family: "Exo 2";
  font-weight: 600;
}
/* .time-keeper-popup svg.react-timekeeper__clock-hand {
  height: 210px;
  width: 210px;
} */
/* .time-keeper-popup circle.react-timekeeper__hand-circle-outer.css-16j6ljo-ClockHand {
  cx: 107;
  cy: 20;
}
.time-keeper-popup circle.react-timekeeper__hand-circle-center.css-1jj6dwi-ClockHand {
  cx: 105;
} */
/* .time-keeper-popup span.react-timekeeper__tb-hour.react-timekeeper__tb-hour--active {
  color: #1550b9 !important;
} */
.time-keeper-popup circle.react-timekeeper__hand-circle-outer {
  cx: 110;
  r: 14;
  cy: 23;
}

.time-keeper-popup .react-timekeeper__tb-minute-wrapper {
  margin-left: 12px;
}
.time-keeper-popup
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle {
  background: #f5f5f5;
  color: #636363;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  /* padding: 0px 15px; */
  /* height: auto; */
  /* width: auto; */
  font-family: "Exo 2";
  height: 25px;
  width: 45px;
  line-height: 25px;
  text-transform: lowercase;
}
.time-keeper-popup
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle.react-timekeeper__meridiem--active {
  background: #f5f5f5;
  color: #242424;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  font-family: "Exo 2";
}
/* .time-keeper-popup  span.css-1ddlhy:focus {
  transition: all ease .1s;
} */
/* 
.time-keeper-popup  span.css-1ddlhy:focus {
  color: white !important;
  z-index: 99999999999 !important;
  filter: grayscale(1);
  outline: none;
  box-shadow: none;
} */

.time-keeper-popup
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle.react-timekeeper__meridiem--active {
  background-color: #1550b9 !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 15px;
}
.time-keeper .react-timekeeper__time-dropdown {
  display: none;
}
/* .time-keeper-popup button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle.react-timekeeper__meridiem--active{
  background-color: #bebebe !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 15px;
} */
.time-keeper-popup
  span.react-timekeeper__tb-minute.react-timekeeper__tb-minute--active {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #0096ee !important;
}
.time-keeper-popup .react-timekeeper__tb-minute-wrapper span {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
}
.time-keeper-popup
  ul.react-timekeeper__dropdown-numbers
  li.react-timekeeper__dropdown-number {
  color: black !important;
}
.time-keeper-popup
  ul.react-timekeeper__dropdown-numbers
  li.react-timekeeper__dropdown-number.react-timekeeper__dropdown-number--active {
  background-color: #1550b9 !important;
  color: white !important;
}
.time-keeper-popup
  .react-timekeeper__clock
  .react-timekeeper__clock-minutes
  span {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  font-family: "Exo 2";
  font-weight: 600;
}
.time-keeper-popup
  .react-timekeeper__top-bar
  span.react-timekeeper__tb-hour.react-timekeeper__tb-hour--active {
  color: #0096ee;
}

/* duration popup css */
.duration-popup .smart-header.smart-unselectable {
  background-color: transparent;
  color: black !important;
}
.duration-popup .smart-element {
  border: none;
}
.duration-popup .smart-hour-container.smart-selected,
.duration-popup .smart-hour-container,
.duration-popup .smart-time-picker .smart-minute-container {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
}
.duration-popup
  .smart-time-picker
  .smart-hour-minute-container
  div:nth-child(2) {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
  opacity: 1;
}
.duration-popup .smart-svg-picker {
  height: 216px !important;
  width: 216px !important;
  background-color: #f5f5f5 !important;
  transform: scale(0.92);
}
.duration-popup .smart-time-picker .smart-label {
  /* fill: #000000;
  color: #000000; */
  font-weight: 500;
  font-size: 13px;
  font-family: "Exo 2";
  font-weight: 600;
}

.duration-popup .smart-time-picker .smart-am-container,
.duration-popup .smart-time-picker .smart-hour-container,
.duration-popup .smart-time-picker .smart-minute-container,
.duration-popup .smart-time-picker .smart-pm-container {
  opacity: 1;
}
.duration-popup .smart-minute-container.smart-selected,
.duration-popup .smart-hour-container.smart-selected {
  color: #1550b9 !important;
}
.duration-popup .smart-time-picker .smart-needle,
.smart-time-picker .smart-needle-central-circle {
  fill: rgb(21 80 185);
}

.select-box select:focus {
  outline: none;
  /* box-shadow: none; */
}

.custom-select-time h6 {
  font-size: 50px;
  font-family: "Exo 2";
  font-weight: 600;
  color: #242424;
}

.custom-select-time h6 span {
  font-size: 18px;
  font-family: "Exo 2";
  font-weight: 600;
}
.custom-select-time {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -85px;
  padding-left: 20px;
}
h6.custom-hour {
  margin-right: 8px;
}

h6.custom-min {
  margin-left: 8px;
}
.time-keeper-popup .smart-time-picker .smart-hour-minute-container {
  font-size: 50px;
  display: none !important;
}
/* .time-keeper-popup .smart-header.smart-unselectable {
  display: none;
} */

.time-active {
  color: #0096ee !important;
}

/* 
css for resize timekeeper */
/* .time-keeper-popup svg.react-timekeeper__clock-hand {
  height: 208px !important;
  width: 208px !important;
}
.time-keeper-popup .react-timekeeper__clock-hours {
  margin-left: -8px;
  margin-top: -6px;
}
.time-keeper-popup .react-timekeeper__clock {
  background: #f5f5f5;
  width: 205px;
  height: 205px;
}
.time-keeper-popup circle.react-timekeeper__hand-circle-outer {
  cx: 108;
  r: 13;
  cy: 15;
}
.time-keeper-popup .react-timekeeper__clock-hours span{
  line-height: 29px;
}
.time-keeper-popup .react-timekeeper__clock-minutes span{
  line-height: 29px;
}
.time-keeper-popup .react-timekeeper__clock-minutes  {
  margin-left: -8px;

} */

.time-keeper-popup .react-timekeeper__clock-hours,
.time-keeper-popup .react-timekeeper__clock-minutes {
  margin-left: -9px;
  margin-top: -9px;
}
.time-keeper-popup .react-timekeeper__clock {
  background: #f5f5f5;
  width: 202px;
  height: 202px;
}
.time-keeper-popup svg.react-timekeeper__clock-hand {
  margin-top: -9px;
  margin-left: -9px;
}

.remove-text {
  position: absolute;
  margin-left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  height: 15px;
  opacity: 0.5;
  right: 0px;
  top: 18px;
  width: 30px;
  text-align: right;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-keeper-popup .react-timekeeper__clock-hours span {
  font-size: 13px;
}

.time-keeper-popup svg.react-timekeeper__clock-hand,
.time-keeper-popup .react-timekeeper__clock-hours,
.time-keeper-popup .react-timekeeper__clock-minutes {
  margin-top: 0;
  margin-left: 0;
}

.time-keeper-popup .react-timekeeper__clock {
  width: 220px;
  height: 220px;
  transform: scale(0.9);
}
.time-keeper-popup .time-keeper-btn {
  margin-top: 11px;
}
.time-keeper-popup.duration-popup .time-keeper-btn {
  margin-top: 0px;
}
/* .duration-picker .smart-time-picker{
  min-width: 260px;
} */
.time-keeper-popup.duration-popup {
  padding: 20px 30px;
}

/* .zero-class {
  -moz-transform:  translateX(2px);
    -ms-transform:  translateX(2px);
    -o-transform: translateX(2px);
    -webkit-transform: translateX(2px);
    transform:  translateX(2px);
 
  opacity: 1;
}
.zero-class-min {
  -moz-transform:  translateX(4px);
  -ms-transform:  translateX(4px);
  -o-transform:  translateX(4px);
  -webkit-transform:  translateX(4px);
  transform:   translateX(4px);

  opacity: 1;
} */
/* .smart-container svg text.zero-class {
  text-anchor: start !important;
  display: block!important;
  transform: translate(2px,0px)!important;
    -ms-transform: translate(2px,0px)!important;
    -webkit-transform: translate(2px,0px)!important;
    -o-transform: translate(2px,0px)!important;
    -moz-transform: translate(2px,0px)!important;
    transform: scale(1.03) !important;
  }
 
  .smart-container svg text.zero-class-min {
    text-anchor: start !important;
    display: block!important;
    transform: translate(4px,0px)!important;
    -ms-transform: translate(4px,0px)!important;
    -webkit-transform: translate(4px,0px)!important;
    -o-transform: translate(4px,0px)!important;
    -moz-transform: translate(4px,0px) !important;
    transform: scale(1.04) !important;
      }

      .other-class {
        text-anchor: start !important;
        display: block!important;
        transform:translateX(2px) !important;
        -ms-transform:translateX(2px) !important;
        -webkit-transform:translateX(2px) !important;
        -o-transform:translateX(2px) !important;
        -moz-transform: translateX(2px) !important;
        transform: scale(1.02) !important;
        
      } */

.span-text.form-group {
  position: relative;
}

.span-text.form-group span.input-text-right {
  position: absolute;
  right: 5px;
  top: 35px;
  font-style: italic;
  text-transform: lowercase;
}
span.input-text-right.text-red {
  color: red;
}

.span-text.form-group input {
  color: #969494;
  -webkit-text-fill-color: #969494;
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
}
.span-text.form-group .input-text-right {
  color: #969494;
  -webkit-text-fill-color: #969494;
}
.p-class span.input-text-right {
  right: 7px;
}
.p-class {
  position: relative;
}
.p-class span.input-text-right {
  top: 6px !important;
}
.time-calender .react-calendar__navigation {
  margin-bottom: 0px;
}
.time-calender .react-calendar__month-view__days .react-calendar__tile {
  padding: 0.5em 0.5em;
  font-size: 12px;
  font-weight: 600;
}
.time-calender .react-calendar__month-view__days {
  padding: 10px 10px 6px;
}

.time-calender
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #757575;
}

.not-repeat .checkbox label {
  cursor: pointer;
}

.not-repeat .checkbox.switch label {
  padding-left: 0;
}

/* hide the default checkbox */
.not-repeat input[type="checkbox"].switch-control {
  position: absolute;
  margin-left: -9999px;
}

.not-repeat input[type="checkbox"].switch-control ~ .switch-label {
  position: relative;
  display: inline-block;
  min-height: 20px; /* required in case the label is empty */
  padding-left: 60px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.not-repeat input[type="checkbox"].switch-control ~ .switch-label:before {
  content: "Off";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #ddd;

  color: #333;
  font-size: 10px;
  text-indent: 25px;
  text-transform: uppercase;
  text-shadow: 0 1px 0px rgba(255, 255, 255, 0.8);

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.not-repeat
  .switch-alternative
  input[type="checkbox"].switch-control
  ~ .switch-label:before {
  content: "\2716"; /* "x" character */
  text-indent: 30px;
}

.not-repeat input[type="checkbox"].switch-control ~ .switch-label:after {
  content: "-";
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 3px;
  width: 20px;
  height: 15px;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: #ccc;
  font-weight: 900;
  line-height: 1;
  text-align: center;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* checked state */
.not-repeat
  input[type="checkbox"].switch-control:checked
  ~ .switch-label:before {
  content: "ON";
  background-color: #339900;
  color: #fff;
  text-indent: 3px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
}

.not-repeat
  input[type="checkbox"].switch-control:checked
  ~ .switch-label:after {
  left: 28px;
}

/* blue switch variation */
.not-repeat
  .switch-alternative
  input[type="checkbox"].switch-control:checked
  ~ .switch-label:before {
  content: "\2714"; /* "✔" character */
  text-indent: 10px;
}

.not-repeat
  .switch-alternative
  input[type="checkbox"].switch-control:checked
  ~ .switch-label:before {
  background-color: #3399cc;
}

/* .step-switch label.switch {
  margin-left: 10px;
  margin-bottom: 0;
}

.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 80px;
  height: 35px;
  padding: 3px;
  border-radius: 18px;
  cursor: pointer;
  box-sizing: content-box;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
}


.step-switch span {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 14px;
  text-transform: uppercase;
  background: #3597b2;
  border-radius: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #fff;
}

.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  transition: inherit;
  box-sizing: content-box;
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #ffffff;
  opacity: 0;
}

.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  transition: inherit;
  box-sizing: content-box;
}
.step-switch span {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
.switch-handle {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 100%;
}
 */
.time-popup .shift-container {
  background: #fff;
  border-radius: 5px;
  padding: 20px 20px 20px !important;
}
.not-repeat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}
.select-time h6 {
  margin-top: 0px;
}
.not-repeat .switch {
  margin-right: 0;
}
.add-apppt-time .time-keeper {
  display: grid;
  justify-content: center;
}

.add-apppt-time .react-timekeeper__top-bar {
  display: none;
}
.add-apppt-time .time-keepr-repeat {
  text-align: center;
  min-height: 54px;
  margin-bottom: 0px;
}

.add-apppt-time .time-keepr-repeat h6 {
  font-size: 20px;
  color: #2e6ad6;
  margin-bottom: 0px;
  margin-top: 0px;
}

.add-apppt-time .time-keepr-repeat h4 {
  font-size: 25px;
  color: #0096ee;
  margin-bottom: 0px;
}
.add-apppt-time .set-btn {
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.add-apppt-time .set-btn button,
.add-apppt-time .set-btn button:focus,
.add-apppt-time .set-btn button:hover,
.add-apppt-time .btn-primary:not(:disabled):not(.disabled):active {
  padding: 5px 10px !important;
  min-width: auto !important;
  font-size: 12px !important;
  background-color: #d7d7d7 !important;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
  color: #7b7a7a !important;
  border: none !important;
  height: auto !important;
  border-radius: 5px !important;
  font-weight: 400;
}
.add-apppt-time .react-timekeeper__clock-wrapper {
  background-color: transparent;
  padding: 0px 0 14px;
}
.add-apppt-time .time-keeper-btn button {
  background-color: #1aa01d;
  color: white;
  border-radius: 10px;
  font-weight: 600 !important;
  border-radius: 10px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 38px !important;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 10px;
}
.add-apppt-time .time-keeper-btn {
  margin-top: 30px;
}

.custom-switch .custom-control-label::before {
  left: -45px;
  width: 50px;
  pointer-events: all;
  border-radius: 5px;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 22px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-switch .custom-control-label::after {
  width: calc(30px - 4px);
  height: calc(25px - 4px);
  border-radius: 5px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1aa01d;
  background-color: #1aa01d;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(25px);
  transform: translateX(25px);
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 0px);
  left: calc(-50px + 5px);
  width: calc(25px - 0px);
  height: calc(22px - 0px);
  background-color: #adb5bd;
  border: 1px solid #adb5bd;
}
.custom-control-input:checked ~ .custom-control-label::before:focus {
  outline: none;
  box-shadow: none;
}
.time-calender.new-time {
  margin-top: 20px;
}
.time-popup .select-box button.btn.btn-primary {
  -webkit-appearance: none;
  appearance: none;
  padding: 6px 40px 6px 10px;
  line-height: 1.2;
  color: #a5a5a5;
  background: transparent;
  z-index: 9;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  border: 1px solid #e0e0e0;
  height: 35px;
}

.time-popup .new-time-btn {
  text-align: center;
  margin-top: 20px;
}

.time-popup .new-time-btn button {
  height: 38px;
  line-height: normal;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 10px;
}
.searxh-popup .not-repeat h5 {
  margin-bottom: 0px;
}
.search-popup .not-repeat h5 {
  margin-bottom: 0px;
  font-size: 17px;
}

.add-apppt-time .smart-header.smart-unselectable {
  display: none;
}
.add-apppt-time .smart-svg-picker {
  transform: scale(1.04);
  background-color: #f5f5f5 !important;
  margin: 15px 0px;
  height: 172px;
  width: 172px;
}
.add-apppt-time .smart-time-picker {
  border: none;
}
.add-apppt-time .smart-time-picker {
  height: auto;
}
.add-apppt-time {
  border-radius: 12px !important;
  background-color: white;
  padding: 20px 35px;
}
.add-apppt-time .set-btn button.time-active-btn.btn.btn-primary {
  background-color: #a4a4a4 !important;
  color: white !important;
}
.add-apppt-time text.smart-label {
  font-weight: 500;
  font-size: 12px;
  font-family: "Exo 2";
  font-weight: 600;
}

.add-apppt-time circle.smart-needle-central-circle:nth-child(4) {
  r: 13;
}

.search-popup.shift-container.shift-bottom {
  padding: 20px 15px !important;
}
.time-calender .react-calendar__navigation button:enabled:focus,
.time-calender .react-calendar__navigation button:enabled:hover {
  background-color: #f4f4f4 !important;
}

.search-popup .form-group input::placeholder {
  color: #a5a5a5;
}
.appointment-type-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.appointment-type-one .open-date.client-notes {
  width: 30px;
  display: flex;
  justify-content: flex-end;
}
.appointment-type-one .team-member h6 {
  margin-bottom: 0px;
  color: #33b8f7;
  font-size: 14px;
}
.appointment-type-one .team-member p {
  font-style: italic;
  line-height: 12px;
  color: #31bdff;
  font-size: 12px;
}
.appointyment-type {
  width: 100%;
  background-color: #eff3ff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.appointyment-type h5 {
  margin-bottom: 0px;
  font-size: 16px;
  color: #31bdff;
}

p.group-time .require-icon {
  color: red !important;
  font-weight: 900;
  font-size: 20px;
  margin-right: 5px;
}
p.group-time {
  /* color: #0096ee!important; */
  font-style: italic;
  line-height: 15px;
  font-size: 11px;
  display: flex;
  color: red !important;
  margin-top: 5px;
}
.appointment-container .add-appointment {
  background-color: white;
  border-radius: 8px;
}
.appointment-view .appointment-date .open-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.appointment-view .appointment-date {
  background-color: #0071c1;
  padding: 13px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.appointment-container .appointment-date h3 {
  color: white;
  margin-bottom: 0px;
}
.appointment-container .appointment-view .appointment-date img {
  margin-right: -5px;
}
.appointment-total h6:first-child {
  color: #0071c1;
}
.appointment-total h6,
.appointment-total h6 span {
  color: #0096ee;
}
.appointment-container .client-profile .action i.fa.fa-phone {
  transform: rotate(90deg);
}
.appointment-type-one .team-member {
  text-align: left;
}
.not-repeat .client-notes {
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-items: baseline;
  margin-bottom: 0px;
}

/* view appoinment popup css */
.client-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.client-details .client-left-details h6,
.client-details .client-left-details p {
  margin-bottom: 0px;
}
.client-details .client-left-details h6 {
  font-size: 18px;
  font-weight: 700;
}
.client-details .client-left-details p {
  color: #737171;
  margin-bottom: -2px;
}
.site-address {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}
.pool-visit-cost p span {
  color: #8d8d8d;
  font-size: 12px;
}

.view-appointment-popup .modal-body {
  padding: 10px 5px !important;
  margin: 0px !important;
}
.view-appointment-popup .staff-form {
  padding: 15px 10px !important;
}
.pool-visit-cost h6 {
  margin-bottom: 0px;
}
p.repeated-text {
  color: #0071c1;
  font-size: 12px;
  margin-top: -5px;
  line-height: initial;
}
.pool-visit-cost p {
  margin-bottom: -5px;
}
button.add-btn.water-test-btn.btn.btn-primary {
  background-color: #dceeff !important;
  border: 1px solid #60b2ff !important;
  color: #007bff;
}
.view-add-appointment .view-btn.service-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.view-add-appointment .view-btn.service-submit button {
  margin: 0px !important;
}
.view-add-appointment {
  margin-bottom: 15px;
}
.view-add-appointment .appointment-total h6,
.view-add-appointment .appointment-total h6 span {
  color: #585a5a;
  font-weight: 500;
}

.view-add-appointment .footer-btn.view-btn {
  justify-content: flex-end !important;
  margin-bottom: 30px;
}
.view-add-appointment .appointment-form {
  margin-top: -5px;
  padding-top: 0px;
}
.view-add-appointment .appointment-form label.form-label {
  font-size: 18px !important;
}
.view-add-appointment .footer-btn.view-btn button {
  width: auto !important;
}
.view-appointment-popup .modal-header {
  font-size: 22px;
  font-weight: 700;
}
.job-notes-content p:last-child {
  color: #0048ba;
  line-height: 16px;
  font-weight: 500;
  font-size: 14px;
}

.job-notes-content {
  background-color: #f5f5f5;
  border: 1px solid #bdbdbd;
  padding: 5px;
}
.owing-amount {
  width: 92%;
  text-align: right;
}

.owing-amount p {
  margin-bottom: 0px;
  color: #ff0000;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: -20px;
  line-height: 0px;
}
.view-add-appointment .appointment-total {
  margin-top: 20px;
}
.view-add-appointment .appointment-total h5 {
  margin-bottom: 0px;
}

/***********finalize cost **************/
.finalize-cost-page .add-appointment {
  background-color: transparent;
}
.finalize-cost-page .appointment-view .appointment-date {
  background-color: transparent;
  padding: 0px !important;
}
.finalize-cost-page .appointment-date h3 {
  color: black;
}
.finalize-cost-page .appointment-date img {
  opacity: 0.4;
}
.finalize-cost-page .appointment-view {
  padding: 12px 12px;
}
.finalize-cost-page .view-btn {
  justify-content: space-between;
}
.finalize-cost-page .pending-water-test {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.finalize-cost-page .client-notes {
  width: auto;
}
.pending-water-test h3 {
  font-size: 14px;
  margin-bottom: 0px;
}
.finalize-cost-page span.sep {
  width: 100%;
  display: inline-block !important;
  position: relative;
  margin: 10px 0px;
}
.finalize-cost-page .search-title.service-head {
  padding-top: 0px;
}

.green-pool-treatment .search-title.service-head {
  padding-top: 11.338582677px;
}

.finalize-cost-page .added-chemicals-info .chemical-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
}
.finalize-cost-page .added-chemicals-info h6 {
  font-weight: 400;
  text-transform: uppercase;
  color: #0048ba;
  font-size: 13px;
  margin-bottom: 3px;
  border-bottom: 1px solid;
  width: fit-content;
}
.finalize-cost-page .chemical-description {
  font-size: 12px;
  margin-bottom: -7px;
  font-weight: 500;
}
.finalize-cost-page .chemical-description:last-child {
  margin-bottom: 0px !important;
}
/* .finalize-cost-page .chemical-rate {
  font-weight: 600;
} */
.finalize-cost-page .chemical-rate {
  min-width: 15%;
  display: flex;
  justify-content: space-between;
}
.finalize-cost-page .appointment-total h6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finalize-cost-page .appointment-total {
  width: 50%;
  margin-left: auto;
}
.finalize-cost-page .appointment-total h6:first-child {
  color: #0096ee !important;
  font-weight: 600 !important;
}
.finalize-cost-page .appointment-total h6,
.finalize-cost-page .appointment-total h6 span {
  font-weight: 600;
  font-size: 12px !important;
}
.finalize-cost-page p.labour-margin {
  font-style: italic;
  font-size: 12px;
  text-align: right;
  color: #8a8a8a;
}
.finalize-cost-page .footer-btn.view-btn {
  margin-top: 25px;
}
.finalize-cost-page .appointment-view .appointment-date {
  margin-bottom: 0px;
}

/**************awaiting approvals *******************/
.awaiting-approvals .invoices-head h6 {
  font-size: 13px;
  padding-right: 0px;
  width: 20%;
  margin-bottom: 0px;
  font-weight: 700;
}
.awaiting-approvals .invoices-head h6:first-child {
  width: 60%;
  color: #0048ba;
}
.awaiting-approvals .customer-name p,
.awaiting-approvals .customer-name p:first-child {
  width: 100% !important;
}

.awaiting-approvals .customer-name {
  width: 60%;
}
.awaiting-approvals .invoice-body p {
  line-height: initial;
  padding-right: 0px;
}
.awaiting-approvals .client-notes {
  justify-content: center;
  width: auto;
}
.awaiting-approvals .invoices-head h6:last-child {
  text-align: center;
  padding-right: 0px;
}
.awaiting-approvals .invoice-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.awaiting-approvals .check-paid {
  color: #20b50c;
}
.awaiting-approvals .invoice-body p span {
  color: #0096ee;
  padding-left: 10px;
}
.awaiting-approvals .customer-name p {
  color: #676767;
}

.awaiting-approvals .customer-name p:nth-last-child(1) {
  color: #a7a7a7 !important;
  font-style: italic;
}
/* .awaiting-approvals .customer-name p:nth-last-child(1) {
  color: #20b50c !important;
  font-style: italic;
} */
.awaiting-approvals p.completed-part span {
  color: red;
  padding-left: 10px;
}

.awaiting-approvals p.completed-part {
  color: #20b50c;
  font-size: 12px;
  font-style: italic;
  margin-top: -5px;
}
.awaiting-approvals .invoices-list .user-pagination {
  padding-top: 40px;
}

/**************Preview invoice **************/
.preview-invoice .add-appointment {
  padding: 12px 12px;
}

.preview-invoice .invoice-type {
  background-color: #eff3fe;
  padding: 10px;
  border-radius: 5px;
}

.preview-invoice .preview-invoice .detailed-invoice {
  display: flex;
  justify-content: space-between;
}

.preview-invoice .detailed-invoice .open-date.client-notes {
  width: auto;
}

.preview-invoice .invoice-type p {
  font-weight: 600;
  color: #22b7f0;
  margin-bottom: 0px;
  line-height: initial;
}

.preview-invoice .invoice-type p span {
  font-style: italic;
  font-weight: 400;
  color: #5ed0fe;
}
.preview-invoice .invoice-type h6 {
  font-weight: 600;
  color: #22b7f0;
  font-size: 18px;
}
.preview-invoice .detailed-invoice {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.preview-invoice .detailed-box {
  margin-top: 10px;
}
.preview-invoice .select-box select.form-control {
  background-color: white;
  color: #22b7f0;
  font-size: 12px;
  font-style: italic;
}

.top-logo {
  width: 50%;
  margin-left: auto;
  flex-wrap: wrap;
}

.top-logo img {
  height: 45px;
}

.preview-invoice-main p {
  font-size: 8px;
  line-height: initial;
  margin-bottom: 0px;
}

.top-logo p {
  padding-left: 44px;
}
.preview-invoice-main {
  padding: 50px 0px;
}
.tax-invoice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 10px;
}
.tax-invoice p {
  width: 34%;
  font-size: 8px;
  line-height: initial;
}
.top-logo p {
  margin-bottom: 10px;
}
.invoice-no {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 500;
  padding-right: 12px;
}
.tax-invoice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-weight: 500;
}
.invoices-listing {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  line-height: initial;
}
.bill-to {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  line-height: initial;
}

.bill-to p {
  width: 50%;
}
.bill-to p:last-child {
  width: 37%;
  text-align: right;
}
.tax-invoice h6 {
  font-size: 10px;
}

.preview-invoice .preview-invoice-main {
  padding: 12px 15px;
}
.invoices-listing-box {
  margin-top: 50px;
}
.goods-cost p:last-child {
  text-align: right;
}
.invoices-bottom .goods-cost p {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.invoices-bottom .goods-cost {
  width: 45%;
}
.invoices-bottom {
  margin-top: 100px;
}
.invoices-bottom .goods-cost p:last-child {
  font-weight: 600;
  font-size: 9px;
  margin-top: 10px;
}
.invoices-bottom .goods-cost p span {
  padding-left: 20px;
  min-width: 60px;
}
.preview-invoice .footer-btn.view-btn button {
  width: 49%;
  font-size: 10px !important;
  padding: 10px 5px !important;
}
.preview-invoice .merge-btn button {
  width: 30% !important;
}
.preview-invoice .footer-btn.view-btn {
  display: flex;
  justify-content: space-between;
}
/* .preview-invoice .footer-btn.view-btn button:first-child {
  border: 1px solid red !important;
  color: red !important;
} */
.search-client-address .client-notes {
  width: auto;
}
.search-client-address {
  background: #ffff;
  padding: 15px 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.appointment-details .view-add-appointment .appointment-total h5 {
  margin-bottom: 0px;
  font-size: 15px;
}
.appointment-details .client-details .client-left-details h6 {
  font-size: 15px;
  font-weight: 600;
}
.appointment-details .client-details .client-left-details p {
  font-size: 13px;
}
.appointment-details .job-notes-content p {
  font-size: 12px;
}
.appointment-details .pool-visit-cost p:last-child {
  font-size: 12px;
}
.appointment-details .pool-visit-cost h6 {
  margin-bottom: 0px;
  font-size: 12px;
}
.appointment-details .appointment-total h6 {
  font-size: 13px;
}

.invoice-close img {
  width: 25px;
  cursor: pointer;
}

.invoice-close {
  position: absolute;
  right: 15px;
  top: -40px;
  opacity: 0.6;
}

.pool-set-up.invoices-list {
  position: relative;
}
.appointment-details
  .client-details.main-clients-details
  .client-left-details
  h6,
.appointment-details
  .client-details.main-clients-details
  .client-left-details
  p {
  color: #0070c0 !important;
}
.appointment-details .client-left-details {
  line-height: 20px;
}
.appointment-details .client-details.site-address .client-left-details h6 {
  color: #7d7d7d !important;
}
.pool-visit-cost h5 {
  color: #0070c0;
  font-size: 15px;
  margin-bottom: 0px;
}
.pool-visit-cost {
  margin-bottom: 10px;
}

.appointment-details .pool-visit-cost p {
  line-height: 16px;
}
.job-notes-content p {
  margin-bottom: 0px;
  color: #0048ba;
}

.job-notes-content {
  background-color: #f5f5f5;
  border: 1px solid #bdbdbd;
  padding: 5px;
  min-height: 55px;
  /* display: flex; */
  justify-content: flex-start;
  align-items: baseline;
}
.client-profile .client-name-contact.site-addresses h6 {
  font-size: 13px;
  color: #333;
  font-weight: 600;
}
.appointment-container.finalize-cost-page .staff-form.view-add-appointment {
  background-color: white;
  padding: 15px 10px;
}
.appointment-container.finalize-cost-page
  .staff-form.view-add-appointment
  .site-address {
  border-bottom: none;
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}
.back-option span img {
  height: 17px;
  transform: rotate(90deg);
  opacity: 0.8;
}
.back-option a img {
  height: 17px;
  transform: rotate(90deg);
  opacity: 0.8;
}

.back-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  margin: -35px 0px 15px;
}
.back-option span {
  display: flex;
  align-items: center;
  color: #6b6b6b;
}

.back-option i {
  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;
  content: "";
  background-image: url(../../assets/admin/close.png);
  background-size: 25px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.back-option i:before {
  display: none;
}
.payment-top-heading p {
  color: #22b7f0;
  font-weight: 600;
}

.completed-appt .footer-btn.view-btn {
  display: flex;
}

.completed-appt .footer-btn.view-btn .dropdown {
  width: 49%;
}

.completed-appt .footer-btn.view-btn .dropdown button {
  width: 100%;
  padding: 0px;
  background-color: transparent !important;
  border: 1px solid #939191;
  color: #939191 !important;
}
.completed-appt .footer-btn.view-btn .dropdown button::after {
  display: none;
}
.completed-appt .footer-btn.view-btn .dropdown .dropdown-item {
  padding: 8px 12px !important;
}
.completed-appt .btn-success:hover,
.completed-appt .btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.completed-appt .btn-success:not(:disabled):not(.disabled).active,
.completed-appt.btn-success:not(:disabled):not(.disabled):active,
.completed-appt .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.duration-picker.time-picker-modal .time-keeper-popup.duration-popup {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 999999999 !important;
}
.duration-picker.time-picker-modal:before,
.time-picker-modal.time-popup::before {
  content: "";
  position: fixed;
  background-color: #131313a8;
  height: 100%;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 999999 !important;
}
.time-keeper-popup.add-apppt-time {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 999999999 !important;
}
.time-keeper-popup .smart-time-picker .smart-svg-picker:focus {
  border: none !important;
}
.finalize-cost-page a.btn.btn-primary.btn-save {
  display: flex;
  justify-content: center;
  align-items: center;
}
.completed-appt h6.invoice-no {
  font-size: inherit;
  justify-content: flex-start;
}
.completed-appt .invoices-detail-table h6 {
  font-weight: 600;
  font-size: 14px;
}
.completed-appt .main-titles-mob h3 {
  color: #1aa01d;
}

.completed-appt .target-heading h6 {
  color: #1aa01d;
}
.completed-appt button.appointment-btn.btn-save.btn.btn-primary {
  background-color: transparent !important;
  border: 1px solid #28a745 !important;
  color: #28a745 !important;
}

.blocked-time-duration {
  display: flex;
  justify-content: space-between;
}

.blocked-time-duration .select-time {
  width: 48%;
}
.blocked-popup .select-time h6 {
  font-size: 13px;
  margin-bottom: 4px;
}
/* .blocked-popup{
  display: none;
} */

.uncomplete-appointment .footer-btn.view-btn .dropdown {
  width: 49%;
}

.uncomplete-appointment .footer-btn.view-btn .dropdown button {
  width: 100% !important;
  padding: 0px;
  background-color: transparent !important;
  border: 1px solid #939191;
  color: #939191 !important;
  font-size: 12px !important;
}

.uncomplete-appointment .footer-btn.view-btn a.dropdown-item {
  padding: 8px 12px !important;
}

.uncomplete-appointment .footer-btn.view-btn {
  display: flex;
  justify-content: space-between !important;
}

.uncomplete-appointment
  .footer-btn.view-btn
  button.appointment-btn.btn-save.btn.btn-primary {
  width: 49% !important;
  font-size: 14px !important;
}
.unpaid-appointment-total h5 {
  color: red;
}

.unpaid-appointment-total h6 {
  color: red !important;
  opacity: 0.5;
}
.paid-appointment-total h5 {
  color: #036503;
}
.paid-appointment-total h6 {
  color: #036503 !important;
  opacity: 0.5;
}
.completed-appointment {
  color: #036503 !important;
}

.edit-appt .container-fluid {
  padding: 0px !important;
}
.edit-appt .view-btn {
  justify-content: flex-end !important;
}

.custom-appt-popup {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  background-color: white;
  background: #f6f7fb;
  padding: 0 10px 20px;
  min-height: 100vh;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
}
.custom-popup .custom-appt-popup {
  visibility: visible;
  opacity: 1;
}
.startdate-modal.calendar-modal.search-modal.time-popup .modal-content {
  background-color: transparent;
}
.time-calender .react-calendar {
  border-radius: 5px;
}
.time-calender .react-calendar__month-view__weekdays {
  padding: 5px 10px 0;
}
.time-calender .react-calendar__month-view__weekdays__weekday {
  padding: 0px 0.5em;
  font-size: 11px;
  font-weight: 700;
}
.blocked-time-duration button {
  border: 1px solid #e0e0e0 !important;
  width: 100% !important;
  text-align: left !important;
  padding: 6px 10px !important;
  font-size: 13px !important;
  color: #a5a5a5 !important;
  height: 35px !important;
  font-weight: 400;
}
.time-popup .modal-content {
  overflow: scroll;
  height: 100vh;
}
.search-popup.shift-container.blocked-popup > .select-time:first-child {
  margin-top: 0px;
}
.time-popup .react-calendar__tile:disabled {
  background-color: transparent !important;
}
.time-popup .react-calendar__tile:disabled abbr {
  color: #dad9d9 !important;
}
.time-popup
  .time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.time-popup
  .time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.time-popup
  .time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.time-popup
  .time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  height: 25px;
  width: 25px;
  min-width: 25px;
}
.apppt-time-popup
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button::after {
  margin-top: 0px;
}
.apppt-time-popup
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button::after {
  margin-left: -2px;
  margin-top: 0px;
}
.apppt-time-popup
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button::after {
  margin-left: -1px;
  margin-top: 0px;
}
.apppt-time-popup
  .time-calender
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button::after {
  margin-left: -1px;
  margin-top: 0px;
}
.view-btn.time-btn.remove-delete-btn {
  display: flex;
  justify-content: flex-end;
}
.view-btn.service-submit button.add-btn.water-test-btn.water-test-done {
  background-color: #f9d30d !important;
  color: white;
  border: 1px solid #f9d30d !important;
  line-height: 16px !important;
}
.view-add-appointment .view-btn.service-submit button{
  line-height: 16px !important; 
}
.react-pdf__Page canvas {
  width: 100% !important; 
  height: auto !important;
  /* border: 1px solid #e8e5e5 !important; */
  margin-top: 20px;
}

.react-pdf__Page.printa4 {
  border: 1px solid #e8e5e5 !important;
  margin-top: 20px;
}

.hour_minute_handle {
  display: flex;
  justify-content: space-between;
}

.time-picker-modal .hour_minute_handle button {
  background: #f5f5f5 !important;
  color: #636363 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  font-family: "Exo 2" !important;
  height: 25px !important;
  width: 45px !important;
  line-height: 25px !important;
  text-transform: lowercase !important;
  min-width: auto !important;
  padding: 0px !important;
  border-top: none !important;
}
.time-picker-modal .hour_minute_handle .hourbtn-active {
  border-radius: 5px !important;
  font-family: "Exo 2" !important;
  background-color: #1550b9 !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}
.hour_minute_handle {
  display: flex;
  justify-content: space-between;
  margin-top: -45px;
  margin-bottom: 25px;
}
.appointment-view .appointmentbox {
  margin-bottom: 15px;
}
.view-add-appointment .pool-visit-cost h5 {
  margin-bottom: 8px;
}
.appointment-details .pool-visit-cost h6 {
  margin-bottom: 3px;
}
.appointment-details .pool-visit-cost p {
  line-height: initial;
}
.view-add-appointment .pool-visit-cost {
  margin-bottom: 8px;
}
.rebook-btn{
  display:flex;
  justify-content: flex-end !important;
}

.water-appointment .appointment-view {
  padding: 20px 12px;
}

.water-appointment .appointment-view .pending-water-test {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 7px 0px;
  align-items: center;
}

.water-appointment .appointment-view .pending-water-test .open-date.client-notes {
  width: auto;
}
.paymenticon {
  display: inherit !important;
  text-align: right !important
}
.hidden-data {
  color: green;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 8px;
}
.hidden-data img {
  margin-left: 15px;
}
.search-modal .modal-header {
  align-items: center;
}

.search-modal .modal-header .client-option.service-edit-icon {
  margin-top: -4px;
}
.appointment-container .search-container .client-profile .client-left-details p {
  color: #737171 !important;
  margin-bottom: -2px;
  line-height: 20px;
  margin-top: 0px;
}
.search-popup.shift-container.blocked-popup input {
  color: #a5a5a5;
  font-weight: 400;
}
.awaiting-approvals .invoices-head h6:first-child {
  color: #000000;
}
.awaiting-approvals .invoices-head h6 {
  font-weight: 600;
}
.awaiting-approvals .customer-name p{
  text-transform: capitalize;
}
.appointment-container.preview-invoice.preview-popup .footer-btn.view-btn button {
  font-size: 15px!important;
  font-weight: 600!important;
  border-radius: 10px;
  padding-top: 0!important;
  padding-bottom: 0!important;
  height: 38px!important;
}
.view-appointment-details .appointment-view {
  padding: 0px;
}
.view-appointment-details .client-details.site-address {
  margin-bottom: 0px;
  border-bottom: none;
  padding-bottom: 0;
}
.view-appointment-details .chemical-cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-appointment-details .chemical-cross i {
  color: red;
  font-size: 16px;
}
.view-appointment-details .sep{
  width: 100%;
  display: inline-block !important;
  position: relative;
  margin: 10px 0px;
}
.view-appointment-details .view-add-appointment .appointment-total{
  margin-top: 0px;
}
.view-appointment-details  .appointment-details-total h6, .appointment-details-total h6 span {
  color: #0096ee !important;
}
.custom-time-popup .staff-close i {
  right: 0px;
  top: -3px;
  background-size: 20px;
  width: 20px;
  height: 20px;
}
.custom-time-popup .staff-close {
  right: 0px;
  top: 0px;
  background-color: #ffffff;
  padding: 30px 0px;
  padding-top: 0px;
}
.appointment-view  span.sep {
  margin: 10px 0px;
}
.view-appointment-details .finalize-cost-page button {
    width: 49% !important;
}
.view-appointment-details .view-add-appointment .footer-btn.view-btn button{
  width: 49% !important;
}
.view-appointment-details .view-add-appointment .footer-btn.view-btn button.testing-btn.appointment-btn.btn-save.btn.btn-primary {
  width: auto !important;
}
.visit-message h6{
  color:#0096ee;
  font-size: 12px;
}
.visit-color-green{
  color: #00b050 !important; 
}
.visit-color-red{
  color: red !important; 
}
.appointment-total .visit-message {
  width: 100%;
}
.appointment-total .visit-message h6 {
  color: #0096ee !important;
  font-size: 12px;
  font-weight: 600 !important;
}
.awating-new-section{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}
.awating-new-section .table-section {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.awaiting-approvals .awating-new-section  .visit-color-green{
  color: #20b50c !important;
  font-style: italic;
  font-size: 12px;
}

.awaiting-approvals .awating-new-section  .visit-color-green span{
 margin-left: 5px;
}

.add-appointment .client-profile .dropdown-menu.show {
  min-width: 11rem;
  box-shadow: 0px 0px 3px 0px #e8dfdf;
    border: none;
}
.add-appointment .client-profile .dropdown-menu .action a, .add-appointment .client-profile .dropdown-menu .action {
  font-size: 14px;
  line-height: 16px;
  color: #b4b5b8;
}
.add-appointment .client-profile .dropdown-menu .action i {
  margin-right: 5px;
  font-size: 12px;
} 
.add-appointment .client-profile .dropdown-menu .dropdown-item {
  padding: 8px 12px;
}
.appointment-view .client-notes .form-check-input[type=checkbox]:hover,
.appointment-view .client-notes .form-check-input[type=checkbox]:focus {
  border: 1px solid #e0e0e0 !important;
}
.appointment-container.preview-invoice.preview-invoice-section .content-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.appointment-container.preview-invoice.preview-invoice-section .content-dropdown h4 {
  margin: 0;
}
.appointment-container .add-appointment .search-container > div {
  width: 100%;
}

.word-wraper {
  word-break: break-all;
}

.green-pool-treatment .multi-visit-cost {
  padding-top: 11.338582677px;
  padding-bottom: 11.338582677px;
}

@media (max-width: 480px) {
  .invoice-template-popup {
    padding: 40px 24px 20px !important;
  }
  .invoice-template-popup .template-btn.view-btn {
    margin: 15px 0;
    width: 100%;
  }
  .invoice-template-popup h5 {
    color: #0048ba;
    font-size: 16px;
  }
  .invoice-template-popup .form-group {
    margin: 20px 0 0;
  }
}