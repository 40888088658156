.notification-popup {
    background-color: #fff;
    width: 350px;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 999;
    transition: all ease 0.5s;
    left: -350px;
  }
  .notification-popup .notify-img {
    width: 50px;
    height: 50px;
    border: 1px solid #dbd5d5;
    border-radius: 60px;
    margin-bottom: 20px;
  }
  .notification-popup .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    border-bottom: 1px solid rgb(237 230 230);
  }
  .notification-popup h6 {
    font-size: 14px;
    font-family: sans-serif;
  }
  .notification-popup .noti-content {
    padding: 0px 20px !important;
  }
  .notification-popup .noti-content p {
    font-size: 14px;
    line-height: 1rem;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
  }
  .notification-popup h5 {
    font-size: 25px;
    padding: 20px 20px 20px 20px;
  }
  .notification-popup .noti-content h6 {
    line-height: 0.75rem;
  }
  .notification-popup .notify-img i {
    color: #61c9e5;
      display: flex;
      justify-content: center;
      margin-top: 14px;
  }
  .notification-popup .notification-content {
    padding: 20px 20px 0px 20px;
  }
  .notification-popup .notification-content {
    padding: 0px 20px 0px 20px;
  }
  .notification-show .notification-popup {
    left: 0px !important;
  }
  .notification-popup .notify-img {
    width: 100px;
    height: 45px;
    border: 1px solid #dbd5d5;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .notification-modal .content {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    border-bottom: 1px dashed #c3c3c3;
    padding-bottom: 15px;
  }
  
  .notification-modal .content .notify-img {
    margin-right: 12px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3441df;
  }
  
  .notification-modal .noti-content {
    width: calc(100% - 55px);
  }
  
  .notification-modal .notify-img i {
    font-size: 20px;
    color: #3441df;
  }
  .notification-modal .noti-content p {
    font-size: 14px;
    line-height: initial;
    color: #727272;
  }
  .notification-modal .no-data {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    font-size: 18px;
  }

  /* .invoice-header-popup{
    background-color: #e3e7e3;
  } */
  .box-border{
    border-bottom: 1px solid #e4e4e4;
   
  }

.box-border .form-control:focus {
    border: none !important;
}
.editorSendInvoice .ql-container.ql-snow {
    border: none !important;
}

.editorSendInvoice .ql-editor{
  font-family: "Exo 2" !important;
  color: #a5a5a5 ;
  font-size: 15px;
}

.highlighted-text {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}

.sendInvoicePara {
  font-family: "Exo 2" !important;
  color: #a5a5a5 ;
  font-size: 15px;
}

.sendInvoice-btn {
justify-content: flex-end !important;
gap: 5px!important;
}
@media  screen and (max-width:991px) {
  
.editorSendInvoice .ql-editor{
  font-family: "Exo 2" !important;
  color: #a7a7a7;
  font-size: 12px;
}

.highlighted-text {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}

.sendInvoicePara {
  font-family: "Exo 2" !important;
  color: #a7a7a7;
  font-size: 12px;
}

.sendInvoice-btn {
justify-content: flex-end !important;
gap: 5px!important;
margin-top: 25px !important;
}
.sendInvoice-close {
  padding-bottom: 30px !important;

}
}