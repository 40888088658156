.reports-page .card {
  background-color: #ffff;
  margin-bottom: 15px;
  border-radius: 5px !important;
  border: none;
  /* box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%); */
  padding: 15px 12px;
}

.reports-page .reports-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  font-family: "Exo 2" !important;
}

.reports-page .reports-heading img {
  width: 32px;
  object-fit: cover;
  transform: rotate(-90deg);
  filter: opacity(0.5);
}

.appointment-summary .custom-datepicker::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background: url("../../assets//home/newcal.png");
  background-repeat: no-repeat;
  right: 5px;
  background-size: contain;
}

.appointment-summary .custom-datepicker {
  padding: 0px 12px 0px 12px;
  color: black !important;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  font-weight: 400;
  background-color: white;
  border: 1px solid #e0e0e0;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #a5a5a5 !important;
}
.appointment-summary .start-date-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appointment-summary .start-date-section .form-group {
  margin-bottom: 0px;
  width: 45%;
}
.appointment-summary {
  background: #fff;
  border-radius: 5px;
  padding: 12px 12px;
}
.appointment-summary-section p {
  line-height: initial;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
  color: #0659dc;
}
.appointment-summary-section {
  margin: 15px 0px;
}
.reports-page .form-group label.form-label {
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.reports-page .summary-table-header,
.reports-page .summary-table-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.reports-page .summary-table-header h6 {
  font-size: 12px;
  width: 22%;
  padding: 0px 5px;
  color: black;
  font-weight: 700;
}
.reports-page .summary-table-header h6:nth-child(2) {
  width: 15% !important;
  text-align: right;
}
.reports-page .summary-table-header h6:first-child,
.reports-page .summary-table-body p:first-child {
  width: 25% !important;
}
.reports-page .summary-table-body p {
  width: 22%;
  text-align: left;
  font-weight: 500;
  color: #757575;
  padding: 0px 5px;
}
.reports-page .summary-table-body p:nth-child(2) {
  width: 15% !important;
  /* text-align: right; */
}
.reports-page .summary-table-body {
  margin-bottom: 8px;
}
.summary-table-body.total-section p {
  line-height: initial;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: center;
  color: #0659dc;
}
.summary-table hr {
  margin-top: 6px;
  margin-bottom: 6px;
}
.reports-page .summary-table-body.total-section p:first-child {
  width: 30% !important;
  text-align: left;
}
.reports-page .summary-table-body.total-section p:nth-child(2) {
  width: 10% !important;
}
.staff-container .select-box select.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #e0e0e0;
}
.startend-popup h6.end-date {
  margin-top: 20px;
}

.startend-popup h6 {
  color: #1414ff;
  font-size: 16px;
  margin-bottom: 5px;
}
.startend-popup .view-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}
.reports-appointment .target-heading.client-profile {
  margin-top: 0px;
}
.reports-page .details-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.reports-page .details-body h6 {
  width: 50%;
  font-size: 12px;
  color: black;
  margin-bottom: 0px;
  font-weight: 700;
}
.reports-page .details-body p {
  width: 50%;
  text-align: left;
  font-weight: 500;
  color: #757575;
}
.reports-page .details-body {
  margin-bottom: 8px;
}
.details-body.period-total h6 {
    color: #0659dc;
}
.ageing-datepicker{
position: relative;
}
.ageing-page .summary-table .summary-table-header h6:nth-child(2),
.ageing-page .summary-table .summary-table-body p:nth-child(2)
{
  text-align: left !important;
  width: 20% !important;
}
.ageing-page .summary-table .summary-table-header h6
{
padding-right: 10px;

}
.ageing-page .summary-table .summary-table-header h6,
.ageing-page .summary-table .summary-table-body p
{
  text-align: left !important;
  width: 20% !important;
}

.ageing-page .summary-table .summary-table-body p:nth-child(1),
.ageing-page .summary-table .summary-table-header h6:nth-child(1)
{
  text-align: left !important;
  width: 30% !important;
}
.ageing-page .summary-table-body.total-section p:first-child{
  width: 30% !important;
}
.ageing-page .summary-table .summary-table-body p:nth-child(1){
  padding-right: 15px;
}
.period-total-other h6{
color: #28b8f2 !important;
font-style: italic;
}
.reports-page .search-box {
  position: relative;
}
.new-location p{
  text-align: left !important;
}
p.location-name {
  color: #0000ff !important ;
}

p.location-light {
  color: #87a7db !important;
}
.location-text p{
  text-align: left;
  color: #959f95;
  font-weight: 400;
}
/* .summary-table p{
text-align: left;
color: #a39898;
font-weight: 500;
font-size: 12px;
} */
.appointment-summary .new-location {
  margin-bottom: 20px;
}
.appointment-summary hr {
  margin-top: 12px;
  margin-bottom: 12px;
}
.reports-page .pageone-payment .summary-table-header h6:nth-child(2) {
  width: 25% !important;
  text-align: left;
}
.pageone-payment .summary-table-body p:last-child {
  text-align: right;
}
.pageone-payment .summary-table-header h6:last-child {
  text-align: right;
}
.pageone-payment 
.location-text {
    padding-left: 5px;
}

.pageone-payment  .location-text p {
    font-size: 11px !important;
}
.pageone-payment .details-body.period-total h6 {
  color: #0000ff;
}
.new-location p {
  font-size: 12px !important;
  font-weight: 700 !important;
}
.pagetwo-payment .summary-table-header h6:first-child{
  width: 25% !important;
}
.pagetwo-payment .summary-table-header h6{
  width: 17% !important;
}
.pagetwo-payment .summary-table-header h6:nth-child(4){
  width: 25% !important;
}
.pagetwo-payment .summary-table-body p:first-child{
  width: 25% !important;
}
.pagetwo-payment .summary-table-body p{
  width: 17% !important;
}
.pagetwo-payment .summary-table-body p:nth-child(4){
  width: 25% !important;
}
.reports-page .pagetwo-payment .summary-table-header h6:nth-child(2) {
  width: 17%  !important;
  text-align: left;
} 

.hyperLink-test u{
  text-decoration-color: #0645AD;
  color: #0645AD;
}
.reports-appointment .summary-table-body p:nth-child(2), .reports-appointment .summary-table-header h6:nth-child(2) {
  text-align: left !important;
}

.appointment-summary.pageone-payment .summary-table-body p:nth-child(2) {
  width: 25% !IMPORTANT;
}
.appointment-summary.pagetwo-payment .summary-table-body p:nth-child(2) {
  width: 17% !IMPORTANT;
}
.pagetwo-payment .summary-table .summary-table-body p{
  width: 17% ;
}
 .reports-page .log-activity-page .summary-table-header h6:nth-child(2){
  text-align: left;
}
.search-summary .auto-complete{
  top: 65px;
}