.csv-upload-page .target-heading.client-profile h3{
    color: #333 !important;
}
/******* download csv popup ********/
.download-csv-popup h6 {
    padding: 0px 12px;
    font-size: 20px;
}

.preview-image input[type="checkbox"]{
    width: 15px;
    height: 24px;
    border-radius: 50%;
    border-color: #5bd125;
    overflow: hidden;
}
.preview-image input[type="checkbox"]:checked {
  background-color: #5bd125;
  border-color: #5bd125;
  background-image: url(../../assets/home/check1.png);
}

.csvUploads a, .csvUploads a:active, .csvUploads a:visited{
    color: #b4b5b8 !important;
}

.duplicate-text {
    padding: 0px 12px;
    line-height: initial;
}

.csv-upload-erros ul.staff-list {
    padding-left: 0px;
}

.csv-upload-erros .staff-box {
    padding: 0px 0px 15px !important;
}
.csv-upload-erros .card {
    background: white;
    border: none !important;
    margin-bottom: 15px;
}
.csv-upload-erros ul.staff-list .staff-box h3 {
    width: 25% !important;
}
.csv-upload-erros ul.staff-list .staff-box h5 {
    width: 75% !important;
}
.csv-upload-erros ul.staff-list .staff-box {
    align-items: flex-start;
}

.upload-cus-errors .cus-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-cus-errors .us-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 10px;
}
.upload-cus-errors .us-list p:nth-child(1), .upload-cus-errors .cus-header h6:nth-child(1) {
    width: 50%;
    font-size: 13px;
    line-height: initial;
    font-weight: 500;
    text-align: left;
}
.upload-cus-errors .us-list img {
    height: 12px;
    filter: invert(1);
    cursor: pointer;
    transform: rotate(270deg);
    opacity: 0.5;
}
.upload-cus-errors .cus-header h6 {
    font-size: 14px !important;
    font-weight: 700 !important;
}

.upload-cus-errors .cus-header h6.hidden span{
    display: none;
}
.upload-cus-errors .us-list p:nth-child(4), .upload-cus-errors .cus-header h6:nth-child(4){
    width: 10%;
    text-align: right;
    padding: 0 !important;
}
.upload-cus-errors .cus-header h6, .upload-cus-errors .us-list p{
    width: 20%;
    text-align: center;
    padding: 0px 5px;
    margin: 0;
}
.upload-cus-errors .error-desc {
    padding-bottom: 30px;
}

.upload-cus-errors .error-desc h3 {
    margin-bottom: 5px;
}

.upload-cus-errors .error-desc p {
    margin: 0;
    line-height: initial;
    font-size: 14px;
}
.upload-cus-errors p.error-no {
    color: red;
}
.upload-cus-errors div#table-scroll {
    background: white;
    padding: 15px;
    border-radius: 5px;
}
.upload-cus-errors .us-list:last-child{
    border: none;
    padding-bottom:0 ;
}
.preview-csv-popup  .save-btn:hover, .preview-csv-popup  .save-btn:focus, 
.preview-csv-popup .save-btn:not(:disabled):not(.disabled):active:focus  {
    background: #15a01c !important;
    color: white !important;
    border: 1px solid #15a01c !important;
}
.no-summary-details {
    background: white;
    padding: 40px 20px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
}