/* iframe{
  pointer-events: none;
} */
span.required-field {
  margin-left: 5px;
  color: red !important;
  font-weight: 800;
  font-size: 16px !important;
}
.basic .form-check-input:checked {
  background: transparent;
}
.client-notes .basic .form-check-input:checked {
  background-color: transparent;
}
.service-mbl.pool-accordian.miniral-pool
  .service-container
  .service-title.title-service
  .btn {
  text-align: left;
}
@media screen and (min-width: 1024px) {
.view-btn .btn {
    width: 49% !important;
    max-width: 150px;
}
}

@media screen and (max-width: 991px) {
  body.fixed-body-section {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

  .uncomplete-appointment .footer-btn.view-btn .dropdown button {
    font-size: 14px !important;
  }

  .content-container.cs-container {
    padding: 88px 0 60px;
  }

  /* .calc-btn-bottom.hide-btn-section.form-row {
    margin-top: 300px;
  } */

  .ppm-label {
    padding: 0px !important;
  }

  .checklist-text {
    font-size: 14px;
  }

  .save-btn.drain-btn button span {
    font-size: 10px !important;
  }

  .name-location p {
    font-size: 13px;
  }

  .ageing-page .summary-table .summary-table-header h6 {
    display: grid;
  }

  .reports-page .summary-table-body p {
    font-size: 14px;
  }

  .reports-page .appointment-summary-section p {
    font-size: 16px;
  }

  .reports-page .reports-heading img {
    width: 24px;
  }

  .reports-page .reports-heading {
    font-size: 18px;
  }

  .calc-btn-reset .back-btn .btn {
    font-size: 12px !important;
  }

  /* .calculator-container .pool-report .form-group {
    margin: 12px 0px !important;
  } */

  .image-upload-popup .select-box:after {
    background-size: 18px;
    width: 20px;
    height: 20px;
    right: 5px;
  }

  .change-text-popup label.form-label {
    font-size: 13px;
  }

  .repeat-modal .view-btn .btn {
    font-size: 12px !important;
  }

  .template-btn.view-btn button {
    width: 49% !important;
  }

  .staff-modal .calendar-filter-icons span {
    background-color: #ededed;
    height: 25px;
    width: 25px;
    margin: 0px 2px;
  }

  .staff-modal .schedule-date-toolbar .date {
    font-size: 12px;
  }

  .calendar-settings .staff-form label.form-label {
    text-transform: inherit !important;
  }

  .staff-modal ul.staff-list .staff-box h5 h6 {
    font-size: 13px;
  }
/* 
  table.edit-template {
    width: 100% !important;
  } */
  .edit-template-page {
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    height: fit-content;
    width: 100%;
    /* overflow: auto; */
}
  .change-image-section .change-section-btn button {
    width: 23% !important;
  }

  .image-upload-popup .staff-close {
    padding: 0px 0px !important;
  }

  .calendar-settings .view-btn {
    justify-content: space-between !important;
  }

  .template-btn button {
    width: 18% !important;
    font-size: 12px;
  }

  .template-btn .btn + .btn {
    margin-left: 0px !important;
  }

  .image-upload-popup .staff-close i {
    right: 12px;
    top: -5px;
  }

  .calendar-container.close-client-popup .calendar-team-member {
    right: -500px;
  }

  .pool-chemistry-box.appointment-service-modal label {
    font-size: 13px !important;
  }

  .view-add-appointment .appointment-form label.form-label {
    font-size: 22px !important;
  }

  /************** pool setup *****************/
  .pool-set-up .balance-water h5 {
    font-size: 16px !important;
  }

  .pool-set-up .balance-water .balance-water-box h6 {
    font-size: 16px !important;
  }

  .service-mbl.pool-accordian.miniral-pool
    .service-container
    .service-title.title-service
    .btn {
    font-size: 20px;
  }

  .pool-set-up .drainage-cost-section h6 {
    font-size: 18px;
  }

  .pool-set-up .drainage-cost-section .bottom-text p {
    font-size: 18px;
  }

  .pool-set-up .drainage-cost-section h5 {
    font-size: 20px;
  }

  .drainage-cost-section input {
    font-size: 20px !important;
  }

  .service-mbl.pool-accordian .mob-accordion {
    padding: 8px 0px !important;
  }

  .pool-accordian .inner-accordion ul.grid-list .grid-box h3 {
    font-size: 17px;
  }

  .pool-accordian .inner-accordion ul.grid-list .grid-box h5 {
    font-size: 17px;
  }

  .pool-set-up .client-option.service-edit-icon .dropdown-menu {
    min-width: 11rem;
  }

  .pool-set-up .target-heading h6 {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .pool-set-up .view-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .chemical-setup .right-pool-analysis {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
  }

  .chemical-setup .left-pool-analysis {
    width: calc(40% - 10px);
  }

  .pool-analysis {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
  }

  .pool-set-up .save-btn-setup {
    display: flex;
    justify-content: flex-end;
  }

  .chemical-product .left-pool-analysis {
    width: 60%;
  }

  .chemical-product .right-pool-analysis {
    width: 40%;
  }

  .left-pool-analysis h5 {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  /************ end pool setup *************/

  /* new client changes */
  .service-container .service-title {
    display: flex;
  }

  .ideal-reading.form-group {
    margin-top: 7px;
  }

  .time-popup .select-box button.btn.btn-primary {
    font-size: 19px;
  }

  .client-container .main-titles-mob {
    padding-right: 12px;
  }

  .staff-container .client-option.service-edit-icon .dropdown-menu {
    min-width: 10rem;
    z-index: 99;
  }

  .client-option.service-edit-icon .dropdown-menu {
    min-width: 11rem;
    z-index: 99;
  }

  .visibility-icon i {
    font-size: 17px;
  }

  .staff-modal .modal-body input {
    padding: 6px 10px !important;
  }

  .new-client-section .staff-box .action-btn .btn,
  .new-client-section .grid-box .action-btn .btn {
    height: 24px;
    width: 24px;
    line-height: 23px;
    min-width: 24px;
    font-size: 12px;
  }

  .client-option.service-edit-icon .action i {
    margin-right: 5px !important;
  }

  .client-option.service-edit-icon .action {
    /* font-size: 16px;
    line-height: 13px; */
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .client-option.service-edit-icon .action img {
    width: 20px;
  }

  .client-container
    .mob-accordion.inner-item.accordion
    .client-heading-option
    h6 {
    font-size: 17px;
  }

  .client-container .new-client-section .client-heading-option h5 {
    font-size: 20px;
  }

  .client-container .new-client-section .client-heading-option h5 i {
    font-size: 25px;
  }

  .client-container
    .new-client-section
    .mob-accordion.inner-item.accordion
    .client-heading-option
    h5
    i {
    font-size: 22px;
  }

  .mob-accordion.new-client-section.accordion .service-edit-icon i {
    font-size: 17px;
  }

  /* new changes add appointment */
  /* login page css */
  .product-service-container .grid-box .grid-content {
    padding-right: 0px !important;
  }

  .product-service-container .grid-box .action-btn {
    padding-left: 5px !important;
  }

  .login-popup-btn button {
    font-size: 20px;
  }

  .login-text {
    font-size: 19px;
  }

  .logo-login {
    width: 170px;
  }

  h3.login-title {
    font-size: 34px;
    margin-bottom: 20px;
  }

  .login-form label.form-label {
    color: #171717;
    font-size: 20px;
    font-weight: 600;
  }

  /* .calc-btn-bottom.form-row .col {
    padding-right: 0px;
    padding-left: 0px;
} */
  .calculator-container .total-amount {
    margin-right: 0px;
    padding-right: 0px;
  }

  .cost-result {
    padding-right: 0px;
    margin-right: 0px;
  }

  .cost {
    padding-right: 0px;
    margin-right: 2px;
  }

  .calculator-container .custom-btn button.costbtn.btn.btn-primary {
    text-align: right !important;
    /* padding-right: 12px; */
  }

  .appointment-total h6:first-child {
    font-size: 15px;
    font-weight: 700;
  }

  .appointment-container .appointment-view .search-title .client-profile h3 {
    font-size: 16px;
    color: #666464;
    font-weight: 600;
    margin-bottom: 0;
  }

  .appointment-total h6 {
    margin-bottom: 0px;
    line-height: 20px;
    font-size: 18px;
  }

  .duration-time {
    font-weight: 500;
    color: #8d8d8d;
    font-size: 16px;
  }

  body.admin-view {
    overflow: auto;
  }

  /* calculator page */
  .calculate-btn img {
    height: 19px;
    margin-right: 5px;
    margin-top: -2px;
  }

  .btn-show button {
    font-size: 22px !important;
    padding: 10px 35px !important;
    font-weight: 600 !important;
  }

  .save-btn {
    margin-left: 5px;
  }

  .btn-show .calculate-btn {
    margin-left: 5px;
  }

  .calculator-main .admin-container {
    background: #e0e8f5;
  }

  .calculator-container .form-container {
    padding-top: 10px;
  }

  .liters.form-label {
    margin: 0px;
  }

  .calculator-container .form-group {
    margin-bottom: 5px;
  }

  .content-container.calculator-container {
    padding: 15px 0 !important;
  }

  .calculator-container .title {
    background: transparent;
    border-radius: 5px;
    margin-bottom: 8px;
  }

  .calculator-container .title h3 {
    font-family: "Exo 2";
    text-align: left;
    background-color: transparent;
    color: black;
    font-size: 24px;
    padding: 0px;
  }

  /* sticky footer css */
  .footer-container {
    display: none;
  }

  .calendar h6 {
    font-size: 16px;
    font-weight: 500;
    color: #949594;
  }

  .sticky-footer {
    display: block;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #f9f9f9;
    padding: 8px 10px 20px;
    border-top: 0.7px solid #dee3e7 !important;
    /* box-shadow: 0px 0px 4px 0px #dcd6d6; */
  }

  .col-md-6.col-6.half {
    padding-right: 5px;
  }

  .col-md-6.col-6.halfright {
    padding-left: 5px;
  }

  .service-modal .row .col-md-6.col-6:last-child {
    padding-left: 10px !important;
  }

  /* client page */
  .header .navbar-brand img {
    width: 85px;
    margin-top: 0px;
  }

  .header .openheader {
    /* height: 16px !important; */
    width: 22px !important;
    height: 18px;
  }

  .client-search-box {
    padding: 15px 12px;
  }

  .staff-upper-form .form-group label.form-label {
    padding: 0px !important;
  }

  .client-container .staff-list .staff-box h5 {
    font-size: 17px !important;
    color: #7c7c7c !important;
  }

  .client-container .staff-list .staff-box h3 {
    font-size: 17px;
  }

  .modal-form {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
  }

  .staff-modal .staff-form {
    padding: 15px;
  }

  .schedule-filters {
    padding: 15px;
  }

  /* staff */
  .staff-upper-form {
    margin-bottom: 25px;
  }

  .view-btn.working-hour {
    margin-bottom: 25px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link,
  .client-container .mob-accordion .card-header .btn.btn-link {
    padding: 10px 12px;
  }

  .staff-container ul.staff-list .staff-box h5 {
    font-size: 17px;
    color: #7c7c7c;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link,
  .client-container .mob-accordion .card-header .btn.btn-link {
    padding: 20px 0px;
    font-size: 20px;
  }

  .staff-upper-form {
    background: #fff;
    border-radius: 5px;
    padding: 10px 12px 17px;
  }

  .staff-container .mob-accordion .card-header {
    padding: 0px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link > i,
  .client-container .mob-accordion .card-header .btn.btn-link > i {
    color: #808183;
    font-size: 22px !important;
    line-height: 20px;
  }

  .subcategory-modal .modal-form textarea.form-control {
    font-size: 17px;
  }

  .service-form .text-muted {
    font-size: 17px;
  }

  .auto-complete li {
    font-size: 17px;
  }

  .modal-header {
    padding: 8px;
    padding-bottom: 0px !important;
  }

  .modal-header {
    padding-top: 0px;
  }

  .modal-header {
    font-size: 24px;
  }

  select.form-control {
    font-size: 17px;
  }

  .service-form input:last-child {
    padding: 6px 10px !important;
  }

  input {
    font-size: 17px;
  }

  .form-group .form-control {
    font-size: 17px;
  }

  /* staff */
  .staff-container .main-titles-mob h3 {
    font-size: 28px;
  }

  /*add appointmnet */
  .appointment-container .appointment-date h3 {
    font-size: 22px;
  }

  .appointment-container .appointment-view .appointment-date img {
    /* height: 29px; */
    width: 28px;
  }

  .appointment-form textarea.form-control::placeholder {
    height: 120px;
    font-size: 18px;
  }

  .appointment-container .appointment-form label.form-label {
    font-size: 19px;
    font-weight: 700;
  }

  .appointment-container .search-container .search-title .client-profile h3 {
    font-size: 22px;
    color: #0070c0 !important;
    font-weight: 600;
  }

  .appointment-container .search-title .content-dropdown img {
    height: 29px;
    width: 29px;
  }

  .appointment-date span {
    font-size: 16px;
  }

  .appointment-container .search-title h3 {
    font-size: 22px;
  }

  .appointment-container .main-titles h3 {
    font-size: 28px;
  }

  .appointment-container .search-title span.plus-icon {
    font-size: 20px;
  }

  .appointment-container .add-sortings .sorting-sub-services {
    max-width: 110px;
    width: 100%;
  }

  .search-title.service-head .sorting {
    padding-right: 10px;
  }

  .service-mbl .mob-accordion {
    padding: 13px 0px !important;
  }

  .service-modal .modal-form input.form-control {
    height: 35px;
    border-radius: 5px !important;
  }

  /* .service-modal .view-btn .btn {
        min-width: 118px !important ;
        width: 118px !important;
    } */
  /* custom header */
  .custom-header {
    background: #014cb2;
    width: 100%;
    height: 100vh;
    padding: 40px 15px 30px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 6%);
    text-align: center;
    border: 0;
    border-top: none;
    color: #333;
    /* width: calc(100% + 30px);
    margin-left: -15px; */
    top: -75px;
    left: 0;
    bottom: 100%;
    position: relative;
    z-index: 99;
  }

  .custom-header .header-link {
    margin-bottom: 180px;
  }

  .desktop-view {
    display: none !important;
  }

  .calendar .cross-icon {
    position: absolute;
    right: 25px;
    top: 28px;
    display: block;
  }

  .calendar .custom-header {
    top: 0px;
  }

  .custom-header a.navbar-brand {
    display: none !important;
  }

  .custom-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .nav-item .nav-link {
    font-size: 14px;
    line-height: 34px;
    color: #fff !important;
    padding: 0 15px;
    font-size: 16px;
    line-height: 38px;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    font-weight: 600;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .cross-icon {
    position: absolute;
    right: 15px;
    top: 22px;
    display: block;
  }

  .cross-icon.show-header .custom-header {
    display: block !important;
  }

  .cross-icon {
    display: block;
  }

  .custom-header {
    align-items: flex-start;
  }

  .cross-icon img {
    height: 20px;
  }

  .mobile-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .calendar .mobile-view {
    display: none;
  }

  .appointment-container .client-profile .action img {
    width: 22px !important;
  }

  .appointment-container .search-container .client-profile img {
    width: 12px;
    height: auto !important;
  }

  /* services and product */
  .action img {
    width: 14px!important;
    margin-right: 5px;
  }

  .admin-content {
    padding: 50px 0px 10px;
  }

  .timehour {
    color: #a29d9d;
    font-size: 20px;
  }

  /* calender */

  .day-option {
    padding: 7px;
  }

  .day-option img {
    width: 52px;
  }

  .calendar-team-member {
    width: 500px;
  }

  .display-view h5,
  .apntment-colors-view h5 {
    font-size: 19px;
  }

  .calendar-options h6 {
    font-size: 24px;
  }

  .calendar-close img {
    width: 24px;
  }

  .team-members h6 {
    font-size: 24px;
  }

  .team-area {
    font-size: 17px;
  }

  .first-last {
    max-width: 68px;
    padding: 24px;
    font-size: 24px;
    height: 68px;
  }

  .team-area {
    width: 33%;
  }

  .open-client .calendar-team-member {
    margin-left: -500px;
  }

  .calendar .header {
    position: relative;
  }

  /* calender */
  .demo-app.calendar-inner {
    padding: 22px 0;
  }

  .calender-date-section {
    margin-top: 0px;
  }

  .calendar .header {
    position: relative;
  }

  .calendar .header {
    background-color: transparent;
    height: auto !important;
  }

  .calendar .header .navbar-dark .navbar-brand {
    display: none;
  }

  .calendar .navbar-dark .navbar-toggler {
    margin-left: auto;
    line-height: inherit;
    z-index: 9999;
    padding: 0.25rem 0;
  }

  .calendar .admin-content {
    padding: 0px 2px;
  }

  /* producct n services */

  .product-service-container .main-titles h3 {
    font-size: 28px;
    padding-bottom: 8px;
  }

  .service-mbl .service-container .service-title.title-service .btn {
    font-size: 22px;
  }

  .product-service-list i {
    padding-right: 10px;
  }

  .service-mbl .service-container .service-title.title-service .btn {
    min-width: auto;
    padding: 0;
    color: #000;
    text-decoration: none;
  }

  .service-mbl .service-container .service-title.title-service .btn i {
    margin-right: 5px;
  }

  .service-mbl .heading-accordion h5 > i {
    font-size: 22px;
    margin-right: 5px;
  }

  .service-edit-icon button img {
    width: 14px;
  }

  .services-header .sorting {
    padding-right: 10px;
  }

  .service-mbl .outer-accordion .card {
    box-shadow: none;
  }

  /* global */
  .modal-body {
    padding: 10px 8px !important;
  }

  /* Product and services */
  .service-modal .modal-header {
    padding: 8px;
    padding-bottom: 0;
  }

  .service-edit-icon i {
    font-size: 20px;
  }

  .services-header span i {
    font-size: 26px;
  }

  .services-header button i {
    font-size: 25px;
  }

  .service-mbl .service-title h4 {
    font-size: 22px;
  }

  .product-service-container .mob-table .heading-accordion h5 {
    font-size: 20px;
  }

  .services-header {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .service-mbl .outer-accordion .card-header .btn.btn-link {
    font-size: 18px;
  }

  .service-mbl .sub-category .time-category {
    color: #7d7d7d;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
  }

  .service-mbl .mob-accordion .inner-accordion .card-header {
    padding: 15px 0px;
  }

  .service-mbl .service-container .service-title {
    padding: 10px 20px;
  }

  .service-modal .form-group {
    /* display: flex; */
    position: relative;
  }

  .subcategory-modal .form-group.row [class*="col-sm"] {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .service-modal .form-group.row [class*="col-sm"] {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .service-mbl ul .grid-list-item {
    padding-left: 24px !important;
  }

  .service-mbl .outer-accordion .sub-category.card-header .btn.btn-link {
    font-size: 17px;
    font-weight: 600;
  }

  /* add appointment */
  .appointment-service-modal .modal-header {
    padding-top: 0px;
  }

  .search-modal .search-box.form-group {
    margin-bottom: 0px !important;
  }

  /* Calender page css */
  .calendar-team-member {
    width: 500px;
  }

  .main-calendar .calendar-filter-icons span {
    height: 36px;
    width: 36px;
    margin: 0px 5px;
  }

  .calendar-date img {
    /* height: 35px; */
    width: 35px;
  }

  .main-calendar .calendar-date .date {
    margin: 0 8px;
    font-size: 18px !important;
  }

  .main-calendar .calendar-date .date span {
    font-size: 14px !important;
  }

  .calendar .navbar-toggler-icon {
    width: 28px;
    height: 32px;
  }

  .main-calendar .grid-box h5 {
    font-weight: 500 !important;
    font-size: 24px !important;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .main-calendar .grid-box span {
    font-size: 16px !important;
    font-weight: 600;
  }

  .main-calendar .grid-box.selected h5 {
    font-size: 22px !important;
  }

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 999 !important;
  }

  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }

  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }

  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }

  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }

  .header-view .header {
    position: relative !important;
    height: 70px;
  }

  .admin-content-view {
    padding-top: 0px;
    padding-bottom: 50px;
    min-height: 100vh;
    overflow: hidden;
  }

  .footer-wrapper.hide-footer .sticky-footer {
    /* transform: translateY(85px);
    transition: all ease .3s; */
    /* 
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
transition: transform .5s ease-out; */
    display: none;
    transition: transform 0.5s ease-out;
  }

  .footer-wrapper.show-footer-bottom .sticky-footer {
    /* display: block; */
    /* transition: all ease .3s;
    transform: translateY(0); */

    /* -webkit-transform: translateY(0);
    transform: translateY(0);
transition: transform .5s ease-out; */

    display: block;
    transition: transform 0.5s ease-out;
  }

  .miniral-popup .modal-body input {
    padding: 6px 20px !important;
  }

  .contact-page .view-btn {
    display: flex;
    justify-content: flex-end;
  }

  .fixed-body-section .header .container {
    padding: 0;
  }

  .edit-chemical-popup .chemical-table-head h6 {
    font-size: 11px;
  }

  .edit-chemical-popup .chemical-table-body h6 {
    font-size: 11px;
  }

  /************ inventory css **************/
  .service-edit-icon {
    font-size: 12px;
  }

  /******** payment reminder *******/
  .payment-reminder-popup .staff-form {
    padding: 0;
  }
  .reports-page .details-body h6 {
    font-size: 14px;
    margin-bottom: 8px;
}
}

@media screen and (max-width: 767px) {
  .show-message-grp.form-group {
    margin-bottom: 6px !important;
}
  .permission-new-layout
    .mob-accordion
    .card-header
    .permission-new-section
    h6 {
    font-size: 15px !important;
  }

  .predictive-ordering-detail .ordering-detail-header h6 {
    font-size: 11px;
    font-weight: 600;
  }

  .source-popup .custom-select-time h4 {
    font-size: 18px;
  }

  .source-popup .custom-select-time .col-md-12 {
    padding: 0px 5px !important;
  }

  .source-popup .custom-select-time .form-group {
    margin-bottom: 5px;
  }

  .calendar img {
    height: 30px;
  }

  .time-popup .modal-body {
    padding: 0px 0px !important;
  }

  .search-modal.time-popup.apppt-time-popup.modal .modal-body {
    padding: 10px 8px !important;
  }

  .reports-page .appointment-summary-section p {
    font-size: 14px;
  }

  .reports-page .reports-heading {
    font-size: 15px;
  }

  .reports-page .summary-table-body p {
    font-size: 12px;
  }

  .filter-table .pool-table-content input {
    font-size: 12px !important;
  }

  .green-pool-table.filter-table .pool-table-content p {
    font-size: 12px !important;
  }

  .green-pool-table.filter-table .pool-table p {
    font-size: 11px !important;
  }

  .filter-glass-table .green-pool-table.filter-table .pool-table p {
    font-size: 13px !important;
  }

  .image-upload-popup .template-btn button {
    width: 48% !important;
    font-size: 15px !important;
    min-width: auto;
  }

  .image-upload-popup .upload-logo .form-group label {
    font-size: 15px !important;
  }

  .calendar-container.close-client-popup .calendar-team-member {
    right: -450px;
  }

  .preview-invoice .invoice-type p {
    font-size: 14px;
  }

  .search-invoice .target-heading.client-profile p {
    font-size: 15px;
  }

  .pricing-setup h6 {
    font-size: 17px;
  }

  .pricing-setup p {
    font-size: 15px;
  }

  .green-pool-calculation .main-title h6 {
    font-size: 18px;
  }

  .search-invoice .target-heading.client-profile h3 {
    font-size: 20px;
  }

  .view-add-appointment .appointment-form label.form-label {
    font-size: 20px !important;
  }

  /************** pool setup *****************/
  .pool-set-up .balance-water h5 {
    font-size: 14px !important;
  }

  .pool-set-up .balance-water .balance-water-box h6 {
    font-size: 14px !important;
  }

  .pool-set-up .drainage-cost-section h6 {
    font-size: 16px;
  }

  .service-mbl.pool-accordian.miniral-pool
    .service-container
    .service-title.title-service
    .btn {
    font-size: 18px;
  }

  .pool-set-up .drainage-cost-section .bottom-text p {
    font-size: 15px;
  }

  .pool-set-up .drainage-cost-section h5 {
    font-size: 18px;
  }

  .drainage-cost-section input {
    font-size: 18px !important;
  }

  .pool-accordian .inner-accordion ul.grid-list .grid-box h3 {
    font-size: 15px;
  }

  .pool-accordian .inner-accordion ul.grid-list .grid-box h5 {
    font-size: 15px;
  }

  .pool-set-up .target-heading h6 {
    font-size: 20px;
  }

  .left-pool-analysis h5 {
    font-size: 15px;
  }

  /************ end pool setup *************/
  /* new client changes */
  .time-popup .select-box button.btn.btn-primary {
    font-size: 15px;
  }

  .client-option.service-edit-icon .action {
    font-size: 14px;
    line-height: 13px;
  }

  .visibility-icon i {
    font-size: 15px;
  }

  .client-option.service-edit-icon .action img {
    width: 18px;
  }

  .client-container
    .mob-accordion.inner-item.accordion
    .client-heading-option
    h6 {
    font-size: 15px;
  }

  .client-container .new-client-section .client-heading-option h5 {
    font-size: 17px;
  }

  .client-container .new-client-section .client-heading-option h5 i {
    font-size: 23px;
  }

  .client-container
    .new-client-section
    .mob-accordion.inner-item.accordion
    .client-heading-option
    h5
    i {
    font-size: 20px;
  }

  .mob-accordion.new-client-section.accordion .service-edit-icon i {
    font-size: 15px;
  }

  .login-popup-btn button {
    font-size: 18px;
  }

  .logo-login {
    width: 150px;
  }

  .login-text {
    font-size: 17px;
  }

  h3.login-title {
    font-size: 32px;
  }

  .login-form label.form-label {
    font-size: 18px;
  }

  .back-btn .btn span {
    font-size: 12px;
  }

  .appointment-container .appointment-view .search-title .client-profile h3 {
    font-size: 14px;
  }

  .appointment-total h6 {
    font-size: 16px;
  }

  .duration-time {
    font-size: 14px;
  }

  /* calculator page */
  .btn-show button {
    font-size: 15px !important;
    padding: 10px 20px !important;
  }

  .calculator-container .title h3 {
    font-size: 22px;
  }

  .calculate-btn img {
    height: 17px;
  }

  /* sticky footer css */

  .calendar h6 {
    font-size: 14px;
  }

  /* staff */
  .client-container .staff-list .staff-box h5 {
    font-size: 15px !important;
  }

  .client-container .staff-list .staff-box h3 {
    font-size: 15px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link,
  .client-container .mob-accordion .card-header .btn.btn-link {
    font-size: 18px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link,
  .client-container .mob-accordion .card-header .btn.btn-link {
    padding: 15px 0px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link > i,
  .client-container .mob-accordion .card-header .btn.btn-link > i {
    font-size: 20px !important;
  }

  .subcategory-modal .modal-form textarea.form-control {
    font-size: 15px;
  }

  .service-form .text-muted {
    font-size: 15px;
  }

  .auto-complete li {
    font-size: 15px;
  }

  .modal-header {
    font-size: 22px;
  }

  select.form-control {
    font-size: 15px;
  }

  .modal-body input:last-child {
    padding: 6px 10px !important;
  }

  input {
    font-size: 15px;
  }

  .form-group .form-control {
    font-size: 15px;
  }

  .staff-container ul.staff-list .staff-box h5 {
    font-size: 15px;
  }

  /* staff */
  .staff-container .main-titles-mob h3 {
    font-size: 26px;
  }

  /*add appointmnet */
  .appointment-container .appointment-date h3 {
    font-size: 20px;
  }

  .appointment-container .main-titles h3 {
    font-size: 26px;
  }

  .appointment-form textarea.form-control::placeholder {
    font-size: 16px;
  }

  .appointment-container .appointment-form label.form-label {
    font-size: 17px;
  }

  .appointment-container .search-title .client-profile h3 {
    font-size: 20px;
  }

  .appointment-date span {
    font-size: 14px;
  }

  .appointment-container .search-title h3 {
    font-size: 20px;
  }

  .appointment-container .search-title .content-dropdown img {
    height: 27px;
    width: 27px;
  }

  .appointment-container .appointment-view .appointment-date img {
    /* height: 27px; */
    width: 24px;
  }

  .appointment-container .search-title span.plus-icon {
    font-size: 18px;
  }

  .appointment-container .add-sortings .sorting-sub-services {
    max-width: 95px;
    width: 100%;
  }

  /* .service-modal .view-btn .btn {
        min-width: 118px !important ;
        width: 118px !important;
    } */
  .appointment-container .client-profile .action img {
    width: 20px !important;
  }

  .appointment-container .search-container .client-profile img {
    width: 14px;
    height: auto !important;
  }

  /* producct n services */

  .product-service-container .main-titles h3 {
    font-size: 26px;
  }

  .service-mbl .outer-accordion .sub-category.card-header .btn.btn-link {
    font-size: 15px;
    font-weight: 600;
  }

  .service-mbl .service-container .service-title.title-service .btn {
    font-size: 20px;
  }

  .service-edit-icon button img {
    width: 13px;
  }

  .timehour {
    font-size: 16px;
  }

  .up-down img {
    width: 16px !important;
    cursor: pointer;
    margin: 0px 5px !important;
    padding: 0px;
  }

  /* global */
  .modal-body {
    padding: 10px 8px !important;
  }

  /* services and product */
  .service-edit-icon i {
    font-size: 18px;
  }

  .services-header span i {
    font-size: 18px;
  }

  .services-header button i {
    font-size: 22px;
  }

  .service-mbl .service-title h4 {
    font-size: 20px;
  }

  .product-service-container .mob-table .heading-accordion h5 {
    font-size: 18px;
    font-weight: 600;
  }

  .service-mbl .heading-accordion h5 > i {
    font-size: 20px;
  }

  .services-header {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .service-mbl .outer-accordion .card-header .btn.btn-link {
    font-size: 18px;
  }

  .service-mbl .sub-category .time-category {
    color: #7d7d7d;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
  }

  .service-mbl .mob-accordion .inner-accordion .card-header {
    padding: 15px 0px;
  }

  .service-mbl .service-container .service-title {
    padding: 10px 20px;
  }

  /* end   */
  .permission-access .basic span.check-img img {
    height: 23px;
    width: 23px;
    background-color: #5bd125;
    border-radius: 50%;
  }

  /* add appointment */
  .search-modal .search-box.form-group {
    margin-bottom: 0px !important;
  }

  .calculate-btn .btn {
    float: right;
    padding: 8px 50px;
    color: white;
    font-family: "Exo 2" !important;
    border-radius: 52px;
    font-weight: 700 !important;
  }

  /* .draining-btn .btn {
    border-radius: 12px !important;
} */
  /* 
Calender page css */
  .calender-option img {
    width: 15px;
  }

  .open-client .calendar-team-member {
    margin-left: -450px;
  }

  .day-option {
    padding: 5px;
  }

  .day-option img {
    width: 50px;
  }

  .calendar-team-member {
    width: 450px;
  }

  .display-view h5,
  .apntment-colors-view h5 {
    font-size: 17px;
  }

  .calendar-options h6 {
    font-size: 22px;
  }

  .calendar-close img {
    width: 22px;
  }

  .team-members h6 {
    font-size: 22px;
  }

  .team-area {
    font-size: 15px;
  }

  .first-last {
    max-width: 65px;
    padding: 22px;
    font-size: 22px;
    height: 65px;
  }

  .team-area {
    width: 50%;
  }

  .main-calendar .calendar-filter-icons span {
    height: 32px;
    width: 32px;
    margin: 0px 3px;
  }

  .calendar-date img {
    /* height: 30px; */
    width: 30px;
  }

  .main-calendar .calendar-date .date {
    margin: 0 8px;
    font-size: 15px !important;
  }

  .main-calendar .calendar-date .date span {
    font-size: 14px !important;
  }

  .calendar .navbar-toggler-icon {
    width: 24px;
    height: 28px;
  }

  .main-calendar .grid-box h5 {
    font-weight: 500 !important;
    font-size: 22px !important;
    width: 38px;
    height: 38px;
    line-height: 38px;
  }

  .main-calendar .grid-box span {
    font-size: 14px !important;
    font-weight: 600;
  }

  .main-calendar .grid-box.selected h5 {
    font-size: 20px !important;
  }

  /************** inventory css **************/
  .predicative-stock h4 {
    font-size: 20px;
  }

  .predicative-stock p {
    font-size: 12px;
  }

  .view-btn.service-submit .btn {
    padding: 5px 2px !important;
    line-height: initial !important;
  }

  .site-addresses .client-company-name {
    font-size: 15px;
  }

  .menu-construction .button-wrapper button {
    min-height: 40px !important;
  }

  .predicative-stock h6 {
    font-size: 15px;
  }

  .predicative-stock h4 {
    font-size: 16px;
  }

  .awaiting-approvals .invoices-head h6 {
    width: 15%;
  }

  .awaiting-approvals .client-notes {
    justify-content: flex-start;
    width: auto;
  }

  .awaiting-approvals .invoices-head h6:last-child {
    text-align: left;
  }

  .awaiting-approvals .client-notes .basic .form-check {
    margin-left: 0;
  }

  .sending-reminders-page .form-group input {
    height: 38px !important;
  }

  .sending-reminders-page
    .number-box
    .dropdown
    .dropdown-toggle.btn.btn-success {
    height: 38px !important;
  }
  .reports-page .form-group label.form-label {
    font-size: 15px;
}
}

@media screen and (max-width: 480px) {
  .permission-new-layout
    .mob-accordion
    .card-header
    .permission-new-section
    h6 {
    font-size: 13px !important;
  }

  .time-popup .modal-body {
    padding: 0px 0px !important;
  }

  .search-modal.time-popup.apppt-time-popup.modal .modal-body {
    padding: 10px 8px !important;
  }

  .preview-invoice .invoice-type p {
    font-size: 12px;
  }

  /******green pool ************/
  .pricing-setup h6 {
    font-size: 15px;
  }

  .search-invoice .target-heading.client-profile p {
    font-size: 13px;
  }

  .pricing-setup p {
    font-size: 13px;
  }

  .green-pool-calculation .main-title h6 {
    font-size: 16px;
  }

  .view-add-appointment .appointment-form label.form-label {
    font-size: 18px !important;
  }

  .search-invoice .target-heading.client-profile h3 {
    font-size: 15px;
  }

  /************** pool setup *****************/
  .pool-set-up .client-option.service-edit-icon .action img {
    width: 14px !important;
  }

  .pool-set-up .balance-water h5 {
    font-size: 14px !important;
  }

  .pool-set-up .balance-water .balance-water-box h6 {
    font-size: 12px !important;
  }

  .pool-set-up .drainage-cost-section h6 {
    font-size: 14px;
  }

  .service-mbl.pool-accordian.miniral-pool
    .service-container
    .service-title.title-service
    .btn {
    font-size: 16px;
  }

  .pool-set-up .drainage-cost-section .bottom-text p {
    font-size: 13px;
  }

  .pool-set-up .drainage-cost-section h5 {
    font-size: 16px;
  }

  .drainage-cost-section input {
    font-size: 14px !important;
  }

  .pool-accordian .inner-accordion ul.grid-list .grid-box h3 {
    font-size: 13px;
  }

  .pool-accordian .inner-accordion ul.grid-list .grid-box h5 {
    font-size: 13px;
  }

  .pool-set-up .target-heading h6 {
    font-size: 18px;
  }

  .left-pool-analysis h5 {
    font-size: 12px;
  }

  /************ end pool setup *************/
  /* new client changes */
  .time-popup .select-box button.btn.btn-primary {
    font-size: 13px;
  }

  .staff-container .client-option.service-edit-icon .action img {
    width: 14px;
  }

  .staff-container .client-option.service-edit-icon .dropdown-menu {
    z-index: 99;
  }

  .client-option.service-edit-icon .dropdown-menu {
    z-index: 99;
  }

  .staff-container .service-edit-icon i {
    font-size: 12px;
  }

  .client-option.service-edit-icon .action {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .visibility-icon i {
    font-size: 13px;
  }

  .client-option.service-edit-icon .action img {
    width: 14px;
  }

  .client-notes .basic input {
    padding: 0px 0px !important;
  }

  .staff-modal .modal-body .client-notes .basic .form-check input:last-child {
    padding: 0px 0px !important;
  }

  .client-container
    .mob-accordion.inner-item.accordion
    .client-heading-option
    h6 {
    font-size: 13px;
  }

  .client-container .new-client-section .client-heading-option h5 {
    font-size: 15px;
  }

  .client-container .new-client-section .client-heading-option h5 i {
    font-size: 20px;
  }

  .client-container
    .new-client-section
    .mob-accordion.inner-item.accordion
    .client-heading-option
    h5
    i {
    font-size: 18px;
  }

  .mob-accordion.new-client-section.accordion .service-edit-icon i {
    font-size: 13px;
  }

  .login-popup-btn button {
    font-size: 14px !important;
  }

  .login-text {
    font-size: 16px;
  }

  .login-text,
  .login-links {
    font-size: 16px;
  }

  .logo-login {
    width: 134px;
  }

  h3.login-title {
    font-size: 30px;
  }

  .login-form label.form-label {
    font-size: 16px;
  }
  .time-calender
    span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-weight: 600;
    font-size: 15px;
  }

  .time-calender .react-calendar__year-view .react-calendar__tile,
  .time-calender .react-calendar__decade-view .react-calendar__tile,
  .time-calender .react-calendar__century-view .react-calendar__tile {
    padding: 12px 5px;
    font-size: 14px;
  }

  .back-btn .btn span {
    font-size: 12px;
  }

  .appointment-container .appointment-view .search-title .client-profile h3 {
    font-size: 12px;
  }

  .appointment-total h6 {
    font-size: 14px;
  }

  .duration-time {
    font-size: 12px;
  }

  .time-calender
    button.react-calendar__tile.react-calendar__tile--now.react-calendar__decade-view__years__year:before {
    border: none !important;
  }

  /* calculator page */
  /* .cal-img img{
      width: 40px !important;
  } */
  .btn-show button {
    font-size: 14px !important;
    padding: 8px 10px !important;
  }

  .calculate-btn img {
    height: 18px;
  }

  .calculator-container .title h3 {
    font-size: 20px;
  }

  /* sticky footer css */

  .calendar h6 {
    font-size: 12px;
  }

  /* staff */
  .client-container .staff-list .staff-box h5 {
    font-size: 13px !important;
  }

  .client-container .staff-list .staff-box h3 {
    font-size: 13px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link,
  .client-container .mob-accordion .card-header .btn.btn-link {
    font-size: 15px;
  }

  .staff-container .mob-accordion .card-header .btn.btn-link > i,
  .client-container .mob-accordion .card-header .btn.btn-link > i {
    font-size: 18px !important;
  }

  /* modal */
  .subcategory-modal .modal-form textarea.form-control {
    font-size: 13px;
  }

  .auto-complete li {
    font-size: 13px;
  }

  .service-form .text-muted {
    font-size: 13px;
  }

  .modal-header {
    font-size: 20px;
  }

  select.form-control {
    font-size: 13px;
  }

  .form-group .form-control {
    font-size: 13px;
  }

  input {
    font-size: 13px;
  }

  .modal-body input:last-child {
    padding: 6px 10px !important;
  }

  /* staff */
  .staff-container .main-titles-mob h3 {
    font-size: 24px;
  }

  /*add appointmnet */
  .appointment-container .appointment-date h3 {
    font-size: 18px;
  }

  .appointment-container .search-title .content-dropdown img {
    height: 22px;
    width: 22px;
  }

  .appointment-form textarea.form-control::placeholder {
    height: 120px;
    font-size: 14px;
  }

  .appointment-container .appointment-form label.form-label {
    font-size: 15px;
  }

  .appointment-container .search-container .search-title .client-profile h3 {
    font-size: 15px;
    color: #0070c0 !important;
  }

  .appointment-container .appointment-view .appointment-date img {
    /* height: 13px; */
    width: 22px;
  }

  .appointment-container .search-title h3 {
    font-size: 18px;
  }

  .appointment-container .appointment-date span {
    font-size: 13px;
  }

  .appointment-sort .up-down img {
    margin: 0px 7px !important;
  }

  .appointment-container .add-sortings .sorting-sub-services {
    max-width: 84px;
    width: 100%;
  }

  .appointment-container .search-title span.plus-icon {
    font-size: 16px;
  }

  .appointment-container .main-titles h3 {
    font-size: 24px;
  }

  .appointment-container .search-container .client-profile img {
    width: 12px;
    height: auto !important;
  }

  /* .service-modal .view-btn .btn {
    min-width: 118px !important ;
    width: 118px !important;
} */
  .appointment-container .client-profile .action img {
    width: 14px !important;
  }

  /* product and services page */

  .action img {
    width: 20px;
  }

  .product-service-container .grid-box .action-btn button img {
    margin-top: -5px;
  }

  .service-mbl .mob-table {
    padding: 0px 12px;
  }

  .timehour {
    font-size: 12px;
  }

  .up-down img {
    width: 14px !important;
    cursor: pointer;
    margin: 0px 5px !important;
    padding: 2px;
  }

  .service-mbl .heading-accordion h5 > i {
    color: #808183;
    font-size: 18px !important;
    line-height: 20px;
  }

  .product-service-list i {
    font-size: 16px;
    padding-right: 10px;
    color: #cccdce;
  }

  .action {
    font-size: 16px;
  }

  .action i {
    margin-right: 10px;
  }

  .product-service-container .main-titles h3 {
    font-size: 24px;
  }

  .service-mbl .service-container .service-title.title-service .btn {
    font-size: 18px;
  }

  .service-edit-icon .dropdown-menu.show {
    box-shadow: 0px 0px 3px 0px #e8dfdf;
    border: none;
  }

  /* Calender page */
  .calender-option img {
    width: 12px;
  }

  .day-option {
    padding: 3px;
  }

  .day-option img {
    width: 45px;
  }

  .calendar-team-member {
    width: 300px;
  }

  .open-client .calendar-team-member {
    margin-left: -300px;
  }

  .display-view h5,
  .apntment-colors-view h5 {
    font-weight: 500;
    font-size: 15px;
  }

  .calendar-options h6 {
    font-size: 20px;
  }

  .calendar-close img {
    width: 20px;
  }

  .team-members h6 {
    font-size: 20px;
  }

  .team-area {
    font-size: 13px;
  }

  .first-last {
    max-width: 62px;
    padding: 20px;
    font-size: 20px;
    height: 62px;
  }

  .team-area {
    width: 50%;
  }

  .main-calendar .grid-box h5 {
    font-weight: 500 !important;
    font-size: 20px !important;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  .main-calendar .grid-box span {
    font-size: 12px !important;
    font-weight: 600;
  }

  .main-calendar .grid-box.selected h5 {
    font-size: 18px !important;
  }

  .main-calendar .calendar-date .date span {
    font-size: 11px !important;
  }

  .main-calendar .calendar-filter-icons span {
    height: 25px;
    width: 25px;
    margin: 0 2px;
  }

  .calendar-date img {
    /* height: 28px; */
    width: 30px;
  }

  .main-calendar .calendar-date .date {
    font-size: 14px !important;
  }

  .navbar-toggler-icon {
    width: 20px;
    height: 20px;
    background-size: 25px;
  }

  /* producct n services */
  .service-edit-icon button img {
    width: 10px !important;
    margin: 0px !important;
  }

  .service-edit-icon .dropdown-toggle::after {
    display: none;
  }

  .service-edit-icon button {
    padding: 0px;
  }

  .sorting img {
    width: 25px;
  }

  /* global */
  .modal-body {
    padding: 10px 8px !important;
  }

  .table-data ul.grid-list .grid-box .grid-content {
    font-size: 13px;
  }

  .table-data ul.grid-list .grid-box h3 {
    font-size: 13px;
  }

  /* product and services */

  .service-mbl .service-container .service-title {
    padding: 10px 12px;
  }

  .service-mbl .sub-category .time-category {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
  }

  .service-mbl .outer-accordion .sub-category.card-header .btn.btn-link {
    font-size: 13px;
    font-weight: 600;
  }

  .service-mbl .service-title h4 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .service-mbl .services-header {
    width: auto;
  }

  .services-header button i {
    font-size: 18px;
  }

  .services-header span i {
    font-size: 16px;
  }

  .product-service-container .mob-table .heading-accordion h5 {
    font-size: 15px;
    font-weight: 600;
    color: #333;
  }

  .service-edit-icon i {
    font-size: 13px;
  }

  .service-mbl .mob-accordion .collapse,
  .service-mbl .collapsing,
  .service-mbl .collapsed {
    background-color: white;
    padding: 0px 0px;
    border-radius: 5px;
  }

  .outer-accordion .card-header .btn.btn-link {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }

  .service-mbl .mob-accordion .inner-accordion .card-header {
    padding: 10px 0px;
  }

  .staff-container ul.staff-list .staff-box h3,
  .staff-container ul.grid-list .grid-box h3 {
    font-size: 13px;
  }

  .staff-container ul.staff-list .staff-box h5 {
    font-size: 13px;
  }

  .staff-container ul.staff-list .staff-box h5,
  .staff-container ul.grid-list .grid-box .grid-content {
    font-size: 13px;
  }

  /* end */

  /* permission and access level */
  .permission-access .basic input {
    height: 18px;
    width: 18px;
    border-radius: 50% !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border: 1px solid #ebb7b7;
    position: relative !important;
  }

  .permission-access .basic span.check-img img {
    height: 20px;
    width: 20px;
    background-color: #5bd125;
    border-radius: 50%;
  }

  .permission-access .basic input:checked::after {
    content: "";
    position: absolute;
    /* height: 23px; */
    /* width: 8px; */
    top: -1px;
    left: -1px;
    padding: 10px;
    background: url(./assets/home/check1.png);
    background-size: cover;
    background-color: #5bd125;
    border-radius: 50%;
    border: none !important;
  }

  /* add appointment */

  .search-modal .modal-header {
    padding-top: 0px;
  }

  .appointment-service-modal .modal-header {
    font-size: 20px;
  }

  .search-modal .search-box.form-group {
    margin-bottom: 0px !important;
  }

  .calculate-btn .btn {
    font-size: 12px;
  }

  /* calender */

  .calendar .header {
    background-color: transparent;
    height: auto !important;
  }

  .calendar .header .navbar-dark .navbar-brand {
    display: none;
  }

  .calendar .navbar-dark .navbar-toggler {
    margin-left: auto;
    line-height: inherit;
    z-index: 9999;
    padding: 0.25rem 0;
    margin-top: 20px;
  }

  .calendar .admin-content {
    padding: 0px;
  }

  .calendar .admin-content-view {
    padding-top: 0px;
  }

  .calendar .header .col-lg-12 {
    padding-right: 7px;
  }

  .quotes-page .search-container .search-title .client-profile h3 {
    font-size: 15px !important;
  }

  .quotes-page .appointment-date h3 {
    font-size: 16px !important;
  }

  .client-quotes-notes span {
    font-size: 12px;
  }

  .booking-page .completed-booking {
    font-size: 12px;
  }

  .booking-page .booking-date {
    font-size: 13px;
  }

  /******* payment reminders *************/
  .sending-reminders-page .pending-water-test h3 {
    font-size: 13px;
  }

  .sms-invocie-template .todays-invoice p,
  .sms-invocie-template .days-description p {
    font-size: 13px;
  }

  .sms-invocie-template .todays-invoice h6 {
    font-size: 14px;
  }

  .calculator-container.page-two label.target.form-label {
    padding: 6px 0px !important;
  }

  .calculator-container.page-two label.target.full-width.form-label {
    padding: 6px 4px !important;
  }
  .not-repeat .client-notes .basic input {
    padding: 2px 4px !important;
  }
  .company-location .sort-icon img {
    width: 25px !important;
  }
  .client-container .client-option.service-edit-icon i {
    font-size: 16px;
  }
  .reports-page .details-body h6 {
    font-size: 12px;
    margin-bottom: 0px;
}
.reports-page .form-group label.form-label {
  font-size: 13px;
}
.client-container .mob-accordion.inner-item.accordion .client-heading-option h5 {
  font-size: 13px;
}

/* Invoice Templates */
.template-page .client-profile .invoice-header h3, .template-page .target-heading.client-profile h3 {
  color: #0f0e0e !important;
}

}

/* for calculator page */
@media screen and (max-height: 896px) {
  /* .zero-class {
        transform: translateX(4px);
        -webkit-transform: translateX(4px);
    }
      .zero-class-min {
        transform: translateX(6px);
        -webkit-transform: translateX(6px);
      } */
  .calculator-container .custom-btn .btn {
    margin-top: -10px;
  }

  .calculator-container .title h3 {
    font-size: 22px;
  }

  .calculator-container .col i.fas.fa-sort-down {
    right: 12px;
    top: 8px;
    align-items: flex-start;
    font-size: 15px;
    line-height: 12px;
    width: 16px;
    height: 16px;
    pointer-events: none !important;
  }

  .custom-select {
    font-size: 14px;
    padding: 5px 30px 5px 6px;
  }

  .content-container.calculator-container {
    padding: 15px 0;
  }

  .calculator-container .form-container {
    padding-top: 15px;
  }

  .custom-select {
    height: 32px;
  }

  .calculator-container .form-group {
    margin-bottom: 5px;
  }

  .pool-depth .form-group {
    margin: 8px 0 0;
  }

  .liters.form-label {
    height: 32px;
  }

  .custom-btn .btn {
    height: 32px;
  }

  .ml-auto .calculate-btn {
    padding-top: 25px;
  }

  .calculator-container label.form-label {
    height: 32px;
  }

  .calculator-container input.form-control {
    height: 32px;
  }

  .showdata {
    font-size: 15px;
    padding: 5px 8px;
  }

  .btn-show {
    padding-top: 6px;
  }

  .calculator-container .cost {
    font-size: 13px;
  }

  .calculator-container .btn-show button {
    font-size: 14px;
    padding: 6px 10px;
  }

  .calculator-container .liters.form-label {
    font-size: 15px;
    padding: 6px 12px;
  }

  .total-result h4 {
    font-size: 22px;
  }
}

@media screen and (max-height: 1000px) {
  .calculator-container .ideal-reading.form-group .custom-btn {
    line-height: 20px;
  }

  .calculator-container .ideal-reading.form-group {
    margin-bottom: 0px !important;
  }
  
  .show-result {
    margin-top: -2px;
    margin-bottom: -5px;
  }

  .ideal-reading.form-group {
    margin-top: 7px;
  }

  .calculator-container .custom-btn .btn {
    margin-top: -9px;
  }

  .calculator-container .col i.fas.fa-sort-down {
    top: 6px;
  }

  .calculator-container .title h3 {
    font-size: 18px;
  }

  .custom-select {
    font-size: 12px;
    padding: 5px 30px 5px 6px;
  }

  .content-container.calculator-container {
    padding: 15px 0;
  }

  .calculator-container .form-container {
    padding-top: 0px;
  }

  .custom-select {
    height: 28px;
  }

  .calculator-container .form-group {
    margin-bottom: 3px;
  }

  .pool-depth .form-group {
    margin: 3px 0 0 0;
  }

  .liters.form-label {
    height: 28px;
  }

  .custom-btn .btn {
    height: 28px;
  }

  .ml-auto .calculate-btn {
    padding-top: 10px;
  }

  .calculator-container label.form-label {
    height: 28px;
  }

  .page-two label.form-label {
    height: 22px;
  }

  .calculator-container input.form-control {
    height: 28px;
  }

  .page-two input.form-control {
    height: 22px;
  }

  .showdata {
    font-size: 11px;
    padding: 3px 6px;
    line-height: 12px;
    font-weight: 700;
    margin: 5px 0 3px 5px;
  }

  .calculator-container .total-amount {
    font-weight: 700;
  }

  .calculator-container .custom-btn .btn {
    font-size: 14px;
  }

  .calculator-container .custom-btn button.costbtn.btn.btn-primary {
    text-align: right !important;
  }

  .btn-show {
    padding-top: 10px;
  }

  .calculator-container .btn-show button {
    font-size: 14px;
    padding: 5px 10px !important;
  }

  .calculator-container .liters.form-label {
    font-size: 13px;
    padding: 5px 12px;
  }

  .total-result h4 {
    font-size: 18px;
  }
}

@media screen and (max-height: 767px) {
  .time-calender
    button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    height: 25px;
    width: 25px;
    min-width: 25px;
  }

  .time-calender
    button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
  .time-calender
    button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
  .time-calender
    button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    height: 25px;
    width: 25px;
    min-width: 25px;
  }

  .calculator-container .ideal-reading.form-group .custom-btn {
    line-height: 20px;
  }

  .calculator-container .ideal-reading.form-group {
    margin-bottom: 0px !important;
  }

  .calculator-container .title h3 {
    font-size: 16px;
  }

  .custom-select {
    font-size: 11px;
    padding: 2px 30px 2px 6px;
  }

  .content-container.calculator-container {
    padding: 15px 0 !important;
  }

  .calculator-container .form-container {
    padding-top: 5px;
  }

  .calculator-container .form-group .form-control {
    font-size: 12px;
  }

  .custom-select {
    height: 26px;
  }

  .calculator-container .form-group {
    margin-bottom: 3px;
  }

  .pool-depth .form-group {
    margin: 2px 0 0;
  }

  .liters.form-label {
    height: 24px;
  }

  .custom-btn .btn {
    height: 24px;
  }

  .ml-auto .calculate-btn {
    padding-top: 20px;
  }

  .calculator-container label.form-label {
    height: 24px;
  }

  .page-two label.form-label {
    height: 22px;
    line-height: 0px;
  }

  .calculator-container input.form-control {
    height: 24px;
  }

  .page-two input.form-control {
    height: 22px;
  }

  .change-layout {
    min-height: 25px;
  }

  .page-two .showdata {
    font-size: 11px;
    line-height: 11px;
  }

  .btn-show {
    padding-top: 3px;
  }

  .calculator-container .btn-show button {
    font-size: 12px !important;
    padding: 5px 12px !important;
  }

  .calculator-container .liters.form-label {
    font-size: 11px;
    padding: 2px 12px;
  }

  .total-result h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 375px) {
  .calendar .header .navbar-toggler-icon {
    width: 15px;
    height: 15px;
    z-index: 9999;
    background-size: 20px;
  }

  .main-calendar .calendar-date .date {
    margin: 0 5px;
    font-size: 13px !important;
  }

  .main-calendar .calendar-date img {
    /* height: 22px; */
    width: 27px;
  }

  label.target.form-label {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 360px) {
  /* calener */
  .main-calendar .calendar-date .date {
    margin: 0 5px;
    font-size: 13px !important;
  }

  .calendar-date img {
    height: 22px;
    width: 22px;
  }

  .main-titles-mob h3 {
    font-size: 20px !important;
  }

  .client-name-contact .client-name {
    font-size: 14px;
  }

  .client-name-contact p.client-address {
    font-size: 12px;
  }

  .client-name-contact .open-date img {
    height: 22px;
    width: 24px;
  }

  .calendar img {
    height: 24px;
  }
  .cover-client-page .action-btn button {
    margin-right: 2px !important;
  }
  .client-notes label {
    font-size: 14px !important;
  }
  .subsciption-popup .staff-form label.form-label {
    font-size: 12px;
  }
  .staff-modal .modal-title {
    font-size: 18px;
  }
  .staff-form label.form-label {
    font-size: 12px;
  }
  .client-notes .basic input {
    height: 18px;
    width: 18px;
  }
  .view-btn .btn {
    font-size: 12px !important;
  }
  .view-add-appointment .appointment-form label.form-label {
    font-size: 15px !important;
  }
  .appointment-container .appointment-date h3 {
    font-size: 15px;
  }
  .appointment-container .search-title h3 {
    font-size: 15px;
  }
  .appointment-container .appointment-form label.form-label {
    font-size: 13px;
  }
  .appointment-service-modal .modal-header {
    font-size: 18px;
  }
  .appointment-service-modal .service-form label.form-label {
    font-size: 12px;
  }
  .search-modal .modal-header,
  .appointment-service-modal .modal-header,
  .not-repeat h6 {
    font-size: 18px;
  }
  .appointment-summary .start-date-section {
    flex-direction: column;
  }
  .appointment-summary .start-date-section .form-group {
    width: 100%;
  }
  .appointment-container .main-titles h3 {
    font-size: 20px;
  }
  .footer-btn.view-btn.footer-dainage-btn button:first-child {
    font-size: 11px !important;
    padding: 5px 8px !important;
    min-width: 65px;
  }
  .finalize-cost-page .footer-btn.view-btn.footer-dainage-btn button {
    font-size: 11px !important;
  }
  .search-client-address h5.client-company-name {
    font-size: 16px;
  }
  .search-modal .search-user-list .search-content h4 {
    font-size: 14px;
  }
  .site-addresses .client-company-name {
    font-size: 13px;
  }
  .time-keeper-popup.duration-popup.event-popup {
    max-width: 300px;
  }
  .event-popup .pending-water-test h3 {
    font-size: 14px;
  }
  .appointment-type-one .team-member h6 {
    font-size: 12px;
  }
  .appointyment-type h5 {
    font-size: 15px;
  }
  .appointment-total h6:first-child {
    font-size: 13px;
  }
  .appointment-total h6 {
    font-size: 13px;
  }
  .select-time .time-popup h6 {
    font-size: 14px;
  }
  .awaiting-approvals .invoices-head h6 {
    font-size: 11px;
  }
  .client-name-contact .client-company-name {
    font-size: 15px;
  }

  /******* pagination *********/
  .pagination-chk a {
    font-size: 11px;
  }
  a.pagination__link {
    width: 85px;
  }

  /********invoice **************/
  .search-invoces-box h6 {
    font-size: 15px;
  }
  .pending-water-test h3 {
    font-size: 12px;
  }
  .pool-chemistry-box.appointment-service-modal label {
    font-size: 11px !important;
  }
  .invoices-list .custom-datepicker::before {
    height: 14px;
    width: 14px;
  }
  .invoices-list .custom-datepicker {
    font-size: 12px;
  }
  .pool-set-up .target-heading h6 {
    font-size: 16px;
  }
  .appointment-container.finalize-cost-page.new-invoice .add-appointment h4 {
    font-size: 16px;
  }
  .appointment-container.finalize-cost-page.new-invoice .add-appointment h6 {
    font-size: 14px;
  }
  .appointment-container .search-container .client-profile p {
    font-size: 11px;
  }
  .quotes-page .appointment-view .form-group label {
    font-size: 11px;
  }
  .appointment-container .search-container .search-title .client-profile h3 {
    font-size: 12px;
  }

  /******* calculator ***********/
  .view-appointment-popup .modal-header {
    font-size: 16px;
    font-weight: 600;
  }
  .service-modal .modal-title {
    font-size: 18px;
  }
  /***** services & products *******/
  .checklist-heading h6 {
    font-size: 18px;
  }
  .product-service-container .main-titles h3 {
    font-size: 20px;
  }
  /******* inventory********/
  .predicative-stock h4 {
    font-size: 14px;
  }
  .search-inventory .pool-chemistry-box.appointment-service-modal h5 {
    font-size: 16px;
  }

  /********* xero ********/
  .main-titles-mob.xero-page-connect h3 {
    font-size: 16px !important;
  }
  .sales-default label.form-label {
    font-size: 12px;
  }
  .xero-checkbox p {
    font-size: 13px;
  }
  .sales-default h5 {
    margin-bottom: 0;
    font-size: 16px;
  }

  /* / Pool Setup / */
  .pool-set-up .target-heading h6 {
    font-size: 16px;
  }
  .right-pool-analysis h6 {
    font-size: 12px;
  }
  .form-group .form-control {
    font-size: 12px;
  }
  .service-mbl .service-container .service-title.title-service .btn {
    font-size: 16px;
  }
  .service-mbl .outer-accordion.accordion .heading-accordion {
    padding: 0;
  }

  ul.grid-list li.grid-list-item:last-child .grid-box {
    padding-bottom: 0;
  }
  .left-pool-table p {
    font-size: 10px;
  }
  .right-pool-table p {
    font-size: 11px;
  }
  .note-info {
    font-size: 11px;
  }
  .green-pool-table h5 {
    font-size: 15px;
  }
  .green-pool-calculation .main-title h6 {
    font-size: 15px;
  }
  .pricing-setup h6 {
    font-size: 14px;
  }
  .green-pool-table.filter-table .pool-table-content p {
    font-size: 11px !important;
  }
  .filter-table .pool-table-content input {
    font-size: 11px !important;
  }
  .right-pool-table span {
    font-size: 11px;
  }
  .search-pool-size h6 {
    font-size: 16px;
  }

  /* / Automated Payments / */
  .staff-container .main-titles-mob h3 {
    font-size: 20px;
  }
  .appointment-summary h6,
  top-heading h6 {
    font-size: 15px;
  }

  /* / Permission Levels / */
  .permission-access .basic h6 {
    font-size: 11px;
  }

  /* staff */
  ul.staff-list .staff-box {
    padding: 5px 0;
  }
  .staff-container ul.staff-list .staff-box h3,
  .staff-container ul.grid-list .grid-box h3 {
    font-size: 11px;
  }
  .staff-container .mob-accordion .card-header .btn.btn-link,
  .client-container .mob-accordion .card-header .btn.btn-link {
    font-size: 13px;
  }
  .download-csv-popup h6 {
    padding: 0px 12px;
    font-size: 15px;
  }
  .card-body .permission-level h6 {
    font-size: 13px;
  }
  .permission-access .basic h6 {
    font-size: 11px;
  }
  .search-invoice .target-heading.client-profile h3 {
    font-size: 13px;
  }
  .sending-reminders-page .pending-water-test h3 {
    font-size: 12px;
  }
  .heading-accordion h5 {
    font-size: 13px;
  }
  ul.grid-list .grid-box h3 {
    font-size: 12px;
  }
  ul.grid-list .grid-box h5 {
    font-size: 12px;
  }
  .pool-set-up .drainage-cost-section h6 {
    font-size: 12px;
  }
  .pool-set-up .drainage-cost-section h5 {
    font-size: 14px;
  }
  .pool-set-up .balance-water h5 {
    font-size: 12px !important;
  }
  .filter-glass-table .green-pool-table.filter-table .pool-table p {
    font-size: 11px !important;
  }
}
/* iframe {
  pointer-events: none;
} */

@media screen and (min-width: 420px) and (max-height: 940px) {
  .calculator-container  .form-group .form-row  .form-label, .calculator-container .custom-select, .calculator-container input.form-control {
    height: 32.2px;
}
.page-two label.form-label, .page-two  input.form-control{
  height: 26px !important;
}
.showdata {
  height: 22px !important;
}
.calculator-container label.form-label {
  font-size: 13px !important;
}
.calculator-container label {
  font-size: 13px !important;
}
.calculator-container .custom-btn .btn {
  font-size: 15px;
}
.calculator-container .custom-select {
  font-size: 13px;
}
.upload-img-calc {
  top: -5px;
}
.showdata{
  font-size: 12px;
}
.change-layout{
  font-size: 13px;
}
}